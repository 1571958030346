import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import Heading from '../../ui/Heading';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDarkMode } from '../../context/DarkModeContext';
import { Chart as ChartJS, CategoryScale, LinearScale, ArcElement, Tooltip } from 'chart.js';
import { fetchCountryWorkCount } from '../../services/apiWork';
import { convertCountryCodesToNames } from '../../utils/countries';
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
);

const ChartBox = styled.div`
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-around;
  padding: 1.2rem 1.6rem;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  box-shadow: var(--shadow-md);

  & > *:first-child {
    margin-bottom: 1.2rem;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #333;
  min-width: 250px;
  width: 45%;
`;


const CountryWorkChart = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useDarkMode();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Number of Works',
      data: [],
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 159, 64, 0.6)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  });

  const fetchCountryCounts = async () => {
    try {
      const response = await fetchCountryWorkCount();
      if (response && response.data && Array.isArray(response.data)) {
        const chartData = {
          labels: response.data.map(d => convertCountryCodesToNames(d.country)),
          datasets: [{
            label: 'Number of Works',
            data: response.data.map(d => d.count),
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        };
        setChartData(chartData);
      } else {
        console.error('No data or empty data received');
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchCountryCounts();
  }, []);

  return (
    <>
      <Heading as="h3">{t('dashboard.country_count_chart')}</Heading>
      <ChartBox>
        <ChartContainer >

          {chartData.labels.length > 0 ? (
            <Pie data={chartData} />
          ) : (
            <p>No data available to display the chart.</p>
          )}
          <Heading as="h6" color={isDarkMode ? "#fff" : "#000"}></Heading>
        </ChartContainer>
      </ChartBox>
    </>

  );
};

export default CountryWorkChart;
