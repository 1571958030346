import React, { useState, useRef, useEffect } from 'react';
import {
    MultiSelectContainer,
    MultiSelectDisplay,
    MultiSelectTag,
    CloseButton,
    MultiSelectDropdown,
    MultiSelectOption,
    StyledTextField,
    LabelStyle,
    SearchInput,
} from './StyledComponents';
import { useFormContext } from 'react-hook-form';

const MultiSelect = ({ options, selectedValues, setSelectedValues, label, id, validationMessage, placeHolder, clearErrors, setError }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    const { errors, register, setValue } = useFormContext();

    useEffect(() => {
        register(id, { required: validationMessage });
    }, [register, id, validationMessage]);

    const handleSelect = (value) => {
        const newSelectedValues = selectedValues.includes(value)
            ? selectedValues.filter(item => item !== value)
            : [...selectedValues, value];

        setSelectedValues(newSelectedValues);
        setValue(id, newSelectedValues);
        newSelectedValues.length > 0 ? clearErrors(id) : setError(id, { type: "manual", message: validationMessage });
    };

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <MultiSelectContainer>
            <label>{label}</label>
            <MultiSelectDisplay onClick={() => setShowDropdown(!showDropdown)}>
                {selectedValues.length > 0 ? (
                    selectedValues.map((value, index) => (
                        <React.Fragment key={value}>
                            <MultiSelectTag>
                                {value}
                                <CloseButton type="button" onClick={() => handleSelect(value)}>
                                    &times;
                                </CloseButton>
                            </MultiSelectTag>
                        </React.Fragment>
                    ))
                ) : (
                    <span>{placeHolder}</span>
                )}
                <input
                    type="hidden"
                    ref={register(id, { required: validationMessage })}
                    value={selectedValues.join(',')}
                />
            </MultiSelectDisplay>
            {showDropdown && (
                <MultiSelectDropdown ref={dropdownRef}>
                    <SearchInput
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {filteredOptions.map(option => (
                        <MultiSelectOption key={option.value}>
                            <StyledTextField
                                type="checkbox"
                                id={option.value}
                                checked={selectedValues.includes(option.value)}
                                onChange={() => handleSelect(option.value)}
                            />
                            <LabelStyle htmlFor={option.value}>{option.label}</LabelStyle>
                        </MultiSelectOption>
                    ))}
                </MultiSelectDropdown>
            )}



        </MultiSelectContainer>
    );
};

export default MultiSelect;