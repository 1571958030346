import axios from './axiosConfig';

export async function createWork(workData) {
    try {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add-work`, workData);

        return response.data;
    } catch (error) {

        handleApiError(error);
    }
}

export async function fetchWorks(userId, role) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/works?userId=${userId}&role=${role}`);
        const works = response.data;

        for (const work of works) {
            const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/works/${work.work_id}/category`);
            work.category = categoryResponse.data.category;
        }

        return works;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchWorkById(workId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/works/${workId}`);
        const work = response.data;
        const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/works/${work.work_id}/category`);
        work.category = categoryResponse.data.category;
        return work;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchCountryWorkCount() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/works/country-counts`);
        if (response.data) {
            return response;
        } else {
            console.error('No data received from API');
            return { data: [] };
        }
    } catch (error) {
        handleApiError(error);
    }
}

export async function updateWork(params) {
    try {
        const { workId, workData } = params;
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/works/${workId}`, workData);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}


export async function deleteWork(workId, userId) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/works/${workId}?userId=${userId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

function handleApiError(error) {
    if (error.response) {
        const message = `HTTP Error: ${error.response.status} ${error.response.data}`;
        console.error("API HTTP error:", message);
        alert(message);
    } else if (error.request) {
        console.error("API no response error:", error.request);
        alert('No response from server. Please check your network connection.');
    } else {
        console.error("API error:", error.message);
        alert('Error processing request. Please try again later.');
    }
    throw error;
}
