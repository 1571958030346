import React from 'react';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import DatePickerWrapper from '../../../ui/DatePickerWrapper';
import { StyledTextField } from '../../../ui/StyledComponents';
import Heading from '../../../ui/Heading';
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
import { workProdcution, workPublication } from '../../../data/workData/workData';
import { v4 as uuidv4 } from 'uuid';

const WorkEvents = ({
    eventTypes,
    toggleEventType,
    publicationEvents,
    setPublicationEvents,
    awardEvents,
    setAwardEvents,
    productionEvents,
    setProductionEvents,
    registrationEvents,
    setRegistrationEvents,
    countryOptions
}) => {
    const addPublicationEvent = (event) => {
        event.preventDefault();
        setPublicationEvents([...publicationEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            region: ''
        }]);
    };

    const removePublicationEvent = (index) => {
        setPublicationEvents(publicationEvents.filter((_, i) => i !== index));
    };

    const handlePublicationChange = (index, field, value) => {
        const updatedEvents = [...publicationEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setPublicationEvents(updatedEvents);
    };

    const addAwardEvent = (event) => {
        event.preventDefault();
        setAwardEvents([...awardEvents, {
            id: uuidv4(),
            name: '',
            date: new Date(),
            achievement: '',
            nomination: false
        }]);
    };

    const removeAwardEvent = (index) => {
        setAwardEvents(awardEvents.filter((_, i) => i !== index));
    };

    const handleAwardChange = (index, field, value) => {
        const updatedEvents = [...awardEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setAwardEvents(updatedEvents);
    };

    const addProductionEvent = (event) => {
        event.preventDefault();
        setProductionEvents([...productionEvents, {
            id: uuidv4(),
            type: '',
            location: '',
            date: new Date(),
            details: ''
        }]);
    };

    const removeProductionEvent = (index) => {
        setProductionEvents(productionEvents.filter((_, i) => i !== index));
    };

    const handleProductionChange = (index, field, value) => {
        const updatedEvents = [...productionEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setProductionEvents(updatedEvents);
    };

    const addRegistrationEvent = (event) => {
        event.preventDefault();
        setRegistrationEvents([...registrationEvents, {
            id: uuidv4(),
            date: new Date(),
            agency: '',
            regionalScope: '',
            applicant: '',
            number: ''
        }]);
    };

    const removeRegistrationEvent = (index) => {
        setRegistrationEvents(registrationEvents.filter((_, i) => i !== index));
    };

    const handleRegistrationChange = (index, field, value) => {
        const updatedEvents = [...registrationEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setRegistrationEvents(updatedEvents);
    };

    const handleToggleEventType = (type) => {
        toggleEventType(type);

        if (eventTypes[type]) {
            switch (type) {
                case 'publication':
                    setPublicationEvents([]);
                    break;
                case 'awardNomination':
                    setAwardEvents([]);
                    break;
                case 'production':
                    setProductionEvents([]);
                    break;
                case 'rightsRegistration':
                    setRegistrationEvents([]);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div>
            <FormRow>
                <Heading as="h5">Publication</Heading>
                <input type="checkbox" checked={eventTypes.publication} onChange={() => handleToggleEventType('publication')} />
            </FormRow>
            {eventTypes.publication && (
                <>
                    {publicationEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Publication type</Heading>
                                <DropDown
                                    id={`event_publication_type_${index}`}
                                    options={workPublication}
                                    onSelect={option => handlePublicationChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose publication type"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Publication date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handlePublicationChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Publication region</Heading>
                                <DropDown
                                    id={`event_publication_region_${index}`}
                                    options={countryOptions}
                                    onSelect={option => handlePublicationChange(index, 'region', option.value)}
                                    value={event.region}
                                    placeholder={"Choose publication region"}
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removePublicationEvent(index)}
                                label={"Remove Publication Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addPublicationEvent}
                        label={"Add Publication Event"}
                    />
                </>
            )}
            <FormRow>
                <Heading as="h5">Award/Nomination</Heading>
                <input type="checkbox" checked={eventTypes.awardNomination} onChange={() => handleToggleEventType('awardNomination')} />
            </FormRow>
            {eventTypes.awardNomination && (
                <>
                    {awardEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Award name</Heading>
                                <StyledTextField type="text" value={event.name} onChange={e => handleAwardChange(index, 'name', e.target.value)} placeholder="Award Name" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Award date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleAwardChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Award achievement/category</Heading>
                                <StyledTextField type="text" value={event.achievement} onChange={e => handleAwardChange(index, 'achievement', e.target.value)} placeholder="Award Category" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Award nomination only</Heading>
                                <input type="checkbox" checked={event.nomination} onChange={() => handleAwardChange(index, 'nomination', !event.nomination)} />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeAwardEvent(index)}
                                label={"Remove Award Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addAwardEvent}
                        label={"Add Award Event"}
                    />
                </>
            )}
            <FormRow>
                <Heading as="h5">Production</Heading>
                <input type="checkbox" checked={eventTypes.production} onChange={() => handleToggleEventType('production')} />
            </FormRow>
            {eventTypes.production && (
                <>
                    {productionEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Production type</Heading>
                                <DropDown
                                    id={`production_type_${index}`}
                                    options={workProdcution}
                                    onSelect={option => handleProductionChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose production type"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Production location</Heading>
                                <StyledTextField type="text" value={event.location} onChange={e => handleProductionChange(index, 'location', e.target.value)} placeholder="Production Location" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Production date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleProductionChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Production details</Heading>
                                <StyledTextField type="text" value={event.details} onChange={e => handleProductionChange(index, 'details', e.target.value)} placeholder="Production Details" />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeProductionEvent(index)}
                                label={"Remove Production Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addProductionEvent}
                        label={"Add Production Event"}
                    />
                </>
            )}
            <FormRow>
                <Heading as="h5">Rights/Copyright/IPR Registration</Heading>
                <input type="checkbox" checked={eventTypes.rightsRegistration} onChange={() => handleToggleEventType('rightsRegistration')} />
            </FormRow>
            {eventTypes.rightsRegistration && (
                <>
                    {registrationEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Registration date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleRegistrationChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Registration agency</Heading>
                                <StyledTextField type="text" value={event.agency} onChange={e => handleRegistrationChange(index, 'agency', e.target.value)} placeholder="Registration Agency" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Registration regional Scope</Heading>
                                <StyledTextField type="text" value={event.regionalScope} onChange={e => handleRegistrationChange(index, 'regionalScope', e.target.value)} placeholder="Registration Regional Scope" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Registration applicant</Heading>
                                <StyledTextField type="text" value={event.applicant} onChange={e => handleRegistrationChange(index, 'applicant', e.target.value)} placeholder="Registration Applicant" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Registration number</Heading>
                                <StyledTextField type="text" value={event.number} onChange={e => handleRegistrationChange(index, 'number', e.target.value)} placeholder="Registration Number" />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeRegistrationEvent(index)}
                                label={"Remove Registration Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addRegistrationEvent}
                        label={"Add Registration Event"}
                    />
                </>
            )}
        </div>
    );
};

export default WorkEvents;
