import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DropDown from '../../../ui/DropDown';
import FormRow from "../../../ui/FormRow";
import Heading from '../../../ui/Heading';
import Input from '../../../ui/Input';
import GroupedDropDown from '../../../ui/GroupedDropDown';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';
import {
    itemGeneralFormat,
    itemGeneralPhotoFormat,
    itemGeneralDocFormat,
    itemSpecificFormatMapping,
    itemPhotoSpecificFormatMapping,
    itemDocSpecificFormatMapping,
    color,
    colorSystems,
    aspectRatiosAudioVisual,
    aspectRatiosPhoto,
    aspectRatiosDoc,
    aperture,
    itemStockAudiovisual,
    itemStockPhoto,
    frameRates,
    bitDepthRatesVideo,
    bitDepthRatesDoc,
    bitDepthRatesPhoto,
    resolutionOptionsVideo,
    resolutionOptionsPhoto,
    resolutionOptionsDoc,
    bitDepthRatesAudio,
    samplingFrequencyAudio,
    audioTypeOptions,
    audioSystemOptions,
    audioChannel,
    itemObjectElementType,
    itemAudiovisualsElementType,
    itemDocElementType,
    itemPhotoElementType,
    itemBase,
    itemBasePhoto
} from '../../../data/itemData/itemData';
import Button from '../../../ui/Button';
const ItemFormat = ({ category, errors, sharedFields }) => {
    const { register, setValue, getValues, control } = useFormContext();
    const [selectedGeneralFormat, setSelectedGeneralFormat] = useState("");
    const [selectedSpecificFormat, setSelectedSpecificFormat] = useState("");
    const [disabledFields, setDisabledFields] = useState({});

    useEffect(() => {
        if (sharedFields) {
            const fieldsToDisable = {};
            const loadFields = async () => {
                for (const key of Object.keys(sharedFields)) {
                    await setValue(`item_format_${key}`, sharedFields[key] || '', { shouldValidate: true });
                }

                setSelectedGeneralFormat(sharedFields.general || "");
                setSelectedSpecificFormat(sharedFields.specific || "");

            };
            Object.keys(sharedFields).forEach(key => {
                const fieldKey = `item_format_${key}`;
                fieldsToDisable[fieldKey] = !!sharedFields[key];
            });
            setDisabledFields(fieldsToDisable);
            loadFields();
        }
    }, [sharedFields, setValue]);

    const audioType = useWatch({
        control,
        name: 'item_format_audio'
    });

    const transformedItemTypes = Object.keys(itemObjectElementType).map(category => ({
        title: category.replace('_', ' '),
        options: itemObjectElementType[category].map(item => ({
            label: item,
            value: item.toLowerCase().replace(/ /g, '_')
        }))
    }));

    useEffect(() => {
        register('item_format_general');
        register('item_format_specific');
        register('item_format_color');
        register('item_format_aspect');
        register('item_format_aperture');
        register('item_format_stock');
        register('item_format_framerate');
        register('item_format_itemtype');
        register('item_format_resolution');
        register('item_format_depth');
        register('item_format_height');
        register('item_format_width');
        register('item_format_colorstandard');
        register('item_format_audiofrequency');
        register('item_format_audiorate');
        register('item_format_audio');
        register('item_format_audioformat');
        register('item_format_audiosystem');
        register('item_format_audiochannel');

        const initialGeneralFormat = getValues('item_format_general') || "";
        const initialSpecificFormat = getValues('item_format_specific') || "";
        setSelectedGeneralFormat(initialGeneralFormat);
        setSelectedSpecificFormat(initialSpecificFormat);

        setValue('item_format_general', initialGeneralFormat);
        setValue('item_format_specific', initialSpecificFormat);
    }, [register, getValues, setValue]);

    useEffect(() => {
        setValue('item_format_general', selectedGeneralFormat);
        setValue('item_format_specific', selectedSpecificFormat);
    }, [selectedGeneralFormat, selectedSpecificFormat, setValue]);

    const handleGeneralFormatSelect = (option) => {
        setSelectedGeneralFormat(option.value);
        setSelectedSpecificFormat("");
        setValue('item_format_general', option.value);
        setValue('item_format_specific', "");
        setValue('manifest_format_audioformat', "");
        setValue('manifest_format_colorstandard', "");
        setValue('manifest_format_colortype', "");
        setValue('manifest_format_aspect', "");
        setValue('manifest_format_codec', "");
        setValue('manifest_format_frame', "");
        setValue('manifest_format_resolution', "");
        setValue('manifest_format_depth', "");
        setValue('manifest_format_audiofrequency', "");
        setValue('manifest_format_audiorate', "");

        const specificFormats = getSpecificFormats(option.options);
        if (specificFormats && specificFormats.length > 0) {
            setSelectedSpecificFormat(specificFormats[0].value);
            setValue('item_format_specific', specificFormats[0].value);
        }
    };

    const handleSpecificFormatSelect = (option) => {
        setSelectedSpecificFormat(option.value);
        setValue('item_format_specific', option.value);
    };

    const handleAudioTypeSelect = (option) => {
        setValue('item_format_audio', option.value);
    };

    const handleDropDownSelect = (fieldName, option) => {
        setValue(fieldName, option.value);
    };

    const getSpecificFormats = (generalFormat) => {
        return itemSpecificFormatMapping[generalFormat] || [];
    };

    const isVideoFieldDisabled = selectedGeneralFormat === 'audio' ||
        (selectedGeneralFormat === 'digital' && ['aiff', 'wav'].includes(selectedSpecificFormat.toLowerCase()));

    const isDigitalFieldDisabled = selectedGeneralFormat !== 'digital' ||
        (selectedGeneralFormat === 'digital' && ['aiff', 'wav'].includes(selectedSpecificFormat.toLowerCase()));

    const isAudioFieldDisabled = selectedGeneralFormat !== 'audio' &&
        (selectedGeneralFormat === 'film' && audioType === 'no_sound');

    const isFieldDisabled = (fieldName) => {
        if (disabledFields[fieldName]) {
            return true;
        }

        switch (fieldName) {
            case 'item_format_specific':
                return !selectedGeneralFormat;
            case 'item_format_color':
            case 'item_format_aspect':
            case 'item_format_framerate':
            case 'item_format_colorstandard':
                return isVideoFieldDisabled;
            case 'item_format_resolution':
            case 'item_format_depth':
            case 'item_format_audiofrequency':
            case 'item_format_audiorate':
                return isDigitalFieldDisabled;
            case 'item_format_audio':
            case 'item_format_audioformat':
            case 'item_format_audiosystem':
            case 'item_format_audiochannel':
                return isAudioFieldDisabled;
            default:
                return false;
        }
    };


    const renderTypeFields = () => {
        switch (category) {
            case 'audiovisuals':
                return renderAudiovisualFields();
            case 'photos_and_images':
                return renderPhotoFields();
            case 'paper_based_documents':
                return renderDocFields();
            case 'objects':
                return renderObjectsFields();
            default:
                return <p>{category}</p>;
        }
    };

    const renderFormatFields = () => {
        switch (category) {
            case 'audiovisuals':
                return renderAudiovisualFormatFields();
            case 'photos_and_images':
                return renderPhotoFormatFields();
            case 'paper_based_documents':
                return renderDocFormatFields();
            default:
                return;
        }
    };

    const renderAudiovisualFormatFields = () => {
        return (



            <>

                <FormRow error={errors.item_format_general?.message}>
                    <Heading as="h5">General Format</Heading>
                    <DropDown
                        id="item_format_general"
                        options={itemGeneralFormat}
                        onSelect={handleGeneralFormatSelect}
                        value={selectedGeneralFormat}
                        placeholder={"Choose General Format"}
                        {...register("item_format_general", {
                            required: "Please choose a general format",
                        })}
                        disabled={isFieldDisabled("item_format_general")}
                    />
                </FormRow>
                <FormRow error={errors.item_format_specific?.message}>
                    <Heading as="h5">Specific Format</Heading>
                    <DropDown
                        key={selectedGeneralFormat}
                        id="item_format_specific"
                        options={getSpecificFormats(selectedGeneralFormat)}
                        onSelect={handleSpecificFormatSelect}
                        value={selectedSpecificFormat}
                        placeholder={"Choose Specific Format"}
                        disabled={isFieldDisabled("item_format_specific")}
                        {...register("item_format_specific", {
                            required: "Please choose a specific format",
                        })}
                    />
                </FormRow>
            </>


        );
    };

    const renderPhotoFormatFields = () => {
        const categoryData = itemPhotoSpecificFormatMapping[selectedGeneralFormat] || [];

        return (

            <>
                <FormRow error={errors.item_format_general?.message}>
                    <Heading as="h5">General Format</Heading>
                    <DropDown
                        id="item_format_general"
                        options={itemGeneralPhotoFormat}
                        onSelect={handleGeneralFormatSelect}
                        value={selectedGeneralFormat}
                        disabled={isFieldDisabled("item_format_general")}
                        placeholder={"Choose General Format"}
                        {...register("item_format_general", { required: "Please choose a general format", })}

                    />
                </FormRow>
                <FormRow error={errors.item_format_specific?.message}>
                    <Heading as="h5">Specific Format</Heading>
                    <GroupedDropDown
                        key={selectedGeneralFormat}
                        id="item_format_specific"
                        options={categoryData}
                        onSelect={handleSpecificFormatSelect}
                        value={selectedSpecificFormat}
                        label={"Choose Specific Format "}
                        disabled={isFieldDisabled("item_format_specific")}
                        {...register("item_format_specific", {
                            required: "Please choose a specific format",
                        })}
                    />
                </FormRow>
            </>
        );
    };

    const renderDocFormatFields = () => {
        const categoryData = itemDocSpecificFormatMapping[selectedGeneralFormat] || [];

        return (
            <>
                <FormRow error={errors.item_format_general?.message}>
                    <Heading as="h5">General Format</Heading>
                    <DropDown
                        id="item_format_general"
                        options={itemGeneralDocFormat}
                        onSelect={handleGeneralFormatSelect}
                        value={selectedGeneralFormat}
                        placeholder={"Choose General Format"}
                        disabled={isFieldDisabled("item_format_general")}
                        {...register("item_format_general", {
                            required: "Please choose a general format",
                        })}
                    />
                </FormRow>
                <FormRow error={errors.item_format_specific?.message}>
                    <Heading as="h5">Specific Format</Heading>

                    <GroupedDropDown
                        key={selectedGeneralFormat}
                        id="item_format_specific"
                        options={categoryData}
                        onSelect={handleSpecificFormatSelect}
                        value={selectedSpecificFormat}
                        label={"Choose Specific Format "}
                        disabled={isFieldDisabled("item_format_specific")}
                        {...register("item_format_specific", {
                            required: "Please choose a specific format",
                        })}
                    />
                </FormRow>
            </>
        );
    };

    const renderAudiovisualFields = () => {
        return (
            <>
                <DropDown
                    id="item_format_itemtype"
                    options={itemAudiovisualsElementType}
                    onSelect={(option) => handleDropDownSelect('item_format_itemtype', option)}
                    value={getValues('item_format_itemtype')}
                    placeholder={"Choose Item Type"}
                />
            </>
        );
    };

    const renderPhotoFields = () => {
        return (
            <>
                <DropDown
                    id="item_format_itemtype"
                    options={itemPhotoElementType}
                    onSelect={(option) => handleDropDownSelect('item_format_itemtype', option)}
                    value={getValues('item_format_itemtype')}
                    placeholder={"Choose Item Type"}
                />
            </>
        );
    };

    const renderDocFields = () => {
        return (
            <>
                <DropDown
                    id="item_format_itemtype"
                    options={itemDocElementType}
                    onSelect={(option) => handleDropDownSelect('item_format_itemtype', option)}
                    value={getValues('item_format_itemtype')}
                    placeholder={"Choose Item Type"}
                />
            </>
        );
    };

    const renderObjectsFields = () => {
        return (
            <>
                <GroupedDropDown
                    id="item_format_itemtype"
                    options={transformedItemTypes}
                    label="Choose Item Type"
                    onSelect={(option) => handleDropDownSelect('item_format_itemtype', option)}
                    value={getValues('item_format_itemtype')}
                />
            </>
        );
    };



    return (
        <>
            {renderFormatFields()}
            <Button variation="disabled" size="large">
                Upload
            </Button>
            {(category === 'audiovisuals' || category === 'photos_and_images') && (
                <FormRow>
                    <Heading as="h5">Color</Heading>
                    <DropDown
                        id="item_format_color"
                        options={color}
                        placeholder={"Choose Color"}
                        onSelect={(option) => handleDropDownSelect('item_format_color', option)}
                        value={getValues('item_format_color')}
                        disabled={isFieldDisabled('item_format_color')}
                    />
                </FormRow>
            )}

            {(category !== 'objects') && (
                <FormRow>
                    <Heading as="h5">Aspect Ratio</Heading>
                    <DropDown
                        id="item_format_aspect"
                        options={category === 'audiovisuals' ? aspectRatiosAudioVisual :
                            category === 'photos_and_images' ? aspectRatiosPhoto :
                                aspectRatiosDoc}
                        placeholder={"Choose Aspect Ratio"}
                        onSelect={(option) => handleDropDownSelect('item_format_aspect', option)}
                        value={getValues('item_format_aspect')}
                        disabled={isFieldDisabled('item_format_aspect')}
                    />
                </FormRow>
            )}
            {(category === 'audiovisuals') && (
                <FormRow>
                    <Heading as="h5">Aperture</Heading>
                    <DropDown
                        id="item_format_aperture"
                        options={aperture}
                        onSelect={(option) => handleDropDownSelect('item_format_aperture', option)}
                        value={getValues('item_format_aperture')}
                        placeholder={"Choose Aperture "}
                        disabled={isFieldDisabled('item_format_aperture')}
                    />
                </FormRow>
            )}

            {(category !== 'paper_based_documents' && category !== 'objects') && (
                <FormRow>
                    <Heading as="h5">Item stock</Heading>
                    <GroupedDropDown
                        id="item_format_stock"
                        options={category === 'audiovisuals' ? itemStockAudiovisual : itemStockPhoto}
                        onSelect={(option) => handleDropDownSelect('item_format_stock', option)}
                        value={getValues('item_format_stock')}
                        label={"Choose item stock "}
                        disabled={isFieldDisabled('item_format_stock')}
                    />
                </FormRow>
            )}

            {(category === 'audiovisuals') && (
                <FormRow>
                    <Heading as="h5">Frame Rate</Heading>
                    <DropDown
                        id="item_format_framerate"
                        options={frameRates}
                        placeholder={"Choose Frame Rate"}
                        onSelect={(option) => handleDropDownSelect('item_format_framerate', option)}
                        value={getValues('item_format_framerate')}
                        disabled={isFieldDisabled('item_format_framerate')}
                    />
                </FormRow>
            )}
            {(category !== 'objects') && (
                <FormRow>
                    <Heading as="h5">Resolution</Heading>
                    <DropDown
                        id="item_format_resolution"
                        options={category === 'audiovisuals' ? resolutionOptionsVideo :
                            category === 'photos_and_images' ? resolutionOptionsPhoto :
                                resolutionOptionsDoc}
                        onSelect={(option) => handleDropDownSelect('item_format_resolution', option)}
                        value={getValues('item_format_resolution')}
                        disabled={isFieldDisabled('item_format_resolution')}
                        placeholder={"Choose Resolution "}
                    />
                </FormRow>
            )}
            {(category !== 'objects') && (
                <FormRow>
                    <Heading as="h5">Bit Depth</Heading>
                    <DropDown
                        id="item_format_depth"
                        options={category === 'audiovisuals' ? bitDepthRatesVideo :
                            category === 'photos_and_images' ? bitDepthRatesPhoto :
                                bitDepthRatesDoc}
                        onSelect={(option) => handleDropDownSelect('item_format_depth', option)}
                        value={getValues('item_format_depth')}
                        disabled={isFieldDisabled('item_format_depth')}
                        placeholder={"Choose Bit Depth "}
                    />
                </FormRow>
            )}
            <FormRow>
                <Heading as="h5">Item type</Heading>
                {renderTypeFields()}
            </FormRow>
            {(category !== 'paper_based_documents' && category !== 'objects') && (
                <FormRow>
                    <Heading as="h5">Item Base</Heading>
                    <DropDown
                        id="item_format_itembase"
                        options={category === 'audiovisuals' ? itemBase :
                            itemBasePhoto}
                        placeholder={"Choose Item Base"}
                        onSelect={(option) => handleDropDownSelect('item_format_itembase', option)}
                        value={getValues('item_format_itembase')}
                    />
                </FormRow>
            )}

            {(category !== 'objects') && (
                <FormRow >
                    <Heading as="h5">Height</Heading>
                    <Input
                        type="number"
                        placeholder={"Enter Height"}
                        {...register("item_format_height")}
                    />
                </FormRow>
            )}

            {(category !== 'objects') && (
                <FormRow>
                    <Heading as="h5">Width</Heading>
                    <Input
                        type="number"
                        placeholder={"Enter Width"}
                        {...register("item_format_width")}
                    />
                </FormRow>
            )}

            {(category === 'audiovisuals' || category === 'photos_and_images') && (
                <FormRow>
                    <Heading as="h5">Color Standard</Heading>
                    <DropDown
                        id="item_format_colorstandard"
                        options={colorSystems}
                        placeholder={"Choose Color System"}
                        onSelect={(option) => handleDropDownSelect('item_format_colorstandard', option)}
                        value={getValues('item_format_colorstandard')}
                        disabled={isFieldDisabled('item_format_colorstandard')}
                    />
                </FormRow>
            )}
            {(category === 'audiovisuals') && (
                <>
                    <FormRow>
                        <Heading as="h5">Audio Sampling Frequency</Heading>
                        <DropDown
                            id="item_format_audiofrequency"
                            options={samplingFrequencyAudio}
                            onSelect={(option) => handleDropDownSelect('item_format_audiofrequency', option)}
                            value={getValues('item_format_audiofrequency')}
                            disabled={isFieldDisabled('item_format_audiofrequency')}
                            placeholder={"Choose Audio Sampling Frequency"}
                        />
                    </FormRow>
                    <FormRow>
                        <Heading as="h5">Audio Bit Depth</Heading>
                        <DropDown
                            id="item_format_audiorate"
                            options={bitDepthRatesAudio}
                            onSelect={(option) => handleDropDownSelect('item_format_audiorate', option)}
                            value={getValues('item_format_audiorate')}
                            placeholder={"Choose Audio Bit Depth"}
                            disabled={isFieldDisabled('item_format_audiorate')}
                        />
                    </FormRow>
                    <FormRow>
                        <Heading as="h5">Audio Type</Heading>
                        <DropDown
                            id="item_format_audio"
                            options={audioTypeOptions}
                            placeholder={"Choose Audio Type"}
                            onSelect={handleAudioTypeSelect}
                            value={audioType}
                            disabled={isFieldDisabled('item_format_audio')}
                        />
                    </FormRow>
                    <FormRow>
                        <Heading as="h5">Audio Format</Heading>
                        <DropDown
                            id="item_format_audioformat"
                            options={itemSpecificFormatMapping.audio}
                            placeholder={"Choose Audio Format"}
                            onSelect={(option) => handleDropDownSelect('item_format_audioformat', option)}
                            value={getValues('item_format_audioformat')}
                            disabled={isFieldDisabled('item_format_audioformat')}
                        />
                    </FormRow>
                    {(selectedGeneralFormat !== 'digital') && (
                        <FormRow>
                            <Heading as="h5">Audio System</Heading>
                            <DropDown
                                id="item_format_audiosystem"
                                options={audioSystemOptions}
                                placeholder={"Choose Audio System"}
                                onSelect={(option) => handleDropDownSelect('item_format_audiosystem', option)}
                                value={getValues('item_format_audiosystem')}
                                disabled={isFieldDisabled('item_format_audiosystem')}
                            />
                        </FormRow>
                    )}
                    <FormRow>
                        <Heading as="h5">Audio Channel</Heading>
                        <DropDown
                            id="item_format_audiochannel"
                            options={audioChannel}
                            placeholder={"Choose Audio Channel"}
                            onSelect={(option) => handleDropDownSelect('item_format_audiochannel', option)}
                            value={getValues('item_format_audiochannel')}
                            disabled={isFieldDisabled('item_format_audiochannel')}
                        />
                    </FormRow>
                </>
            )}
        </>
    );
};

export default ItemFormat;
