import axios from './axiosConfig';

export async function fetchRecordCounts() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/record-counts`);

        return response.data;

    } catch (error) {
        handleApiError(error);
    }
}


export async function createRequest(userId, recordId, description) {
    try {
        const payload = {
            userId,
            recordId,
            description
        };


        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/requests`, payload);

        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

export async function handleUpdateStatus(userId, requestId, status) {
    try {
        const payload = {
            userId,
            requestId,
            status
        };


        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update-request-status'`, payload);

        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}


function handleApiError(error) {
    if (error.response) {
        const message = `HTTP Error: ${error.response.status} ${error.response.data}`;
        console.error("API HTTP error:", message);
        alert(message);
    } else if (error.request) {
        console.error("API no response error:", error.request);
        alert('No response from server. Please check your network connection.');
    } else {
        console.error("API error:", error.message);
        alert('Error processing request. Please try again later.');
    }
    throw error;
}
