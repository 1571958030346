import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledTextarea } from './StyledComponents';

function AutoExpandingTextarea({ name, placeholder, disabled }) {
    const { register } = useFormContext();
    const textareaRef = useRef(null);

    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [register]);

    return (
        <StyledTextarea
            ref={textareaRef}
            {...register(name)}
            placeholder={placeholder}
            disabled={disabled}
            onInput={adjustHeight}
        />
    );
}

export default AutoExpandingTextarea;
