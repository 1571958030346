import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../context/NotificationProvider';
import { getNotifications, deleteNotification, markNotificationAsRead } from '../../services/apiNotification';
import NotificationRow from '../../ui/NotificationRow';
import SpinnerMini from '../../ui/SpinnerMini';

const NotificationsList = ({ userId }) => {
    const [notifications, setNotifications] = useState([]);
    const { isLoading, setIsLoading, setError, setNotificationCount } = useContext(NotificationContext);

    useEffect(() => {
        const fetchNotifications = async () => {
            setIsLoading(true);
            try {
                const fetchedNotifications = await getNotifications(userId);
                setNotifications(fetchedNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            } catch (error) {
                setError(error.message);
                setNotifications([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotifications();
    }, [userId, setIsLoading, setError]);

    useEffect(() => {
        const unreadNotifications = notifications.filter(n => !n.read);
        if (unreadNotifications && unreadNotifications.length > 0) {
            const delayMarkAsRead = setTimeout(async () => {
                try {
                    const idsToMarkAsRead = unreadNotifications.map(n => n.notification_id);
                    await markNotificationAsRead(idsToMarkAsRead);
                    setNotifications(current =>
                        current.map(n => ({
                            ...n,
                            read: idsToMarkAsRead.includes(n.notification_id) ? true : n.read
                        }))
                    );
                    setNotificationCount(current => current - unreadNotifications.length);
                } catch (error) {
                    console.error('Error marking notifications as read:', error);
                }

            }, 5000);

            return () => clearTimeout(delayMarkAsRead);
        }
    }, [notifications]);

    const handleDelete = async (notificationId) => {
        try {
            await deleteNotification(notificationId);
            setNotifications(notifications.filter(n => n.notification_id !== notificationId));
        } catch (error) {
            console.error('Failed to delete notification:', error);
        }
    };

    return (
        <div>
            {isLoading ? (
                <SpinnerMini />
            ) : notifications.length > 0 ? (
                <ul>
                    {notifications.map((notification, index) => (
                        <NotificationRow
                            key={index}
                            label={notification.title}
                            message={notification.message}
                            time={notification.createdAt}
                            isNew={!notification.read}
                            onDelete={() => handleDelete(notification.notification_id)}
                        />
                    ))}
                </ul>
            ) : (
                <p>No notifications to display</p>
            )}
        </div>
    );
};

export default NotificationsList;
