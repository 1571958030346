import axios from './axiosConfig';

export async function createUser(userData) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, userData);
        return response.data;
    } catch (error) {

        handleApiError(error);
    }
}

export async function fetchUsers() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchUserById(userId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}`);
        return response.data;
    } catch (error) {
        return null
    }
}

export async function fetchUserStats() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users-stats`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user statistics:", error);
        throw error;
    }
}

export async function fetchTopUsers() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/top-users`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch top users:', error);
        throw new Error('Failed to fetch top users');
    }
}

export async function updateUserRole(userId, newRoleData) {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/${userId}/role`, newRoleData);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

export async function deleteUser(userId) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${userId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

function handleApiError(error) {
    if (error.response) {
        const message = `HTTP Error: ${error.response.status} ${error.response.data}`;
        console.error("API HTTP error:", message);
        alert(message);
    } else if (error.request) {
        console.error("API no response error:", error.request);
        alert('No response from server. Please check your network connection.');
    } else {
        console.error("API error:", error.message);
        alert('Error processing request. Please try again later.');
    }
    throw error;
}
