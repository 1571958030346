import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import Heading from '../../../ui/Heading';
import DropDown from '../../../ui/DropDown';
import { usageTypeAudioVisualDialogue, usageTypeAudioVisualWritten, usageTypeDoc } from '../../../data/manifestationData/manifestationData';
import { getLanguageOptions } from '../../../utils/countries';
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';

const ManifestationLanguage = ({
    manifestationLanguages,
    setManifestationLanguages,
    errors,
    category
}) => {
    const { register, setValue } = useFormContext();
    const languageOptions = getLanguageOptions();

    useEffect(() => {
        const filteredLanguages = manifestationLanguages.filter(lang =>
            lang.language.trim() !== "" ||
            (lang.dialogue && lang.dialogueType.trim() !== "") ||
            (lang.written && lang.writtenType.trim() !== "")
        );
        setValue('manifest_format_language', JSON.stringify(filteredLanguages));
    }, [manifestationLanguages, setValue]);

    const handleAddLanguage = (event) => {
        event.preventDefault();
        const newLanguage = category === "audiovisuals"
            ? { language: "", dialogue: false, written: false, dialogueType: "", writtenType: "" }
            : { language: "", writtenType: "" };
        setManifestationLanguages([...manifestationLanguages, newLanguage]);
    };

    const handleRemoveLanguage = (index) => {
        const newLanguages = [...manifestationLanguages];
        newLanguages.splice(index, 1);
        setManifestationLanguages(newLanguages);
    };

    const handleLanguageChange = (index, option) => {
        const newLanguages = [...manifestationLanguages];
        newLanguages[index].language = option.value || "";
        setManifestationLanguages(newLanguages);
    };

    const handleCheckboxChange = (index, field) => {
        const newLanguages = [...manifestationLanguages];
        newLanguages[index][field] = !newLanguages[index][field];
        setManifestationLanguages(newLanguages);
    };

    const handleDialogueSelect = (index, option) => {
        const newLanguages = [...manifestationLanguages];
        newLanguages[index].dialogueType = option.value || "";
        setManifestationLanguages(newLanguages);
    };

    const handleWrittenSelect = (index, option) => {
        const newLanguages = [...manifestationLanguages];
        newLanguages[index].writtenType = option.value || "";
        setManifestationLanguages(newLanguages);
    };

    return (
        <>
            {manifestationLanguages.map((lang, index) => (
                <div key={index}>
                    <FormRow>
                        <Heading as="h5">Language {index + 1}</Heading>
                        <DropDown
                            id={`manifestation_language_${index}`}
                            options={languageOptions}
                            onSelect={(option) => handleLanguageChange(index, option)}
                            value={lang.language}
                            placeholder="Choose language"
                        />
                        <TextButtonRemove handleRemoveIdentifier={() => handleRemoveLanguage(index)} label="Remove language">Remove</TextButtonRemove>
                    </FormRow>
                    {category === "audiovisuals" ? (
                        <>
                            <FormRow>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={lang.dialogue}
                                        onChange={() => handleCheckboxChange(index, "dialogue")}
                                    />
                                    Dialogue
                                </label>
                                {lang.dialogue && (
                                    <DropDown
                                        id={`dialogue_type_${index}`}
                                        options={usageTypeAudioVisualDialogue}
                                        onSelect={(option) => handleDialogueSelect(index, option)}
                                        value={lang.dialogueType}
                                        placeholder="Choose Dialogue Usage Type"
                                    />
                                )}
                            </FormRow>
                            <FormRow>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={lang.written}
                                        onChange={() => handleCheckboxChange(index, "written")}
                                    />
                                    Written
                                </label>
                                {lang.written && (
                                    <DropDown
                                        id={`written_type_${index}`}
                                        options={usageTypeAudioVisualWritten}
                                        onSelect={(option) => handleWrittenSelect(index, option)}
                                        value={lang.writtenType}
                                        placeholder="Choose Written Usage Type"
                                    />
                                )}
                            </FormRow>
                        </>
                    ) : (
                        <FormRow>
                            <DropDown
                                id={`written_type_${index}`}
                                options={usageTypeDoc}
                                onSelect={(option) => handleWrittenSelect(index, option)}
                                value={lang.writtenType}
                                placeholder="Choose Usage Type"
                            />
                        </FormRow>
                    )}
                </div>
            ))}
            <TextButtonAdd handleAddIdentifier={handleAddLanguage} label="Add Other Language"></TextButtonAdd>
        </>
    );
};

export default ManifestationLanguage;
