import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid var(--color-grey-200);
`;

const TabButton = styled.button`
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  background: ${(props) => (props.active ? 'var(--color-grey-50)' : 'var(--color-grey-100)')};
  border: ${(props) => (props.active ? '1px solid var(--color-grey-200)' : '1px solid transparent')};
  border-bottom: none;
  outline: none;
  color: var(--color-grey-700);
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.3s;
  &:hover {
    background: var(--color-grey-200);
  }
`;

const TabContent = styled.div`
  padding: 2.4rem;
  border: 1px solid var(--color-grey-200);
  background: var(--color-grey-50);
`;

export const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  return (
    <div>
      <TabContainer>
        {React.Children.map(children, (child, index) => (
          <TabButton active={activeTab === index} onClick={() => setActiveTab(index)}>
            {t(child.props.title)}
          </TabButton>
        ))}
      </TabContainer>
      <TabContent>
        {React.Children.toArray(children)[activeTab]}
      </TabContent>
    </div>
  );
};

export const Tab = ({ children }) => {
  return <>{children}</>;
};
