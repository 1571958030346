import React, { useState, useEffect } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';
import { fetchRecordCounts } from '../../services/apiRecord';
import { useDarkMode } from '../../context/DarkModeContext';
import Heading from '../../ui/Heading';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const ChartBox = styled.div`
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-around;
  padding: 1.2rem 1.6rem;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  box-shadow: var(--shadow-md);

  & > *:first-child {
    margin-bottom: 1.2rem;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  min-width: 250px;
  width: 100%;
`;

const RADIAN = Math.PI / 180;

function TypesCountChart() {
    const { isDarkMode } = useDarkMode();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        const loadData = async () => {
            try {
                const counts = await fetchRecordCounts();
                const formattedData = [
                    { name: "Works", value: parseInt(counts.works, 10), color: "#F5782B" },
                    { name: "Manifestations", value: parseInt(counts.manifestations, 10), color: "#6E1946" },
                    { name: "Items", value: parseInt(counts.items, 10), color: "#91B9B4" }
                ];
                setData(formattedData);
            } catch (error) {
                setError('Failed to load record counts.');
                toast.error('Failed to load record counts.');
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, []);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = outerRadius + 10;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill={isDarkMode ? "white" : "black"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <>
            <Heading as="h3">{t('dashboard.types_count_chart')}</Heading>
            <ChartBox>
                <ChartContainer isDarkMode={isDarkMode}>
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={data}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={80}
                                labelLine={true}
                                label={renderCustomizedLabel}
                                paddingAngle={5}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value) => `${value} entries`} />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </ChartBox>
        </>
    );
}

export default TypesCountChart;
