import React, { useEffect } from 'react';
import { useFormContext, useFieldArray, FormProvider } from 'react-hook-form';
import CustomInputWithPrefix from '../../../ui/CustomInputWithPrefix';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import { manifestationAudioVisualsType, manifestationPhotoDocTypes } from "../../../data/manifestationData/manifestationData";
import Heading from '../../../ui/Heading';
import GenerateID from '../../../ui/GenerateID';
import Input from "../../../ui/Input";
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
const ManifestationGeneralFields = ({ category }) => {
    const methods = useFormContext();
    const { register, setValue, control, formState: { errors }, trigger } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "manifest_identifiers"
    });

    const handleManifestationTypeSelect = (option) => {
        setValue("manifest_type", option.value);
        trigger("manifest_type")
    };
    useEffect(() => {
        setValue("manifestation_identifier_type", "Manifestation");
    }, [setValue]);

    const handleAddIdentifier = (event) => {
        event.preventDefault();
        if (fields.length < 3) {
            append({ id: "", type: "" });
        }
    };

    const getManifestationTypesList = (category) => {
        switch (category) {
            case 'paper_based_documents':
            case 'photos_and_images':
                return manifestationPhotoDocTypes;
            default:
                return manifestationAudioVisualsType;
        }
    };

    const manifestationTypesList = getManifestationTypesList(category);

    return (
        <>
            <FormProvider {...methods}>
                <FormRow>
                    <Heading as="h5">Manifestation Type</Heading>
                    <DropDown
                        id="manifest_type"
                        options={manifestationTypesList}
                        onSelect={handleManifestationTypeSelect}
                        initialValue={methods.getValues("manifest_type")}
                        placeholder={"Choose manifestation type"}
                        {...register("manifest_type")}
                    />
                </FormRow>
                <FormRow error={errors.manifest_nfaid?.message}>
                    <Heading as="h5">NFA ID</Heading>
                    <CustomInputWithPrefix
                        id="manifest_nfaid"
                        name="manifest_nfaid"
                        prefix="SA-NFA-MYY-"
                        {...register("manifest_nfaid", { required: "Please enter NFA id" })}
                        GenerateIDComponent={GenerateID}
                    />
                </FormRow>

                {fields.map((field, index) => (
                    <>
                        <FormRow error={errors?.manifest_identifiers?.[index]?.id?.message}>
                            <Heading as="h5">Identifier ID {index + 1}</Heading>
                            <Input
                                id={`manifest_identifiers_${index}`}
                                type="text"
                                {...register(`manifest_identifiers[${index}].id`, { required: "Please enter ID" })}
                                placeholder={`Identifier ID ${index + 1}`}
                            />
                        </FormRow>
                        <FormRow error={errors?.manifest_identifiers?.[index]?.type?.message}>
                            <Heading as="h5">Identifier Type {index + 1}</Heading>
                            <Input
                                id={`manifest_identifiers_type_${index}`}
                                type="text"
                                {...register(`manifest_identifiers[${index}].type`, { required: "Please enter ID type" })}
                                placeholder={`Identifier Type ${index + 1}`}
                            />
                        </FormRow>
                        {index >= 0 && (
                            <TextButtonRemove handleRemoveIdentifier={() => remove(index)} label="Remove identifer" ></TextButtonRemove>
                        )}
                    </>
                ))}
                {fields.length < 3 && (
                    <TextButtonAdd handleAddIdentifier={handleAddIdentifier} label="Add new identifer" ></TextButtonAdd>
                )}
            </FormProvider>
        </>
    );
};

export default ManifestationGeneralFields;
