import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DropDown from '../../../ui/DropDown';
import FormRow from "../../../ui/FormRow";
import GroupedDropDown from '../../../ui/GroupedDropDown';
import Heading from '../../../ui/Heading';
import {
    manifestationGeneralFormat,
    manifestationGeneralPhotoFormat,
    manifestationPhotoSpecificFormatMapping,
    manifestationSpecificFormatMapping,
    manifestationGeneralDocFormat,
    manifestationDocSpecificFormatMapping,
    colorStandard,
    colorStandardDigital,
    colorType,
    colorTypeDoc,
    aspectRatiosAudioVisual,
    aspectRatiosDoc,
    aspectRatiosPhoto,
    aperture,
    itemStockAudiovisual,
    itemStockPhoto,
    frameRates,
    bitDepthRatesVideo,
    bitDepthRatesDoc,
    bitDepthRatesPhoto,
    resolutionOptionsVideo,
    resolutionOptionsPhoto,
    resolutionOptionsDoc,
    codecAudioVisual,
    codecPhoto,
    codec,
    bitDepthRatesAudio,
    samplingFrequencyAudio,
    audioTypeOptions,
    audioSystemOptions,
    audioChannel,
} from '../../../data/manifestationData/manifestationData';
import Button from '../../../ui/Button';

const ManifestationFormat = ({ category, errors }) => {
    const { register, setValue, getValues, control } = useFormContext();
    const [selectedGeneralFormat, setSelectedGeneralFormat] = useState("");
    const [selectedSpecificFormat, setSelectedSpecificFormat] = useState("");
    const [audioFormatLabel, setAudioFormatLabel] = useState("");

    const audioType = useWatch({
        control,
        name: 'manifest_format_audio'
    });

    useEffect(() => {
        register('manifest_format_general', { required: true });
        register('manifest_format_specific', { required: true });
        register('manifest_format_colorstandard');
        register('manifest_format_colortype');
        register('manifest_format_aspect');
        register('manifest_format_aperture');
        register('manifest_format_stock');
        register('manifest_format_codec');
        register('manifest_format_frame');
        register('manifest_format_resolution');
        register('manifest_format_depth');
        register('manifest_format_audiofrequency');
        register('manifest_format_audiorate');
        register('manifest_format_audio');
        register('manifest_format_audioformat');
        register('manifest_format_audiosystem');
        register('manifest_format_audiochannel');

        const initialGeneralFormat = getValues('manifest_format_general') || "";
        const initialSpecificFormat = getValues('manifest_format_specific') || "";
        setSelectedGeneralFormat(initialGeneralFormat);
        setSelectedSpecificFormat(initialSpecificFormat);

        setValue('manifest_format_general', initialGeneralFormat);
        setValue('manifest_format_specific', initialSpecificFormat);
        if (initialGeneralFormat === 'audio') {
            setValue('manifest_format_audioformat', initialSpecificFormat);
        }
    }, [register, getValues, setValue]);

    useEffect(() => {
        setValue('manifest_format_general', selectedGeneralFormat);
        setValue('manifest_format_specific', selectedSpecificFormat);
    }, [selectedGeneralFormat, selectedSpecificFormat, setValue]);

    const handleGeneralFormatSelect = (option) => {
        setSelectedGeneralFormat(option.value);
        setSelectedSpecificFormat("");
        setValue('manifest_format_general', option.value);
        setValue('manifest_format_specific', "");
        setValue('manifest_format_audioformat', "");
        setValue('manifest_format_colorstandard', "");
        setValue('manifest_format_colortype', "");
        setValue('manifest_format_aspect', "");
        setValue('manifest_format_codec', "");
        setValue('manifest_format_frame', "");
        setValue('manifest_format_resolution', "");
        setValue('manifest_format_depth', "");
        setValue('manifest_format_audiofrequency', "");
        setValue('manifest_format_audiorate', "");
        setValue('manifest_format_aperture', "");
        setValue('manifest_format_stock', "");
        setValue('manifest_format_audio', "");
        setValue('manifest_format_audiosystem', "");
        setValue('manifest_format_audiochannel', "");
    };

    const handleSpecificFormatSelect = (option) => {
        setSelectedSpecificFormat(option.value);
        setValue('manifest_format_specific', option.value);
        if (selectedGeneralFormat === 'audio') {
            setValue('manifest_format_audioformat', option.value);
            setAudioFormatLabel(option.label);
        }
    };

    const handleAudioTypeSelect = (option) => {
        setValue('manifest_format_audio', option.value);
    };

    const handleDropDownSelect = (fieldName, option) => {
        setValue(fieldName, option.value);
    };

    const isVideoFieldDisabled = selectedGeneralFormat === 'audio' ||
        (selectedGeneralFormat === 'digital' && ['aiff', 'wav'].includes(selectedSpecificFormat.toLowerCase()));

    const isDigitalFieldDisabled = selectedGeneralFormat !== 'digital' ||
        (selectedGeneralFormat === 'digital' && ['aiff', 'wav'].includes(selectedSpecificFormat.toLowerCase()));

    const isAudioFieldDisabled = selectedGeneralFormat !== 'audio' &&
        (selectedGeneralFormat === 'film' && audioType === 'no_sound');

    useEffect(() => {
        if (isDigitalFieldDisabled) {
            setValue('manifest_format_resolution', null);
            setValue('manifest_format_depth', null);
            setValue('manifest_format_audiofrequency', null);
            setValue('manifest_format_audiorate', null);
        }
    }, [isDigitalFieldDisabled, setValue]);

    const renderCategoryFields = () => {
        switch (category) {
            case 'audiovisuals':
                return renderAudiovisualFields();
            case 'photos_and_images':
                return renderPhotoFields();
            case 'paper_based_documents':
                return renderDocFields();
            default:
                return null;
        }
    };

    const renderAudiovisualFields = () => {
        const categoryData = manifestationSpecificFormatMapping[selectedGeneralFormat] || [];
        return (
            <>
                <FormRow error={errors.manifest_format_general?.message}>
                    <Heading as="h5">General Format</Heading>
                    <DropDown
                        id="manifest_format_general"
                        options={manifestationGeneralFormat}
                        onSelect={handleGeneralFormatSelect}
                        value={selectedGeneralFormat}
                        initialValue={getValues('manifest_format_specific')}
                        placeholder={"Choose General Format "}
                        {...register("manifest_format_general", {
                            required: "Please choose a general format",
                        })}
                    />
                </FormRow>
                <FormRow error={errors.manifest_format_specific?.message}>
                    <Heading as="h5">Specific Format</Heading>
                    <DropDown
                        key={selectedGeneralFormat}
                        id="manifest_format_specific"
                        options={categoryData}
                        onSelect={handleSpecificFormatSelect}
                        value={selectedSpecificFormat}
                        initialValue={getValues('manifest_format_specific')}
                        disabled={!selectedGeneralFormat}
                        placeholder={"Choose Specific Format "}
                        {...register("manifest_format_specific", {
                            required: "Please choose a specific format",
                        })}
                    />
                </FormRow>
            </>
        );
    };

    const renderPhotoFields = () => {
        const categoryData = manifestationPhotoSpecificFormatMapping[selectedGeneralFormat] || [];
        return (
            <>
                <FormRow error={errors.manifest_format_general?.message}>
                    <Heading as="h5">General Format</Heading>
                    <DropDown
                        id="manifest_format_general"
                        options={manifestationGeneralPhotoFormat}
                        onSelect={handleGeneralFormatSelect}
                        value={selectedGeneralFormat}
                        initialValue={getValues('manifest_format_general')}
                        placeholder={"Choose General Format "}
                        {...register("manifest_format_general", {
                            required: "Please choose a general format",
                        })}
                    />
                </FormRow>
                <FormRow error={errors.manifest_format_specific?.message}>
                    <Heading as="h5">Specific Format</Heading>
                    <GroupedDropDown
                        key={selectedGeneralFormat}
                        id="manifest_format_specific"
                        options={categoryData}
                        onSelect={handleSpecificFormatSelect}
                        value={selectedSpecificFormat}
                        initialValue={getValues('manifest_format_specific')}
                        label={"Choose Specific Format "}
                        disabled={!selectedGeneralFormat}
                        {...register("manifest_format_specific", {
                            required: "Please choose a specific format",
                        })}
                    />
                </FormRow>
            </>
        );
    };

    const renderDocFields = () => {
        const categoryData = manifestationDocSpecificFormatMapping[selectedGeneralFormat] || [];
        return (
            <>
                <FormRow error={errors.manifest_format_general?.message}>
                    <Heading as="h5">General Format</Heading>
                    <DropDown
                        id="manifest_format_general"
                        options={manifestationGeneralDocFormat}
                        onSelect={handleGeneralFormatSelect}
                        value={selectedGeneralFormat}
                        initialValue={getValues('manifest_format_general')}
                        placeholder={"Choose General Format "}
                        {...register("manifest_format_general", {
                            required: "Please choose a general format",
                        })}
                    />
                </FormRow>
                <FormRow error={errors.manifest_format_specific?.message}>
                    <Heading as="h5">Specific Format</Heading>
                    <GroupedDropDown
                        key={selectedGeneralFormat}
                        id="manifest_format_specific"
                        options={categoryData}
                        onSelect={handleSpecificFormatSelect}
                        value={selectedSpecificFormat}
                        initialValue={getValues('manifest_format_specific')}
                        label={"Choose Specific Format "}
                        disabled={!selectedGeneralFormat}
                        {...register("manifest_format_specific", {
                            required: "Please choose a specific format",
                        })}
                    />
                </FormRow>
            </>
        );
    };

    const audioFormatPlaceholder = selectedGeneralFormat === 'audio' && selectedSpecificFormat ? audioFormatLabel : "Choose Audio Format";

    return (
        <>
            {renderCategoryFields()}
            <Button variation="disabled" size="large">
                Upload
            </Button>

            {(selectedGeneralFormat === 'digital') && (
                <FormRow>
                    <Heading as="h5">Color standard</Heading>
                    <DropDown
                        id="manifest_format_colorstandard"
                        options={colorStandardDigital}
                        onSelect={(option) => handleDropDownSelect('manifest_format_colorstandard', option)}
                        value={getValues('manifest_format_colorstandard')}
                        initialValue={getValues('manifest_format_colorstandard')}
                        disabled={isVideoFieldDisabled}
                        placeholder={"Choose color standard "}
                    />
                </FormRow>
            )}

            {(selectedGeneralFormat !== 'digital' && selectedGeneralFormat !== 'audio') && (
                <FormRow>
                    <Heading as="h5">Color standard</Heading>
                    <DropDown
                        id="manifest_format_colorstandard"
                        options={colorStandard}
                        onSelect={(option) => handleDropDownSelect('manifest_format_colorstandard', option)}
                        value={getValues('manifest_format_colorstandard')}
                        initialValue={getValues('manifest_format_colorstandard')}
                        disabled={isVideoFieldDisabled}
                        placeholder={"Choose color standard"}
                    />
                </FormRow>
            )}

            {(selectedGeneralFormat !== 'audio') && (
                <FormRow>
                    <Heading as="h5">Color type</Heading>
                    <DropDown
                        id="manifest_format_colortype"
                        options={category === 'audiovisuals' ? colorType :
                            category === 'photos_and_images' ? colorType :
                                colorTypeDoc}
                        onSelect={(option) => handleDropDownSelect('manifest_format_colortype', option)}
                        value={getValues('manifest_format_colortype')}
                        initialValue={getValues('manifest_format_colortype')}
                        disabled={isVideoFieldDisabled}
                        placeholder={"Choose color type "}
                    />
                </FormRow>
            )}
            {(selectedGeneralFormat !== 'audio') && (
                <FormRow>
                    <Heading as="h5">Aspect Ratio</Heading>
                    <DropDown
                        id="manifest_format_aspect"
                        options={category === 'audiovisuals' ? aspectRatiosAudioVisual :
                            category === 'photos_and_images' ? aspectRatiosPhoto :
                                aspectRatiosDoc}
                        onSelect={(option) => handleDropDownSelect('manifest_format_aspect', option)}
                        value={getValues('manifest_format_aspect')}
                        initialValue={getValues('manifest_format_aspect')}
                        disabled={isVideoFieldDisabled}
                        placeholder={"Choose Aspect Ratio "}
                    />
                </FormRow>
            )}
            {(category === 'audiovisuals') && (
                <FormRow>
                    <Heading as="h5">Aperture</Heading>
                    <DropDown
                        id="manifest_format_aperture"
                        options={aperture}
                        onSelect={(option) => handleDropDownSelect('manifest_format_aperture', option)}
                        value={getValues('manifest_format_aperture')}
                        initialValue={getValues('manifest_format_aperture')}
                        placeholder={"Choose Aperture "}
                    />
                </FormRow>
            )}

            {(category !== 'paper_based_documents') && (
                <FormRow>
                    <Heading as="h5">Item stock</Heading>
                    <GroupedDropDown
                        id="manifest_format_stock"
                        options={category === 'audiovisuals' ? itemStockAudiovisual :
                            itemStockPhoto}
                        onSelect={(option) => handleDropDownSelect('manifest_format_stock', option)}
                        value={getValues('manifest_format_stock')}
                        initialValue={getValues('manifest_format_stock')}
                        label={"Choose item stock "}
                    />
                </FormRow>
            )}

            {category !== 'paper_based_documents' &&
                !(category === 'photos_and_images' && (selectedGeneralFormat === 'prints' || selectedGeneralFormat === 'film')) &&
                !(selectedGeneralFormat === 'digital' && selectedSpecificFormat === 'raw') &&
                selectedGeneralFormat !== 'audio' && (
                    <FormRow>
                        <Heading as="h5">Codec</Heading>
                        <DropDown
                            id="manifest_format_codec"
                            options={
                                category === 'audiovisuals' ? codecAudioVisual :
                                    category === 'photos_and_images' ? codecPhoto :
                                        codec
                            }
                            onSelect={(option) => handleDropDownSelect('manifest_format_codec', option)}
                            value={getValues('manifest_format_codec')}
                            initialValue={getValues('manifest_format_codec')}
                            disabled={isVideoFieldDisabled}
                            placeholder={"Choose Codec/Encoding"}
                        />
                    </FormRow>
                )}

            {(category === 'audiovisuals') && selectedGeneralFormat !== 'audio' && (
                <FormRow>
                    <Heading as="h5">Frame Rate</Heading>
                    <DropDown
                        id="manifest_format_frame"
                        options={frameRates}
                        onSelect={(option) => handleDropDownSelect('manifest_format_frame', option)}
                        value={getValues('manifest_format_frame')}
                        initialValue={getValues('manifest_format_frame')}
                        disabled={isVideoFieldDisabled}
                        placeholder={"Choose Frame Rate "}
                    />
                </FormRow>
            )}
            {!isDigitalFieldDisabled &&
                <FormRow>
                    <Heading as="h5">Resolution</Heading>
                    <DropDown
                        id="manifest_format_resolution"
                        options={category === 'audiovisuals' ? resolutionOptionsVideo :
                            category === 'photos_and_images' ? resolutionOptionsPhoto :
                                resolutionOptionsDoc}
                        onSelect={(option) => handleDropDownSelect('manifest_format_resolution', option)}
                        value={getValues('manifest_format_resolution')}
                        initialValue={getValues('manifest_format_resolution')}
                        disabled={isDigitalFieldDisabled}
                        placeholder={"Choose Resolution "}
                    />
                </FormRow>
            }
            {!isDigitalFieldDisabled &&
                <FormRow>
                    <Heading as="h5">Bit Depth</Heading>
                    <DropDown
                        id="manifest_format_depth"
                        options={category === 'audiovisuals' ? bitDepthRatesVideo :
                            category === 'photos_and_images' ? bitDepthRatesPhoto :
                                bitDepthRatesDoc}
                        onSelect={(option) => handleDropDownSelect('manifest_format_depth', option)}
                        value={getValues('manifest_format_depth')}
                        initialValue={getValues('manifest_format_depth')}
                        disabled={isDigitalFieldDisabled}
                        placeholder={"Choose Bit Depth "}
                    />
                </FormRow>
            }

            {(category === 'audiovisuals') && (
                <>
                    {!isDigitalFieldDisabled &&
                        <FormRow>
                            <Heading as="h5">Audio Sampling Frequency</Heading>
                            <DropDown
                                id="manifest_format_audiofrequency"
                                options={samplingFrequencyAudio}
                                onSelect={(option) => handleDropDownSelect('manifest_format_audiofrequency', option)}
                                value={getValues('manifest_format_audiofrequency')}
                                initialValue={getValues('manifest_format_audiofrequency')}
                                disabled={isDigitalFieldDisabled}
                                placeholder={"Choose Audio Sampling Frequency"}
                            />
                        </FormRow>
                    }
                    {!isDigitalFieldDisabled &&
                        <FormRow>
                            <Heading as="h5">Audio Bit Depth</Heading>
                            <DropDown
                                id="manifest_format_audiorate"
                                options={bitDepthRatesAudio}
                                onSelect={(option) => handleDropDownSelect('manifest_format_audiorate', option)}
                                value={getValues('manifest_format_audiorate')}
                                initialValue={getValues('manifest_format_audiorate')}
                                disabled={isDigitalFieldDisabled}
                                placeholder={"Choose Audio Bit Depth"}
                            />
                        </FormRow>
                    }
                    <FormRow>
                        <Heading as="h5">Audio Type</Heading>
                        <DropDown
                            id="manifest_format_audio"
                            options={audioTypeOptions}
                            onSelect={handleAudioTypeSelect}
                            value={audioType}
                            initialValue={getValues('manifest_format_audio')}
                            placeholder={"Choose Audio Type"}
                        />
                    </FormRow>

                    {(selectedGeneralFormat !== 'digital') && (
                        <FormRow>
                            <Heading as="h5">Audio Format</Heading>
                            <DropDown
                                id="manifest_format_audioformat"
                                options={manifestationSpecificFormatMapping.audio}
                                onSelect={(option) => handleDropDownSelect('manifest_format_audioformat', option)}
                                value={getValues('manifest_format_audioformat')}
                                initialValue={getValues('manifest_format_audioformat')}
                                disabled={isAudioFieldDisabled}
                                placeholder={audioFormatPlaceholder}
                            />
                        </FormRow>
                    )}


                    {(selectedGeneralFormat === 'digital') && (
                        <FormRow>
                            <Heading as="h5">Audio Format</Heading>
                            <DropDown
                                id="manifest_format_audioformat"
                                options={manifestationSpecificFormatMapping.digitalFormat}
                                onSelect={(option) => handleDropDownSelect('manifest_format_audioformat', option)}
                                value={getValues('manifest_format_audioformat')}
                                initialValue={getValues('manifest_format_audioformat')}
                                disabled={isAudioFieldDisabled}
                                placeholder={"Choose Audio Format"}
                            />
                        </FormRow>
                    )}

                    {(selectedGeneralFormat !== 'digital') && (
                        <FormRow>
                            <Heading as="h5">Audio System</Heading>
                            <DropDown
                                id="manifest_format_audiosystem"
                                options={audioSystemOptions}
                                onSelect={(option) => handleDropDownSelect('manifest_format_audiosystem', option)}
                                value={getValues('manifest_format_audiosystem')}
                                initialValue={getValues('manifest_format_audiosystem')}
                                disabled={isAudioFieldDisabled}
                                placeholder={"Choose Audio System"}
                            />
                        </FormRow>
                    )}
                    <FormRow>
                        <Heading as="h5">Audio Channel</Heading>
                        <DropDown
                            id="manifest_format_audiochannel"
                            options={audioChannel}
                            onSelect={(option) => handleDropDownSelect('manifest_format_audiochannel', option)}
                            value={getValues('manifest_format_audiochannel')}
                            initialValue={getValues('manifest_format_audiochannel')}
                            disabled={isAudioFieldDisabled}
                            placeholder={"Choose Audio Channel"}
                        />
                    </FormRow>
                </>
            )}
        </>
    );
};

export default ManifestationFormat;
