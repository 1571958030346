import axios from './axiosConfig';

export const fetchStorageData = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/storage`);

        return response.data;
    } catch (error) {
        console.error('Error fetching storage data:', error);
        throw error;
    }
}

function handleApiError(error) {
    if (error.response) {
        const message = `HTTP Error: ${error.response.status} ${error.response.data}`;
        console.error("API HTTP error:", message);
        alert(message);
    } else if (error.request) {
        console.error("API no response error:", error.request);
        alert('No response from server. Please check your network connection.');
    } else {
        console.error("API error:", error.message);
        alert('Error processing request. Please try again later.');
    }
    throw error;
}
