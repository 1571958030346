import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import { itemAccessStatus, itemAccessStatusObject } from '../../../data/itemData/itemData';
import Heading from '../../../ui/Heading';

const ItemAccessStatus = ({ category, errors }) => {
    const { register, setValue, watch } = useFormContext();
    return (
        <FormRow error={errors.item_accessstatus?.message}>
            <Heading as="h5">Access Status</Heading>
            <DropDown
                id="item_accessstatus"
                placeholder={"Choose Access Status"}
                options={category !== 'objects' ?
                    itemAccessStatus.map(status => ({ label: status.label, value: status.value })) :
                    itemAccessStatusObject.map(status => ({ label: status.label, value: status.value }))}
                onSelect={option => setValue('item_accessstatus', option.value || "")}
                value={watch('item_accessstatus') || ""}
                {...register('item_accessstatus', { required: "Please choose a access status", })}
            />
        </FormRow>
    );
};

export default ItemAccessStatus;
