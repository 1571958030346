import React, { useEffect, useState } from 'react';
import { fetchManifestations, deleteManifestation, updateManifestation } from '../../../../services/apiManifestation';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchUserById } from '../../../../services/apiUser';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SpinnerMini from '../../../../ui/SpinnerMini';
import {
    Typography,
    IconButton,
    Collapse,
    Box,
    TablePagination,
    Table as MuiTable,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
} from '@mui/material';
import { MdOutlineExpandLess, MdOutlineExpandMore, MdEdit, MdDelete } from "react-icons/md";
import Heading from '../../../../ui/Heading';
import FormRow from '../../../../ui/FormRow';
import Table from '../../../../ui/Table';
import StyledFilterField from '../../../../ui/StyledFilterField';
import CustomModal from '../../../../ui/CustomModal';
import { useUser } from '../../../authentication/useUser';
import TextButton from '../../../../ui/TextButton';
import PreviewContent from '../../../../ui/PreviewContent';
import { convertLanguageCodesToNames, convertCountryCodesToNames } from '../../../../utils/countries';
const StyledNavLink = styled(NavLink)`
    cursor: pointer;
    &:hover {
        background: transparent;
        color: var(--color-brand-600);
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        color: var(--color-grey-400);
        h5 {
            color: var(--color-grey-400);
        }
    }
    &:link,
    &:visited {
        display: flex;
        align-items: center;
        color: var(--color-grey-600);
        transition: all 0.3s;
    }
`;

function ManifestationTable() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useUser();
    const [manifestations, setManifestations] = useState([]);
    const [filteredManifestations, setFilteredManifestations] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, manifestId: null });
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        async function loadManifestations() {
            setIsLoading(true);
            try {
                if (user) {
                    const data = await fetchManifestations(user.user.user_id, user.user.user_role);
                    setManifestations(data);
                    setFilteredManifestations(data);
                    setError(null);
                }
            } catch (err) {
                setError('Failed to fetch manifestations');
            } finally {
                setIsLoading(false);
            }
        }
        loadManifestations();
    }, [user]);

    useEffect(() => {
        manifestations.forEach(manifestation => {
            if (!userDetails[manifestation.user_id]) {
                getUserDetails(manifestation.user_id);
            }
        });
    }, [manifestations]);

    async function getUserDetails(userId) {
        if (userDetails[userId]) {
            return;
        }
        setIsLoading(true);
        try {
            const userDetailsResponse = await fetchUserById(userId);
            if (userDetailsResponse) {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [userId]: `${userDetailsResponse.user_fname} ${userDetailsResponse.user_lname}`
                }));
            }
            else {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [userId]: 'Unknown User'
                }));
            }
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            setUserDetails(prevDetails => ({
                ...prevDetails,
                [userId]: 'Unknown User'
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const handleFilterChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(searchText);
        const filtered = manifestations.filter(manifestation =>
            manifestation.manifest_title_proper.toLowerCase().includes(searchText)
            || manifestation.manifest_title_alternative.toLowerCase().includes(searchText)
            || manifestation.manifest_title_supplied.toLowerCase().includes(searchText)
            || manifestation.manifest_title_other.toLowerCase().includes(searchText)
            || manifestation.manifest_type.toLowerCase().includes(searchText)
            || (manifestation.manifest_nfaid && manifestation.manifest_nfaid.toLowerCase().includes(searchText))
            || (manifestation.category && manifestation.category.toLowerCase().includes(searchText))
        );
        setFilteredManifestations(filtered);
        setPage(0);
    };


    const handleDeleteClick = (manifestId) => {
        setConfirmDelete({ open: true, manifestId });
    };

    const handleDelete = async () => {
        if (confirmDelete.manifestId !== null) {
            setIsLoading(true);
            try {
                await deleteManifestation(confirmDelete.manifestId);
                setManifestations(manifestations.filter(manifestation => manifestation.manifest_id !== confirmDelete.manifestId));
                setFilteredManifestations(filteredManifestations.filter(manifestation => manifestation.manifest_id !== confirmDelete.manifestId));
                setError(null);

            } catch (err) {
                setError('Failed to delete manifestation');

            } finally {
                setIsLoading(false);
                setConfirmDelete({ open: false, manifestId: null });
            }
        }
    };

    const handleEdit = (manifestId) => {
        navigate(`/edit-manifestation/${manifestId}`);
    };


    const handleExpandClick = (manifestId) => {
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(manifestId)) {
            newExpandedRows.delete(manifestId);
        } else {
            newExpandedRows.add(manifestId);
        }
        setExpandedRows(newExpandedRows);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return <SpinnerMini />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    const renderEventDetails = (eventType, eventDetails) => {
        switch (eventType) {
            case 'event_publication':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Publication Event:</Heading>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Type: {eventDetail.type}</p>
                        <p>Region: {convertCountryCodesToNames(eventDetail.region)}</p>
                    </div>
                ));
            case 'event_award':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Award Event:</Heading>
                        <p>Name: {eventDetail.name}</p>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Achievement: {eventDetail.achievement}</p>
                        <p>Nomination: {eventDetail.nomination ? 'Yes' : 'No'}</p>
                    </div>
                ));
            case 'event_licensing':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Licensing Event:</Heading>
                        <p>Notes: {eventDetail.notes}</p>
                    </div>
                ));
            case 'event_preservation':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Preservation Event:</Heading>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Type: {eventDetail.type}</p>
                        <p>Notes: {eventDetail.notes}</p>
                    </div>
                ));
            case 'event_decision':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Decision Event:</Heading>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Scope: {convertCountryCodesToNames(eventDetail.scope)}</p>
                        <p>Certificate Number: {eventDetail.certificateNumber}</p>
                        <p>Verdict: {eventDetail.verdict}</p>
                    </div>
                ));
            case 'event_manufacture':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Manufacture Event:</Heading>
                        <p>Type: {eventDetail.type}</p>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Region: {convertCountryCodesToNames(eventDetail.region)}</p>
                    </div>
                ));
            default:
                return <Heading as='h8'>No details available</Heading>;
        }
    };

    const RenderLanguageDetails = ({ languages, category }) => {
        if (!Array.isArray(languages) || languages.length === 0) {
            return <div>No languages available</div>;
        }

        return languages.map((lang, index) => (
            <div key={index}>
                {category === "audiovisuals" && (
                    <FormRow>
                        <Heading as={"h8"}>Language: {convertLanguageCodesToNames(lang.language)}</Heading>
                        {lang.written && <Heading as={"h8"}>Written: {lang.writtenType}</Heading>}
                        {lang.dialogue && <Heading as={"h8"}>Dialogue: {lang.dialogueType}</Heading>}
                    </FormRow>
                )}
                {category === "paper_based_documents" && (
                    <FormRow>
                        <Heading as={"h8"}>Language: {convertLanguageCodesToNames(lang.language)}</Heading>
                        <Heading as={"h8"}>Written: {lang.writtenType}</Heading>
                    </FormRow>
                )}
            </div>
        ));
    };

    const renderManifestationRow = (manifestation) => {
        return (
            <React.Fragment key={manifestation.manifest_id}>
                <Table.Row>
                    <Heading as={"h8"}> {manifestation.manifest_title_proper}
                        <br />
                        <Typography style={{ fontSize: '0.8rem' }}>
                            {manifestation.manifest_nfaid}
                        </Typography>
                        <br />
                        <Typography variant="caption" style={{ display: 'block' }}>
                            {`Entered by: ${userDetails[manifestation.user_id] ? userDetails[manifestation.user_id] : <SpinnerMini />}`}
                        </Typography>
                    </Heading>
                    <Heading as={"h8"}>{manifestation.category}</Heading>
                    <Heading as={"h8"}>{manifestation.manifest_type}</Heading>
                    <FormRow>

                        {expandedRows.has(manifestation.manifest_id) ? <StyledNavLink onClick={() => handleExpandClick(manifestation.manifest_id)} ><MdOutlineExpandLess /> </StyledNavLink> : <StyledNavLink StyledNavLink onClick={() => handleExpandClick(manifestation.manifest_id)}> <MdOutlineExpandMore /></StyledNavLink>}
                        <StyledNavLink as="div" onClick={() => handleEdit(manifestation.manifest_id)}> <MdEdit /></StyledNavLink>
                        <StyledNavLink onClick={() => handleDeleteClick(manifestation.manifest_id)}> <MdDelete /></StyledNavLink>
                        <TextButton label="Fiaf card" link={`/manifestation-details/${manifestation.manifest_id}`}></TextButton>
                    </FormRow>
                </Table.Row>
                <Collapse in={expandedRows.has(manifestation.manifest_id)} timeout="auto" unmountOnExit>
                    <Box margin={2}>
                        <TableBody>
                            {Object.entries({
                                'manifestation_table.alternative_title': manifestation.manifest_title_alternative ? manifestation.manifest_title_alternative : "No data available",
                                'manifestation_table.supplied_title': manifestation.manifest_title_supplied ? manifestation.manifest_title_supplied : "No data available",
                                ...(manifestation.category === "photos_and_images" ? { 'manifestation_table.caption_title': manifestation.manifestation_title_caption } : {}),
                                'manifestation_table.other_title': manifestation.manifest_title_other ? manifestation.manifest_title_other : "No data available",
                                'manifestation_table.general_format': manifestation.manifest_format_general ? manifestation.manifest_format_general : "No data available",
                                'manifestation_table.specific_format': manifestation.manifest_format_specific ? manifestation.manifest_format_specific : "No data available",
                                'manifestation_table.color_standard': manifestation.manifest_format_colorstandard ? manifestation.manifest_format_colorstandard : "No data available",
                                'manifestation_table.color_type': manifestation.manifest_format_colortype ? manifestation.manifest_format_colortype : "No data available",
                                'manifestation_table.aspect_ratio': manifestation.manifest_format_aspect ? manifestation.manifest_format_aspect : "No data available",
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.aperture': manifestation.manifest_format_aperture ? manifestation.manifest_format_aperture : "No data available" } : {}),
                                ...(manifestation.category !== "paper_based_documents" ? { 'manifestation_table.stock': manifestation.manifest_format_stock ? manifestation.manifest_format_stock : "No data available" } : {}),
                                ...(manifestation.category !== "paper_based_documents" ? { 'manifestation_table.codec': manifestation.manifest_format_codec ? manifestation.manifest_format_codec : "No data available" } : {}),
                                'manifestation_table.frame_rate': manifestation.manifest_format_frame ? manifestation.manifest_format_frame : "No data available",
                                'manifestation_table.resolution': manifestation.manifest_format_resolution ? manifestation.manifest_format_resolution : "No data available",
                                'manifestation_table.bit_depth_rate': manifestation.manifest_format_depth ? manifestation.manifest_format_depth : "No data available",
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.audio_frequency': manifestation.manifest_format_audiofrequency ? manifestation.manifest_format_audiofrequency : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.audio_rate': manifestation.manifest_format_audiorate ? manifestation.manifest_format_audiorate : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.audio': manifestation.manifest_format_audio ? manifestation.manifest_format_audio : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.audio_format': manifestation.manifest_format_audioformat ? manifestation.manifest_format_audioformat : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.audio_system': manifestation.manifest_format_audiosystem ? manifestation.manifest_format_audiosystem : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.audio_channel': manifestation.manifest_format_audiochannel ? manifestation.manifest_format_audiochannel : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.length': manifestation.manifest_format_length ? manifestation.manifest_format_length : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.duration': manifestation.manifest_format_duration ? manifestation.manifest_format_duration : "No data available" } : {}),
                                ...(manifestation.category !== "photos_and_images" ? { 'manifestation_table.language': manifestation.manifest_format_language && manifestation.manifest_format_language.length > 0 ? <RenderLanguageDetails category={manifestation.category} languages={manifestation.manifest_format_language} /> : "No data available" } : {}),
                                ...(manifestation.category === "audiovisuals" ? { 'manifestation_table.usage': manifestation.manifest_format_usage ? manifestation.manifest_format_usage : "No data available" } : {}),
                            }).map(([label, value], index) => (
                                <Table.Row key={index}>
                                    <Heading as='h7'>
                                        {t(label)}
                                    </Heading>
                                    <Heading as={'h8'}>
                                        {Array.isArray(value)
                                            ? value.map((item, idx) => (
                                                <div key={idx}>{JSON.stringify(item)}</div>
                                            ))
                                            : value}
                                    </Heading>
                                </Table.Row>
                            ))}
                        </TableBody>

                        <Table.Accordion
                            summary={t('table.agents')}
                            details={
                                <MuiTable size="small">
                                    <TableHead>

                                        <Table.Cell>{t('agent.type')}</ Table.Cell>
                                        <Table.Cell>{t('agent.description')}</Table.Cell>

                                    </TableHead>
                                    <TableBody>
                                        {(manifestation.manifestation_agents || []).map((agent, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell><Heading as={'h8'}>{agent.agent_type ? agent.agent_type : "No data available"}</Heading></TableCell>
                                                <TableCell><Heading as={'h8'}>{agent.agent_description ? agent.agent_description : "No data available"}</Heading></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </MuiTable>
                            }
                        />
                        <Table.Accordion
                            summary={t('table.events')}
                            details={


                                <MuiTable size="small">
                                    <TableHead>
                                        <TableCell><Heading as='h7'>{t('event.type')}</Heading></TableCell>
                                        <TableCell ><Heading as='h7'>{t('event.details')}</Heading></TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {['event_publication', 'event_award', 'event_licensing', 'event_preservation', 'event_decision', 'event_manufacture'].map((eventType, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell><Heading as='h7'>{t(eventType)}</Heading></TableCell>
                                                <Table.Cell>
                                                    {manifestation.manifestation_events && manifestation.manifestation_events[0] && manifestation.manifestation_events[0][eventType] && manifestation.manifestation_events[0][eventType].length > 0 ?
                                                        renderEventDetails(eventType, manifestation.manifestation_events[0][eventType])
                                                        : <Heading as='h8'>No data available</Heading>}
                                                </Table.Cell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </MuiTable>
                            }
                        />
                        <Table.Accordion
                            summary={t('table.preview')}
                            details={
                                <PreviewContent category={manifestation.category} />
                            }
                        />
                    </Box>
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <div style={{ width: '100%', marginTop: '1rem' }}>
            <Heading as="h3" > {t('titles.manifestation')}</Heading>
            <StyledFilterField
                label={<Heading as="h6" > {t('table.filter')}</Heading>}
                variant="outlined"
                value={filterText}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                style={{ marginBottom: '1rem' }}
            />
            <Table columns="repeat(7, 1fr)">
                <Table.Header>
                    <Heading as="h6" > {t('manifestation_table.title')}</Heading>
                    <Heading as="h6" > {t('table.category')}</Heading>
                    <Heading as="h6" > {t('manifestation_table.type')}</Heading>
                    <Heading as="h6" > {t('actions.actions')}</Heading>
                </Table.Header>
                <Table.Body data={filteredManifestations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} render={renderManifestationRow} />
            </Table>
            <Table.Footer>
                <TablePagination
                    component="div"
                    count={filteredManifestations.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Table.Footer>
            <CustomModal
                isOpen={confirmDelete.open}
                onRequestClose={() => setConfirmDelete({ open: false, workId: null })}
                onConfirm={handleDelete}
                confirmationMessage={
                    <>
                        Are you sure you want to delete this manifestation?
                        <br />
                        <span style={{ color: 'var(--color-red-800)', fontWeight: 'bold' }}>This action cannot be undone</span>
                    </>
                }
            />
        </div>
    );
}
export default ManifestationTable;
