import React, { useState, useRef, useEffect } from 'react';
import Button from "../../ui/Button";
import { useTranslation } from 'react-i18next';
import TwoFactorInput from '../../ui/TwoFactorInput';
import SpinnerMini from '../../ui/SpinnerMini';
import Form from '../../ui/Form';
import FormRow from '../../ui/FormRow';
import FormRowVertical from '../../ui/FormRowVertical';

function TwoFactorAuthForm({ email, verify2FA, isVerifying }) {
    const { t } = useTranslation();
    const [digits, setDigits] = useState(Array(6).fill(""));
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current = Array.from({ length: 6 }, (_, i) => inputRefs.current[i] || React.createRef());
    }, []);

    function handleInput(e, index) {
        const value = e.target.value;
        const newDigits = [...digits];
        newDigits[index] = value;
        setDigits(newDigits);

        if (value.length === 1 && index < 5) {
            if (inputRefs.current[index + 1] && inputRefs.current[index + 1].current) {
                inputRefs.current[index + 1].current.focus();
            }
        }
    }

    function handleBackspace(e, index) {
        if (e.key === "Backspace" && !digits[index]) {
            if (index > 0 && inputRefs.current[index - 1] && inputRefs.current[index - 1].current) {
                inputRefs.current[index - 1].current.focus();
            }
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        const code = digits.join('');
        if (code.length !== 6) return;

        verify2FA({ user_email: email, code });
    }

    return (
        <div>
            <FormRowVertical>
                <Form>
                    <FormRow>
                        {digits.map((digit, index) => (
                            <TwoFactorInput
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleInput(e, index)}
                                onInput={(e) => handleInput(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                ref={inputRefs.current[index]}
                                disabled={isVerifying}
                            />
                        ))}
                    </FormRow>
                </Form>

                <Button onClick={handleSubmit} size="large" disabled={isVerifying || digits.some(digit => digit === "")}>
                    {isVerifying ? <SpinnerMini /> : t('verify.two_factor_authentication')}
                </Button>
            </FormRowVertical>
        </div>
    );
}

export default TwoFactorAuthForm;
