
export const recordCategories = [
    { label: 'Audiovisuals', value: 'audiovisuals' },
    { label: 'Photos And Images', value: 'photos_and_images' },
    { label: 'Paper based Documents (Texts)', value: 'paper_based_documents' },
    { label: 'Objects', value: 'objects' },
];

export const recordTypes = [
    { label: 'Work (W)', value: 'work' },
    { label: 'Manifestation (M)', value: 'manifestation' },
    { label: 'Item (I)', value: 'item' },
];

