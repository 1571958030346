import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

// UI Components
import Heading from '../../../ui/Heading';
import Form from '../../../ui/Form';
import FormGroup from '../../../ui/FormGroup';
import Button from '../../../ui/Button';
import CustomModal from '../../../ui/CustomModal';
import Warning from '../../../ui/Warning';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';

// Form Sections
import ItemTitles from './ItemTitles';
import ItemGeneralFields from './ItemGeneralFields';
import ItemLocation from './ItemLocation';
import ItemFormat from './ItemFormat';
import ItemAgents from './ItemAgents';
import ItemExtent from './ItemExtent';
import ItemEvents from './ItemEvents';
import ItemMeasurements from './ItemMeasurements';
import ItemAccessCondition from './ItemAccessCondition';
import ItemAccessStatus from './ItemAccessStatus';
import PartOfItem from './PartOfItem';

// Data
import { fetchItemById, updateItem } from '../../../services/apiItem';
import { useUser } from '../../authentication/useUser';
import { getCountryOptions } from '../../../utils/countries';
import 'react-datepicker/dist/react-datepicker.css';

function EditItemForm() {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const countryOptions = getCountryOptions();
    const methods = useForm({
        mode: 'onBlur',
    });
    const { user } = useUser();
    const userId = user.user.user_id;

    const [agents, setAgents] = useState([]);
    const [inspectionEvents, setInspectionEvents] = useState([]);
    const [licensingEvents, setLicensingEvents] = useState([]);
    const [preservationEvents, setPreservationEvents] = useState([]);
    const [acquisitionEvents, setAcquisitionEvents] = useState([]);
    const [category, setCategory] = useState(null);
    const [eventTypes, setEventTypes] = useState({
        inspection: false,
        licensing: false,
        preservation: false,
        acquisition: false,
    });
    const [selectedManifestId, setSelectedManifestId] = useState({ manifestId: null, workId: null });
    const [manifestTitle, setManifestTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkedConditions, setCheckedConditions] = useState({
        Base_Emulsion: [],
        Physical_Structural: [],
        Perforations: [],
        Deposit: [],
        Image: [],
        Decomposition: [],
        Shrinkage: ''
    });
    const [warning, setWarning] = useState({ visible: false, message: '', color: '' });
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const {
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        watch
    } = methods;

    const accessStatus = watch("item_accessstatus");
    const formatResolution = watch("item_format_resolution");
    const formatDepth = watch("item_format_depth");
    const formatAudioRate = watch("item_format_audioRate");
    const formatFrequency = watch("item_format_audioFrequency");
    const itemGeneralFormat = watch("item_format_general");
    const itemSpecificFormat = watch("item_format_specific");

    const queryClient = useQueryClient();

    const mapEventsToState = (events) => {
        const inspection = [];
        const licensing = [];
        const preservation = [];
        const acquisition = [];
        if (events.length > 0) {
            events[0].event_inspection?.forEach(event => inspection.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
            events[0].event_licensing?.forEach(event => licensing.push({
                ...event,
                id: uuidv4(),
            }));
            events[0].event_preservation?.forEach(event => preservation.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
            events[0].event_acquisition?.forEach(event => acquisition.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
        }
        return { inspection, licensing, preservation, acquisition };
    };

    const { data: itemData, isLoading: isFetching } = useQuery(['item', itemId], () => fetchItemById(itemId), {
        onSuccess: (data) => {
            if (!isDataLoaded) {
                const {
                    item_agents,
                    item_events,
                    part_of_manifest_id,
                    part_of_manifest_title,
                    part_of_work_id,
                    item_accesscondition,
                    category,
                    ...restData
                } = data;

                const { inspection, licensing, preservation, acquisition } = mapEventsToState(item_events || {});

                reset(restData);
                setCategory(category);
                setAgents(item_agents.map(agent => ({
                    ...agent,
                    id: uuidv4(),
                    type: agent.agent_type,
                    description: agent.agent_description,
                })) || []);
                setInspectionEvents(inspection);
                setLicensingEvents(licensing);
                setPreservationEvents(preservation);
                setAcquisitionEvents(acquisition);
                setEventTypes({
                    licensing: licensing.length > 0,
                    preservation: preservation.length > 0,
                    acquisition: acquisition.length > 0,
                    inspection: inspection.length > 0,
                });
                setSelectedManifestId({ manifestId: part_of_manifest_id, workId: part_of_work_id });
                setManifestTitle(part_of_manifest_title);
                setCheckedConditions({
                    Base_Emulsion: item_accesscondition.Base_Emulsion || [],
                    Physical_Structural: item_accesscondition.Physical_Structural || [],
                    Perforations: item_accesscondition.Perforations || [],
                    Deposit: item_accesscondition.Deposit || [],
                    Image: item_accesscondition.Image || [],
                    Decomposition: item_accesscondition.Decomposition || [],
                    Shrinkage: item_accesscondition.Shrinkage || ''
                });
                setIsDataLoaded(true);
            }
        },
        onError: (err) => toast.error('Error fetching item data: ' + err.message),
    });

    const { mutate, isLoading: isUpdating } = useMutation({
        mutationFn: updateItem,
        onSuccess: () => {
            toast.success('Item updated successfully');
            queryClient.invalidateQueries(['item', itemId]);
            navigate(-1);
        },
        onError: (err) => toast.error('Error updating item: ' + err.message),
    });

    const onSubmit = (data, event) => {
        event.preventDefault();
        if (!selectedManifestId.manifestId) {
            setWarning({
                visible: true,
                message: "The Item is required to be connected to a proper Manifestation. Would you like to continue commit registry?",
                color: 'yellow'
            });
            return setTimeout(() => setIsModalOpen(true), 1500);
        }

        setIsModalOpen(true);
    };


    const handleConfirmSubmission = () => {
        setIsModalOpen(false);

        const data = methods.getValues();

        const itemData = {
            ...data,
            user_id: userId,
            agents: agents.map(agent => ({
                agent_type: agent.type,
                agent_description: agent.description,
            })),
            events: {
                inspection: inspectionEvents,
                licensing: licensingEvents,
                preservation: preservationEvents,
                acquisition: acquisitionEvents
            },
            item_accesscondition: checkedConditions,
            item_status: warning.color,
            part_of_manifest_id: selectedManifestId.manifestId,
            part_of_work_id: selectedManifestId.workId,
        };
        mutate({ itemId, itemData });
    };

    const onError = () => {
        setWarning({
            visible: true,
            message: "A mandatory field must be filled",
            color: 'red'
        });
    };

    const toggleEventType = (type) => {
        setEventTypes(prev => ({ ...prev, [type]: !prev[type] }));
    };

    if (isFetching) return <SpinnerOverlay />;

    return (
        <FormProvider {...methods}>
            <Heading as="h3">Edit Item</Heading>
            <Form type="tertiary" onSubmit={handleSubmit(onSubmit, onError)}>
                <FormGroup title="General Information">
                    <ItemGeneralFields category={category} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemTitles category={category} isCreating={isUpdating} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemLocation errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemFormat category={category} errors={errors} />
                </FormGroup>
                {(category === 'objects') && (
                    <FormGroup>
                        <ItemMeasurements category={category} isCreating={isUpdating} errors={errors} />
                    </FormGroup>
                )}
                {(category !== 'objects') && (
                    <FormGroup>
                        <ItemExtent category={category} isCreating={isUpdating} />
                    </FormGroup>
                )}
                <FormGroup>
                    <ItemAccessCondition category={category} errors={errors} checkedConditions={checkedConditions} setCheckedConditions={setCheckedConditions} />
                </FormGroup>
                <FormGroup>
                    <ItemAccessStatus category={category} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemEvents
                        eventTypes={eventTypes}
                        toggleEventType={toggleEventType}
                        inspectionEvents={inspectionEvents}
                        setInspectionEvents={setInspectionEvents}
                        licensingEvents={licensingEvents}
                        setLicensingEvents={setLicensingEvents}
                        preservationEvents={preservationEvents}
                        setPreservationEvents={setPreservationEvents}
                        acquisitionEvents={acquisitionEvents}
                        setAcquisitionEvents={setAcquisitionEvents}
                        countryOptions={countryOptions}
                        errors={errors}
                    />
                </FormGroup>
                <FormGroup>
                    {!isFetching && agents.length > 0 && (
                        <ItemAgents
                            category={category}
                            agents={agents}
                            setAgents={setAgents}
                            errors={errors}
                        />
                    )}
                </FormGroup>
                <FormGroup>

                    <PartOfItem
                        selectedManifestId={selectedManifestId}
                        setSelectedManifestId={setSelectedManifestId}
                        category={category}
                        errors={errors}
                    />

                </FormGroup>


                <Button variation="primary" type="submit" disabled={isUpdating}>
                    Update
                </Button>
                {warning.visible && (
                    <Warning message={warning.message} color={warning.color} />
                )}
                {isUpdating && <SpinnerOverlay />}
                <CustomModal
                    isOpen={isModalOpen}
                    confirmationMessage={"Are you sure you want to update the item?"}
                    onRequestClose={() => setIsModalOpen(false)}
                    onConfirm={handleConfirmSubmission}
                    message="Are you sure you want to submit the record?"
                />
            </Form>
        </FormProvider>
    );
}

export default EditItemForm;
