
import DashboardLayout from "../features/dashboard/DashboardLayout";
import Heading from "../ui/Heading";
import Row from "../ui/Row";
import { useTranslation } from 'react-i18next';
function Dashboard() {
  const { t } = useTranslation();
  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">{t('titles.dashboard')}</Heading>
      </Row>
      <DashboardLayout />
    </>
  );
}

export default Dashboard;
