import styled from 'styled-components';

export const StyledFormControl = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledLabel = styled.label`
    margin-bottom: 8px;
`;


export const StyledInput = styled.input`
    padding: 0.8rem 1.2rem;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border: 1px solid var(--color-grey-200);
    background-color: var(--color-grey-0);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-sm);
    color: var(--color-grey-900);
    box-sizing: border-box;

    &:hover {
        border-color: var(--color-brand-600);
    }

    &:focus {
        outline: 2px solid var(--color-brand-600);
    }
`;

export const StyledOptionsContainer = styled.div`
    max-height: 200px;
    width: 100%; 
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid var(--color-grey-200);
    background-color: var(--color-grey-0);
    z-index: 1;
    overflow-y: auto;
`;

export const StyledOption = styled.div`
    padding: 8px;
    cursor: pointer;
    color: var(--color-grey-900);
    background-color: var(--color-grey-0);

    &:hover {
        background-color: var(--color-grey-100);
    }

    body.dark-mode & {
        color: var(--color-grey-0);
        background-color: var(--color-grey-700);

        &:hover {
            background-color: var(--color-grey-600);
        }
    }
`;