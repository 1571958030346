import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DropDown from '../../../ui/DropDown';
import toast from 'react-hot-toast';

const PartOfManifestation = ({ selectedWorkId, setSelectedWorkId, category, isEditing, currentWorkId }) => {
    const [works, setWorks] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        const fetchWorks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/works-by-category?category=${encodeURIComponent(category)}`);
                if (response.data.message) {
                    toast(response.data.message, {
                        icon: 'ℹ️',
                    });
                } else {
                    const uniqueWorks = response.data.reduce((acc, work) => {
                        if (!acc.some(item => item.value === work.value)) {
                            acc.push(work);
                        }
                        return acc;
                    }, []);
                    setWorks(uniqueWorks);
                }
            } catch (error) {
                toast(`Error fetching works: ${error.response ? error.response.data.message : error.message}`, {
                    icon: '❌',
                });
            }
        };

        fetchWorks();
    }, [category]);


    const handleSelectWork = (option) => {
        setSelectedWorkId(option.value);
    };

    return (
        <>
            <DropDown
                key={works.length}
                id="part_of_work"
                options={works}
                onSelect={handleSelectWork}
                value={selectedWorkId}
                label="This Manifestation is Part of"
            />
        </>
    );
};

export default PartOfManifestation;
