import styled from "styled-components";
import NewRecord from "./NewRecord";
import RecordTable from "./RecordTable";

function RecordsLayout() {
  return (
    <>
      <RecordTable />
    </>
  );
}

export default RecordsLayout;
