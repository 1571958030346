import styled from "styled-components";
import LoginForm from "../features/authentication/LoginForm";
import Logo from "../ui/Logo";
import Heading from "../ui/Heading";
import LanguageToggle from "../ui/LanguageToggle"
import DarkModeToggle from "../ui/DarkModeToggle"
import { useTranslation } from 'react-i18next';

const LoginLayout = styled.main`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 48rem;
  align-content: center;
  justify-content: center;
  gap: 3.2rem;
  background-color: var(--color-grey-50);
`;

const TogglesWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
`;


function Login() {
  const { t } = useTranslation();
  return (
    <LoginLayout>
      <TogglesWrapper>
        <LanguageToggle />
        <DarkModeToggle />
      </TogglesWrapper>
      <Logo />
      <Heading as="h4">{t('titles.website')}</Heading>
      <Heading as="h4">{t('login.title')}</Heading>
      <LoginForm />
    </LoginLayout>
  );
}

export default Login;
