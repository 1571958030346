import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: var(--color-grey-50);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const TitleBar = styled.div`
    background-color: var(--color-grey-100);
    color: var(--color-grey-600);
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
`;

const PreviewImage = styled.img`
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
`;

const Tabs = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: var(--color-grey-50);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: 10px;
`;

const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 10px;
    color: var(--color-grey-700);
    background: ${(props) => (props.active ? 'var(--color-grey-50)' : 'var(--color-grey-100)')};
    border: ${(props) => (props.active ? '1px solid var(--color-grey-200)' : '1px solid transparent')};
    cursor: pointer;
`;

const Content = styled.div`
    flex: 1;
    margin-top: 10px;
    overflow: auto;
`;

const formatCategoryName = (category) => {
    if (!category) {
        return 'Unknown Category';
    }
    return category
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const PreviewContent = ({ category }) => {
    const [activeTab, setActiveTab] = useState('Acsess');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Container>
            <TitleBar>{formatCategoryName(category)} View</TitleBar>
            <PreviewImage src="https://via.placeholder.com/150x150?text=Preview" alt="Preview" />
            <Tabs>
                <Tab active={activeTab === 'Acsess'} onClick={() => handleTabClick('Acsess')}>Acsess</Tab>
                <Tab active={activeTab === 'Atts'} onClick={() => handleTabClick('Atts')}>Atts.</Tab>
            </Tabs>
            {/*TODO content goes here*/}
            {/* <Content>
                {activeTab === 'Acsess' && <div>Access</div>}
                {activeTab === 'Atts' && <div>Attachments</div>}
            </Content> */}
        </Container>
    );
};

export default PreviewContent;
