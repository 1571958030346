import React from 'react';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import { StyledTextField } from '../../../ui/StyledComponents';
import DatePickerWrapper from '../../../ui/DatePickerWrapper';
import Heading from '../../../ui/Heading';
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
import { manifestationPublication, manifestationPublicationPhoto, manifestationPreservation, manifestationDecision, manifestationManufacture } from "../../../data/manifestationData/manifestationData";
import { convertCountryCodesToNames } from '../../../utils/countries';
import { v4 as uuidv4 } from 'uuid';

const ManifestationEvents = ({
    eventTypes,
    toggleEventType,
    publicationEvents,
    setPublicationEvents,
    awardEvents,
    setAwardEvents,
    licensingEvents,
    setLicensingEvents,
    preservationEvents,
    setPreservationEvents,
    decisionEvents,
    setDecisionEvents,
    manufactureEvents,
    setManufactureEvents,
    countryOptions,
    category
}) => {
    const safeMap = (array, callback) => Array.isArray(array) ? array.map(callback) : [];

    const addPublicationEvent = (event) => {
        event.preventDefault();
        setPublicationEvents([...publicationEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            region: ''
        }]);
    };

    const removePublicationEvent = (index) => {
        setPublicationEvents(publicationEvents.filter((_, i) => i !== index));
    };

    const handlePublicationChange = (index, field, value) => {
        const updatedEvents = [...publicationEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setPublicationEvents(updatedEvents);
    };

    const addAwardEvent = (event) => {
        event.preventDefault();
        setAwardEvents([...awardEvents, {
            id: uuidv4(),
            name: '',
            date: new Date(),
            achievement: '',
            nomination: false
        }]);
    };

    const removeAwardEvent = (index) => {
        setAwardEvents(awardEvents.filter((_, i) => i !== index));
    };

    const handleAwardChange = (index, field, value) => {
        const updatedEvents = [...awardEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setAwardEvents(updatedEvents);
    };

    const addLicensingEvent = (event) => {
        event.preventDefault();
        setLicensingEvents([...licensingEvents, {
            id: uuidv4(),
            notes: '',
        }]);
    };

    const removeLicensingEvent = (index) => {
        setLicensingEvents(licensingEvents.filter((_, i) => i !== index));
    };

    const handleLicensingChange = (index, field, value) => {
        const updatedEvents = [...licensingEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setLicensingEvents(updatedEvents);
    };

    const addPreservationEvent = (event) => {
        event.preventDefault();
        setPreservationEvents([...preservationEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            notes: '',
        }]);
    };

    const removePreservationEvent = (index) => {
        setPreservationEvents(preservationEvents.filter((_, i) => i !== index));
    };

    const handlePreservationChange = (index, field, value) => {
        const updatedEvents = [...preservationEvents];
        if (field === 'region') {
            updatedEvents[index][field] = value.value;
        } else {
            updatedEvents[index][field] = value;
        }
        setPreservationEvents(updatedEvents);
    };


    const addDecisionEvent = (event) => {
        event.preventDefault();
        setDecisionEvents([...decisionEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            scope: '',
            certificateNumber: '',
            verdict: ''
        }]);
    };

    const removeDecisionEvent = (index) => {
        setDecisionEvents(decisionEvents.filter((_, i) => i !== index));
    };

    const handleDecisionChange = (index, field, value) => {
        const updatedEvents = [...decisionEvents];
        if (field === 'scope') {
            updatedEvents[index][field] = value.value;
        } else {
            updatedEvents[index][field] = value;
        }
        setDecisionEvents(updatedEvents);
    };

    const addManufactureEvent = (event) => {
        event.preventDefault();
        setManufactureEvents([...manufactureEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            region: '',
        }]);
    };

    const removeManufactureEvent = (index) => {
        setManufactureEvents(manufactureEvents.filter((_, i) => i !== index));
    };

    const handleManufactureChange = (index, field, value) => {
        const updatedEvents = [...manufactureEvents];
        if (field === 'region') {
            updatedEvents[index][field] = value.value;
        } else {
            updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        }
        setManufactureEvents(updatedEvents);
    };

    const getPublicationData = (category) => {
        switch (category) {
            case 'photos_and_images':
                return manifestationPublicationPhoto;
            case 'audiovisuals':
            case 'paper_based_documents':
                return manifestationPublication;
            default:
                return [];
        }
    };

    const publicationData = getPublicationData(category);

    const handleToggleEventType = (type) => {
        if (eventTypes[type]) {
            switch (type) {
                case 'publication':
                    setPublicationEvents([]);
                    break;
                case 'awardNomination':
                    setAwardEvents([]);
                    break;
                case 'licensing':
                    setLicensingEvents([]);
                    break;
                case 'preservation':
                    setPreservationEvents([]);
                    break;
                case 'decision':
                    setDecisionEvents([]);
                    break;
                case 'manufacture':
                    setManufactureEvents([]);
                    break;
                default:
                    break;
            }
        }
        toggleEventType(type);
    };

    return (
        <div>
            <FormRow>
                <Heading as="h5">Publication </Heading>
                <input type="checkbox" checked={eventTypes.publication} onChange={() => handleToggleEventType('publication')} />
            </FormRow>
            {eventTypes.publication && (
                <>
                    {safeMap(publicationEvents, (event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Publication type</Heading>
                                <DropDown
                                    id={`event_publication_type_${index}`}
                                    options={publicationData}
                                    onSelect={option => handlePublicationChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose publication type"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Publication date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handlePublicationChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Publication region</Heading>
                                <DropDown
                                    id={`event_publication_region_${index}`}
                                    options={countryOptions}
                                    onSelect={option => handlePublicationChange(index, 'region', option.value)}
                                    value={event.region}
                                    placeholder={"Choose publication region"}
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removePublicationEvent(index)}
                                label={"Remove Publication Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addPublicationEvent}
                        label={"Add Publication Event"}
                    />
                </>
            )}
            <FormRow>
                <Heading as="h5">Award/Nomination</Heading>
                <input type="checkbox" checked={eventTypes.awardNomination} onChange={() => handleToggleEventType('awardNomination')} />
            </FormRow>
            {eventTypes.awardNomination && (
                <>
                    {safeMap(awardEvents, (event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Award name</Heading>
                                <StyledTextField type="text" value={event.name} onChange={e => handleAwardChange(index, 'name', e.target.value)} placeholder="Award Name" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Award date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleAwardChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Award achievement/category</Heading>
                                <StyledTextField type="text" value={event.achievement} onChange={e => handleAwardChange(index, 'achievement', e.target.value)} placeholder="Award Category" />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Award nomination only</Heading>
                                <input type="checkbox" checked={event.nomination} onChange={() => handleAwardChange(index, 'nomination', !event.nomination)} />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeAwardEvent(index)}
                                label={"Remove Award Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addAwardEvent}
                        label={"Add Award Event"}
                    />
                </>
            )}

            <FormRow>
                <Heading as="h5">Licensing</Heading>
                <input type="checkbox" checked={eventTypes.licensing} onChange={() => handleToggleEventType('licensing')} />
            </FormRow>
            {eventTypes.licensing && (
                <>
                    {safeMap(licensingEvents, (event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <StyledTextField
                                    type="text" value={event.notes} onChange={e => handleLicensingChange(index, 'notes', e.target.value)} placeholder="Licensing"
                                    id="licensing"
                                    multiline
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeLicensingEvent(index)}
                                label={"Remove Licensing Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addLicensingEvent}
                        label={"Add Licensing Event"}
                    />
                </>
            )}

            <FormRow>
                <Heading as="h5">Preservation</Heading>
                <input type="checkbox" checked={eventTypes.preservation} onChange={() => handleToggleEventType('preservation')} />
            </FormRow>
            {eventTypes.preservation && (
                <>
                    {safeMap(preservationEvents, (event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Preservation Type</Heading>
                                <DropDown
                                    id="preservation_type"
                                    options={manifestationPreservation}
                                    onSelect={option => handlePreservationChange(index, 'type', option.value)}
                                    value={event.type}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Preservation Date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handlePreservationChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Preservation Region</Heading>
                                <DropDown
                                    id={`event_preservation_region_${index}`}
                                    options={countryOptions}
                                    onSelect={option => handlePreservationChange(index, 'region', option)}
                                    value={event.region}
                                    placeholder={"Choose preservation region"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Preservation Notes</Heading>
                                <StyledTextField
                                    id="preservation_notes"
                                    onChange={e => handlePreservationChange(index, 'notes', e.target.value)}
                                    placeholder="Preservation Notes"
                                    value={event.notes}
                                    multiline
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removePreservationEvent(index)}
                                label={"Remove preservation Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addPreservationEvent}
                        label={"Add preservation Event"}
                    />
                </>
            )}
            <FormRow>
                <Heading as="h5">Decision</Heading>
                <input type="checkbox" checked={eventTypes.decision} onChange={() => handleToggleEventType('decision')} />
            </FormRow>
            {eventTypes.decision && (
                <>
                    {safeMap(decisionEvents, (event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Decision Type</Heading>
                                <DropDown
                                    id={`event_decision_type_${index}`}
                                    options={manifestationDecision}
                                    onSelect={option => handleDecisionChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose decision type"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Decision Date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleDecisionChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Decision Regional Scope</Heading>
                                <DropDown
                                    id={`event_decision_region_${index}`}
                                    options={countryOptions}
                                    onSelect={option => handleDecisionChange(index, 'scope', option)}
                                    value={event.scope}
                                    placeholder={"Choose decision region"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Certificate Number</Heading>
                                <StyledTextField
                                    id={`event_decision_certificate_${index}`}
                                    onChange={e => handleDecisionChange(index, 'certificate', e.target.value)}
                                    value={event.certificate}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Verdict</Heading>
                                <StyledTextField
                                    id={`event_decision_verdict_${index}`}
                                    onChange={e => handleDecisionChange(index, 'verdict', e.target.value)}
                                    value={event.verdict}
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeDecisionEvent(index)}
                                label={"Remove decision Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addDecisionEvent}
                        label={"Add decision Event"}
                    />
                </>
            )}
            <FormRow>
                <Heading as="h5">Manufacture</Heading>
                <input type="checkbox" checked={eventTypes.manufacture} onChange={() => handleToggleEventType('manufacture')} />
            </FormRow>
            {eventTypes.manufacture && (
                <>
                    {safeMap(manufactureEvents, (event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Manufacture Type</Heading>
                                <DropDown
                                    id={`event_manufacture_type_${index}`}
                                    options={manifestationManufacture}
                                    onSelect={option => handleManufactureChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose manufacture type"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Manufacture Date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleManufactureChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Manufacture Region</Heading>
                                <DropDown
                                    id={`event_manufacture_region_${index}`}
                                    options={countryOptions}
                                    onSelect={option => handleManufactureChange(index, 'region', option)}
                                    value={event.region}
                                    placeholder={"Choose manufacture region"}
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeManufactureEvent(index)}
                                label={"Remove manufacture Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addManufactureEvent}
                        label={"Add manufacture Event"}
                    />
                </>
            )}
        </div>
    );
};

export default ManifestationEvents;
