import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../../ui/Form";
import FormRowVertical from "../../ui/FormRowVertical";
import FormSection from "../../ui/FormSection";
import { useTranslation } from 'react-i18next';
import { recordCategories, recordTypes } from "../../data/recordData/recordCategoriesTypes";
import DropDown from "../../ui/DropDown";
import toast from "react-hot-toast";
import AddRecord from "../../ui/AddRecord";
import { Dialog } from '@mui/material';
import Button from "../../ui/Button";

function NewRecord() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [category, setCategory] = useState("");
    const [recordType, setRecordType] = useState("");
    const [showModal, setShowModal] = useState(false);

    const handleAddRecord = (e) => {
        e.preventDefault();
        if (!category || !recordType) {
            toast.error("Please select both a category and a type");
            return;
        }
        switch (recordType) {
            case 'work':
                navigate('/create-work', { state: { category } });
                break;
            case 'manifestation':
                navigate('/create-manifestation', { state: { category } });
                break;
            case 'item':
                navigate('/create-item', { state: { category } });
                break;
            default:
                break;
        }
    };

    const handleCategorySelect = (option) => {
        setCategory(option.value);
        if (option.value === 'objects') {
            setRecordType('item');
        } else {
            setRecordType('');
        }
    };

    const handleTypeSelect = (option) => {
        setRecordType(option.value);
    };

    return (
        <>

            <Button onClick={() => setShowModal(true)} size="large" >
                {t('actions.new_record')}
            </Button>
            <Dialog open={showModal} onClose={() => setShowModal(false)} aria-labelledby="form-dialog-title">
                <Form onSubmit={handleAddRecord}>
                    <FormSection>
                        <FormRowVertical>
                            <DropDown
                                id="record_category"
                                options={recordCategories}
                                onSelect={handleCategorySelect}
                                label={t("Record Category")}
                                placeholder={t("Choose category")}
                            />
                        </FormRowVertical>

                        <FormRowVertical>
                            <DropDown
                                id="record_type"
                                options={recordTypes}
                                onSelect={handleTypeSelect}
                                label={t("Record Type")}
                                disabled={category === 'objects'}
                                placeholder={t("Choose type")}
                                initialValue={category === 'objects' ? 'item' : ''}
                            />
                        </FormRowVertical>
                    </FormSection>
                    <FormRowVertical>
                        <Button size="large" type="submit">
                            {t('actions.start_record')}
                        </Button>
                    </FormRowVertical>
                </Form>
            </Dialog>
        </>
    );
}

export default NewRecord;
