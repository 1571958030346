
import RecordsLayout from "../features/records/RecordsLayout";
import Heading from "../ui/Heading";
import Row from "../ui/Row"
import { useTranslation } from 'react-i18next';
import NewRecord from "../features/records/NewRecord";
function Record() {
    const { t } = useTranslation();
    return (
        <>
            <Row type="horizontal">
                <Heading as="h1">{t('titles.records')}</Heading>
                <NewRecord />
            </Row>
            <RecordsLayout />
        </>
    );
}

export default Record;
