import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyles from "./styles/GlobalStyles";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Notification from "./pages/Notification";
import Record from "./pages/Record";
import User from "./pages/User";
import PageNotFound from "./pages/PageNotFound";
import Templates from "./pages/Templates";
import HoldingInstitutions from "./pages/HoldingInstitutions";
import AppLayout from "./ui/AppLayout";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./ui/ProtectedRoute";
import { DarkModeProvider } from "./context/DarkModeContext";
import { LanguageSwitchProvider } from "./context/LanguageSwitchContext";
import { useTranslation } from "react-i18next";
import CreateWorkForm from "./features/records/work/CreateWorkForm";
import EditWorkForm from "./features/records/work/EditWorkForm";
import EditManifestationForm from "./features/records/manifestation/EditManifestationForm";
import EditItemForm from "./features/records/item/EditItemForm";
import CreateItemForm from "./features/records/item/CreateItemForm";
import CreateUserForm from "./features/users/CreateUserForm";
import CreateManifestationForm from "./features/records/manifestation/CreateManifestationForm";
import ItemDetails from "./features/records/tables/itemTable/ItemDetails";
import WorkDetails from "./features/records/tables/workTable/WorkDetails"
import ManifestationDetails from "./features/records/tables/manifestationTable/ManifestationDetails"
import Catalogue from "./pages/Catalogue";
import { NotificationProvider } from "./context/NotificationProvider";
import { Edit } from "@mui/icons-material";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
    },
  },
});

function App() {
  const { t } = useTranslation();
  return (
    <LanguageSwitchProvider>
      <DarkModeProvider>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <GlobalStyles />
            <BrowserRouter>
              <Routes>
                <Route
                  element={
                    <ProtectedRoute >
                      <AppLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route path="dashboard" element={<ProtectedRoute rolesRequired={['general_manager', 'manager']}><Dashboard /></ProtectedRoute>} />
                  <Route path="records" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><Record /></ProtectedRoute>} />
                  <Route path="create-work" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><CreateWorkForm /></ProtectedRoute>} />
                  <Route path="edit-work/:workId" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><EditWorkForm /></ProtectedRoute>} />
                  <Route path="edit-manifestation/:manifestId" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><EditManifestationForm /></ProtectedRoute>} />
                  <Route path="edit-item/:itemId" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><EditItemForm /></ProtectedRoute>} />
                  <Route path="create-item" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><CreateItemForm /></ProtectedRoute>} />
                  <Route path="create-manifestation" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'entry_personnel']}><CreateManifestationForm /></ProtectedRoute>} />
                  <Route path="catalogue" element={<ProtectedRoute rolesRequired={['general_manager', 'manager', 'viewer']}><Catalogue /></ProtectedRoute>} />
                  <Route path="item-details/:itemId" element={<ItemDetails />} />
                  <Route path="work-details/:workId" element={<WorkDetails />} />
                  <Route path="manifestation-details/:manifestId" element={<ManifestationDetails />} />
                  <Route path="users" element={<ProtectedRoute rolesRequired={['general_manager']}><User /></ProtectedRoute>} />
                  <Route path="create-user" element={<ProtectedRoute rolesRequired={['general_manager']}><CreateUserForm /></ProtectedRoute>} />
                  <Route path="holding-institutions" element={<ProtectedRoute rolesRequired={['general_manager', 'manager']}><HoldingInstitutions /></ProtectedRoute>} />
                  <Route path="templates" element={<ProtectedRoute rolesRequired={['general_manager', 'manager']}><Templates /></ProtectedRoute>} />
                  <Route path="notifications" element={<Notification />} />

                </Route>
                <Route path="login" element={<Login />} />
                <Route path="/" element={<Navigate to="/records" replace />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>
            <Toaster
              position="top-right"
              gutter={12}
              containerStyle={{ margin: "8px" }}
              toastOptions={{
                success: { duration: 3000 },
                error: { duration: 5000 },
                style: {
                  fontSize: "16px",
                  maxWidth: "500px",
                  padding: "16px 24px",
                  backgroundColor: "var(--color-grey-0)",
                  color: "var(--color-grey-700)",
                },
              }}
            />
          </NotificationProvider>
        </QueryClientProvider>
      </DarkModeProvider>
    </LanguageSwitchProvider>
  );
}

export default App;
