import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import Heading from '../../../ui/Heading';
import MultiSelect from '../../../ui/MultiSelect';

const WorkLocalization = ({
    category,
    workCountry = [], setWorkCountry,
    workOriginalLanguage, setWorkOriginalLanguage,
    workLanguages = [], setWorkLanguages,
    countryOptions, languageOptions
}) => {
    const methods = useFormContext();
    const { register, setValue, formState: { errors }, setError, clearErrors } = methods;

    if (category === "photos_and_images") return null;

    const handleWorkOriginalLanguageSelect = (option) => {
        setWorkOriginalLanguage(option.value);
        setValue('work_original_language', option.value, { shouldValidate: true });
    };


    return (
        <>
            {category !== 'paper_based_documents' && (
                <FormRow error={errors.work_country?.message}>
                    <Heading as="h5">Country - origin</Heading>
                    <MultiSelect
                        id="work_country"
                        options={countryOptions}
                        selectedValues={workCountry}
                        setSelectedValues={setWorkCountry}
                        validationMessage="Please choose a country"
                        register={register}
                        setValue={setValue}
                        placeHolder="Choose country of reference"
                        clearErrors={clearErrors}
                        setError={setError}
                    />

                </FormRow>
            )}
            <FormRow error={errors.work_original_language?.message}>
                <Heading as="h5">Original language</Heading>
                <DropDown
                    id="work_original_language"
                    options={languageOptions}
                    onSelect={handleWorkOriginalLanguageSelect}
                    value={workOriginalLanguage}
                    {...register('work_original_language', { required: "Please enter language" })}
                />
            </FormRow>
            <FormRow>
                <Heading as="h5">Other languages</Heading>
                <MultiSelect
                    id="work_languages"
                    options={languageOptions}
                    selectedValues={workLanguages}
                    setSelectedValues={setWorkLanguages}
                    register={register}
                    setValue={setValue}
                    placeHolder="Choose other languages"
                    clearErrors={clearErrors}
                    setError={setError}
                />
            </FormRow>
        </>
    );
};

export default WorkLocalization;
