import { useState } from "react";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import Input from "../../ui/Input";
import FormRowVertical from "../../ui/FormRowVertical";
import SpinnerMini from "../../ui/SpinnerMini";
import { useLogin } from "./useLogin";
import { useTranslation } from 'react-i18next';
import TwoFactorAuthForm from "./TwoFactorAuthForm";

function LoginForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, verify2fa, isLoading, is2FAPending, is2FAVerifying } = useLogin();

  function handleLoginSubmit(e) {
    e.preventDefault();
    if (!email || !password) return;

    login(
      { user_email: email, user_pw: password },
      {
        onSettled: () => {
          setPassword("");
        },
      }
    );
  }

  return (
    <Form onSubmit={is2FAPending ? null : handleLoginSubmit}>
      {is2FAPending ? (
        <TwoFactorAuthForm
          email={email}
          verify2FA={verify2fa}
          isVerifying={is2FAVerifying}
        />
      ) : (
        <>
          <FormRowVertical label={t('login.email')}>
            <Input
              type="email"
              id="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
            />
          </FormRowVertical>
          <FormRowVertical label={t('login.password')}>
            <Input
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
            />
          </FormRowVertical>
          <FormRowVertical>
            <Button size="large" disabled={isLoading}>
              {!isLoading ? t('login.button') : <SpinnerMini />}
            </Button>
          </FormRowVertical>
        </>
      )}
    </Form>
  );
}

export default LoginForm;
