

export const itemAudioVisualsType = [
    { label: 'Pre-release', value: 'pre_release' },
    { label: 'Theatrical Distribution', value: 'theatrical_distribution' },
    { label: 'Non-Theatrical Distribution', value: 'non_theatrical_distribution' },
    { label: 'Not for release', value: 'not_for_release' },
    { label: 'Unreleased', value: 'unreleased' },
    { label: 'Home Viewing Publication', value: 'home_viewing_publication' },
    { label: 'Broadcast', value: 'broadcast' },
    { label: 'Internet', value: 'internet' },
    { label: 'Preservation/Restoration', value: 'preservation_restoration' },
    { label: 'Unknown', value: 'unknown' },
];

export const itemPhotoDocTypes = [
    { label: 'Release', value: 'release' },
    { label: 'Pre-release', value: 'pre_release' },
    { label: 'Not for release', value: 'not_for_release' },
    { label: 'Unreleased', value: 'unreleased' },
    { label: 'Internet', value: 'internet' },
    { label: 'Preservation/Restoration', value: 'preservation_restoration' },
    { label: 'Unknown', value: 'unknown' },
];

export const itemTitleTypes = [
    { label: 'Proper', value: 'Proper' },
    { label: 'Alternative', value: 'alternative' },
    { label: 'Supplied/Devised', value: 'supplied_devised' },
    { label: 'Other', value: 'other' },
];

export const itemGeneralFormat = [
    { label: 'Film', value: 'film' },
    { label: 'Video', value: 'video' },
    { label: 'Audio', value: 'audio' },
    { label: 'Optical', value: 'optical' },
    { label: 'Digital', value: 'digital' },
];

export const itemSpecificFormatMapping = {
    film: [
        { label: '35mm', value: '35mm' },
        { label: '16mm', value: '16mm' },
        { label: 'Super 16mm', value: 'super_16mm' },
        { label: '8mm', value: '8mm' },
        { label: 'Super 8mm', value: 'super_8mm' },
        { label: '9.5mm', value: '9_5mm' },
        { label: '17.5mm', value: '17_5mm' },
        { label: '70mm', value: '70mm' }
    ],
    video: [
        { label: '1-inch C Format', value: '1_inch_c_format' },
        { label: 'Digital Betacam', value: 'digital_betacam' },
        { label: 'Betacam SP', value: 'betacam_sp' },
        { label: 'HDCAM SR', value: 'hdcam_sr' },
        { label: 'D1', value: 'd1' },
        { label: 'D5', value: 'd5' },
        { label: 'DVCPRO HD', value: 'dvcpro_hd' }
    ],
    audio: [
        { label: '2” open reel', value: '2_inch_open_reel' },
        { label: '1” open reel', value: '1_inch_open_reel' },
        { label: '1/2” open reel', value: 'half_inch_open_reel' },
        { label: '1/4” open reel', value: 'quarter_inch_open_reel' },
        { label: 'audiocassette', value: 'audiocassette' },
        { label: '35mm magnetic track', value: '35mm_magnetic_track' },
        { label: '16mm magnetic track', value: '16mm_magnetic_track' },
        { label: 'Phonograph Records', value: 'phonograph_records' }
    ],
    optical: [
        { label: 'CD', value: 'cd' },
        { label: 'DVD', value: 'dvd' },
        { label: 'Blu-ray', value: 'blu_ray' },
        { label: 'Laser Disc', value: 'laser_disc' }
    ],
    digital: [
        { label: 'DPX', value: 'dpx' },
        { label: 'MOV', value: 'mov' },
        { label: 'MP4', value: 'mp4' },
        { label: 'MXF', value: 'mxf' },
        { label: 'AVI', value: 'avi' },
        { label: 'AIFF', value: 'aiff' },
        { label: 'WAV', value: 'wav' }
    ]
};

export const itemGeneralPhotoFormat = [
    { label: 'Film', value: 'film' },
    { label: 'Prints', value: 'prints' },
    { label: 'Digital', value: 'digital' },
];


export const itemPhotoSpecificFormatMapping = {
    film: [
        {
            title: 'Glass Negatives', options: [
                { label: 'Albumen Glass Negative', value: 'albumen_glass_negative' },
                { label: 'Collodion Glass Negative', value: 'collodion_glass_negative' },
                { label: 'Gelatin Glass Negative', value: 'gelatin_glass_negative' }
            ]
        },
        {
            title: 'Plastic Film Negatives', options: [
                { label: 'Chromogenic Color Negative', value: 'chromogenic_color_negative' },
                { label: 'Silver Gelatin Negative', value: 'silver_gelatin_negative' }
            ]
        },
        {
            title: 'Slide/Transparency', options: [
                { label: 'Autochrome', value: 'autochrome' },
                { label: 'Glass Slide, B&W', value: 'glass_slide_bw' }
            ]
        },
        {
            title: 'Plastic Film Slides', options: [
                { label: 'B&W Film Slide', value: 'bw_film_slide' },
                { label: 'Color Film Slide', value: 'color_film_slide' }
            ]
        },
        {
            title: 'Microform Plastic', options: [
                { label: 'Microfiche', value: 'microfiche' },
                { label: 'Microfilm', value: 'microfilm' },
                { label: 'Aperture Card', value: 'aperture_card' }
            ]
        }
    ],
    prints: [
        {
            title: 'Monochrome Prints', options: [
                { label: 'Albumen Print', value: 'albumen_print' },
                { label: 'Carbon Print', value: 'carbon_print' },
                { label: 'Cyanotype', value: 'cyanotype' },
                { label: 'Gelatin POP Print', value: 'gelatin_pop_print' },
                { label: 'Glossy Collodion Print', value: 'glossy_collodion_print' },
                { label: 'Matte Collodion Print', value: 'matte_collodion_print' },
                { label: 'Platinum/Palladium Print', value: 'platinum_palladium_print' },
                { label: 'Salt Print', value: 'salt_print' },
                { label: 'Silver Gelatin Print (DOP)', value: 'silver_gelatin_print_dop' }
            ]
        },
        {
            title: 'Color Prints', options: [
                { label: 'Chromogenic Color Print', value: 'chromogenic_color_print' },
                { label: 'Color Carbro Print', value: 'color_carbro_print' },
                { label: 'Dye Transfer Print', value: 'dye_transfer_print' },
                { label: 'Silver Dye-Bleach Print', value: 'silver_dye_bleach_print' }
            ]
        },
        {
            title: 'Slide/Transparency', options: [
                { label: 'Autochrome', value: 'autochrome' },
                { label: 'Glass Slide, B&W', value: 'glass_slide_bw' }
            ]
        },
        {
            title: 'Instant Photos', options: [
                { label: 'Instant Photo, B&W', value: 'instant_photo_bw' },
                { label: 'Instant Photo, Color', value: 'instant_photo_color' }
            ]
        },
        {
            title: 'Digital Prints', options: [
                { label: 'Inkjet Print', value: 'inkjet_print' },
                { label: 'Laser Print', value: 'laser_print' }
            ]
        },
        {
            title: 'Photomechanical Prints', options: [
                { label: 'Collotype', value: 'collotype' },
                { label: 'Gravure', value: 'gravure' },
                { label: 'Letterpress Halftone', value: 'letterpress_halftone' },
                { label: 'Offset Lithography', value: 'offset_lithography' },
                { label: 'Woodburytype', value: 'woodburytype' }
            ]
        },
        {
            title: 'Microform', options: [
                { label: 'Microcard', value: 'microcard' },
                { label: 'Microprint', value: 'microprint' }
            ]
        }
    ],
    digital: [
        {
            title: 'File Formats', options: [
                { label: 'RAW', value: 'raw' },
                { label: 'TIFF', value: 'tiff' },
                { label: 'PSD', value: 'psd' },
                { label: 'DNG', value: 'dng' },
                { label: 'JPG', value: 'jpg' },
                { label: 'GIF', value: 'gif' },
                { label: 'TAG', value: 'tag' },
                { label: 'BMP', value: 'bmp' }
            ]
        }
    ]
};

export const itemGeneralDocFormat = [
    { label: 'Unbound Paper', value: 'unbound_paper' },
    { label: 'Bound / Book', value: 'bound' },
    { label: 'Digital', value: 'digital' },
];


export const itemDocSpecificFormatMapping = {
    unbound_paper: [
        {
            title: 'Paper',
            options: [
                { label: 'Acid-Free Paper', value: 'acid_free_paper' },
                { label: 'Coated Paper', value: 'coated_paper' },
                { label: 'Newsprint', value: 'newsprint' },
                { label: 'Proprietary Paper', value: 'proprietary_paper' },
                { label: 'Tracing/Transparent Paper', value: 'tracing_transparent_paper' }
            ]
        },
        {
            title: 'Architectural Drawing Reproduction',
            options: [
                { label: 'Aniline Print', value: 'aniline_print' },
                { label: 'Blueprint', value: 'blueprint' },
                { label: 'Diazo Print', value: 'diazo_print' },
                { label: 'Electrostatic Print', value: 'electrostatic_print' },
                { label: 'Ferro-Gallic Print', value: 'ferro_gallic_print' },
                { label: 'Gel-Lithograph', value: 'gel_lithograph' },
                { label: 'Hectograph', value: 'hectograph' },
                { label: 'Photostat', value: 'photostat' },
                { label: 'VanDyke Print', value: 'vandyke_print' },
                { label: 'Wash-Off Print', value: 'wash_off_print' }
            ]
        },
        {
            title: 'Ink & Other Media',
            options: [
                { label: 'Ball-Point Pen', value: 'ball_point_pen' },
                { label: 'Carbon Black Ink', value: 'carbon_black_ink' },
                { label: 'Colored Pencils', value: 'colored_pencils' },
                { label: 'Copying Pencil', value: 'copying_pencil' },
                { label: 'Dye-Based Ink', value: 'dye_based_ink' },
                { label: 'Felt-Tip Pen / Marker', value: 'felt_tip_pen_marker' },
                { label: 'Graphite', value: 'graphite' },
                { label: 'Iron Gall Ink', value: 'iron_gall_ink' },
                { label: 'Pigment-Based Ink', value: 'pigment_based_ink' },
                { label: 'Typewriter Ribbon Ink', value: 'typewriter_ribbon_ink' },
                { label: 'Wax-Based Pencil / Crayon', value: 'wax_based_pencil_crayon' }
            ]
        },
        {
            title: 'Office Copy/Print',
            options: [
                { label: 'Carbon Copy', value: 'carbon_copy' },
                { label: 'Carbonless Copy (NCR)', value: 'carbonless_copy_ncr' },
                { label: 'Color Photocopy', value: 'color_photocopy' },
                { label: 'Copybook / Roller Copy', value: 'copybook_roller_copy' },
                { label: 'Diazo Print', value: 'diazo_print_office' },
                { label: 'Diffusion Transfer', value: 'diffusion_transfer' },
                { label: 'Dual Spectrum', value: 'dual_spectrum' },
                { label: 'Electrofax', value: 'electrofax' },
                { label: 'Electrostatic / Xerox Copy', value: 'electrostatic_xerox_copy' },
                { label: 'Hectograph', value: 'hectograph_office' },
                { label: 'Impact Print', value: 'impact_print' },
                { label: 'Inkjet Print', value: 'inkjet_print' },
                { label: 'Laser Print', value: 'laser_print' },
                { label: 'Lithograph (Office Litho)', value: 'lithograph_office_litho' },
                { label: 'Photostat', value: 'photostat_office' },
                { label: 'Spirit Duplicate', value: 'spirit_duplicate' },
                { label: 'Stencil Copy', value: 'stencil_copy' },
                { label: 'Thermal Print / Thermofax', value: 'thermal_print_thermofax' },
                { label: 'Typography', value: 'typography' }
            ]
        }
    ],
    bound: [
        {
            title: 'Paper',
            options: [
                { label: 'Acid-Free Paper', value: 'acid_free_paper_bound' },
                { label: 'Coated Paper', value: 'coated_paper_bound' },
                { label: 'Newsprint', value: 'newsprint_bound' },
                { label: 'Proprietary Paper', value: 'proprietary_paper_bound' },
                { label: 'Tracing/Transparent Paper', value: 'tracing_transparent_paper_bound' }
            ]
        },
        {
            title: 'Board Materials',
            options: [
                { label: 'Paper Board', value: 'paper_board' },
                { label: 'Wood Board', value: 'wood_board' },
            ]
        },
        {
            title: 'Cover & Binding Materials',
            options: [
                { label: 'Cloth (starch-filled, pyroxylin-treated, acrylic-coated)', value: 'cloth' },
                { label: 'Leather (sheep, goat, calf)', value: 'leather' },
                { label: 'Paper', value: 'paper_cover_binding' },
                { label: 'Parchment (vellum, etc.)', value: 'parchment' },
                { label: 'Tawed Skin', value: 'tawed_skin' }
            ]
        },
    ],
    digital: [
        {
            title: 'File Formats',
            options: [
                { label: 'TIFF', value: 'tiff' },
                { label: 'PDF', value: 'pdf' },
                { label: 'JPEG', value: 'jpeg' },
                { label: 'TXT', value: 'txt' },
                { label: 'EPUB', value: 'epub' },
                { label: 'MOBI', value: 'mobi' },
                { label: 'DOC/DOCX', value: 'doc_docx' },
                { label: 'HTML', value: 'html' },
                { label: 'XML', value: 'xml' }
            ]
        }
    ]
};


export const color = [
    { label: 'Color', value: 'color' },
    { label: 'Color + Black-and-white', value: 'color_black_and_white' },
    { label: 'Black-and-white', value: 'black_and_white' },
    { label: 'Black-and-white (tinted)', value: 'black_and_white_tinted' },
    { label: 'Black-and-white (toned)', value: 'black_and_white_toned' },
    { label: 'Black-and-white (tinted and toned)', value: 'black_and_white_tinted_and_toned' },
    { label: 'Sepia', value: 'sepia' }
];

export const colorSystems = [
    { label: 'Pathécolor', value: 'pathecolor' },
    { label: 'Technicolor', value: 'technicolor' },
    { label: 'Kinemacolor', value: 'kinemacolor' },
    { label: 'Anscocolor', value: 'anscocolor' },
    { label: 'Ferranjacolor', value: 'ferranjacolor' },
    { label: 'Fujicolor', value: 'fujicolor' },
    { label: 'Kodachrome', value: 'kodachrome' },
    { label: 'Eastmancolor', value: 'eastmancolor' },
    { label: 'RGB', value: 'rgb' },
    { label: 'YUV', value: 'yuv' }
];


export const aspectRatiosAudioVisual = [
    { label: '1.33:1 (4:3)', value: '1.33:1' },
    { label: '1.78:1 (16:9)', value: '1.78:1' },
    { label: '2.34:1', value: '2.34:1' },
    { label: '2.39:1', value: '2.39:1' },
    { label: '2.52:1', value: '2.52:1' },
    { label: '2.7:1', value: '2.7:1' }
];

export const aspectRatiosDoc = [
    { label: 'A0', value: 'a0' },
    { label: 'A1', value: 'a1' },
    { label: 'A2', value: 'a2' },
    { label: 'A3', value: 'a3' },
    { label: 'A4', value: 'a4' },
    { label: 'A5', value: 'a5' },
    { label: 'A6', value: 'a6' },
    { label: 'A7', value: 'a7' },
    { label: 'A8', value: 'a8' },
    { label: 'A9', value: 'a9' },
    { label: 'A10', value: 'a10' },
    { label: 'Letter', value: 'letter' },
    { label: 'Legal', value: 'legal' },
    { label: 'Tabloid', value: 'tabloid' },
    { label: '4.25”×7”', value: '4.25x7' },
    { label: '5.5”×8.5”', value: '5.5x8.5' },
    { label: '6”×9”', value: '6x9' },
    { label: '7”x10”', value: '7x10' },
    { label: '8.5”x11”', value: '8.5x11' },
    { label: '9”x12”', value: '9x12' },
    { label: '10”x13”', value: '10x13' }
];


export const aspectRatiosPhoto = [
    { label: '3:2', value: '3:2' },
    { label: '4:3', value: '4:3' },
    { label: '16:9', value: '16:9' },
    { label: '1:1', value: '1:1' },
    { label: '5:4', value: '5:4' },
    { label: '7:6', value: '7:6' },
    { label: '8:10', value: '8:10' }
];


export const aperture = [
    { label: 'Academy 1.33:1', value: 'academy_1_33_1' },
    { label: 'Full Height', value: 'full_height' },
    { label: 'Full Screen', value: 'full_screen' },
    { label: 'Flat', value: 'flat' },
    { label: 'Anamorphic', value: 'anamorphic' },
    { label: '3D', value: '3d' },
    { label: 'Pan and Scan', value: 'pan_and_scan' },
    { label: 'Pillarbox', value: 'pillarbox' },
    { label: 'Letterbox/Widescreen', value: 'letterbox_widescreen' },
    { label: 'Windowbox', value: 'windowbox' }
];

export const itemStockAudiovisual = [

    {
        title: 'Film',
        options: [
            { label: 'Eastman Kodak', value: 'eastman_kodak' },
            { label: 'Fuji', value: 'fuji' },
            { label: 'Agfa', value: 'agfa' },
        ]
    },

    {
        title: 'Video',
        options: [
            { label: '3M', value: '3m' },
            { label: 'Agfa', value: 'agfa' },
            { label: 'Agfa Gavaert', value: 'agfa_gavaert' },
            { label: 'Akai', value: 'akai' },
            { label: 'Ampex', value: 'ampex' },
            { label: 'Ansco', value: 'ansco' },
            { label: 'BASF', value: 'basf' },
            { label: 'Brifco', value: 'brifco' },
            { label: 'Fuji', value: 'fuji' },
            { label: 'Sony', value: 'sony' },
        ]
    },


    {
        title: 'Audio',
        options: [
            { label: '3M', value: '3m' },
            { label: 'Scotch', value: 'scotch' },
            { label: 'Shamrock', value: 'shamrock' },
            { label: 'Ampex', value: 'ampex' },
        ]
    },


    {
        title: 'Optical',
        options: [
            { label: 'Maxell', value: 'maxell' },
            { label: 'Meorex', value: 'meorex' },
            { label: 'Philips', value: 'philips' },
            { label: 'Verbatim', value: 'verbatim' },
        ]
    },


    {
        title: 'Digital Tape',
        options: [
            { label: 'Fuji', value: 'fuji' },
            { label: 'HP', value: 'hp' },
            { label: 'Oracle', value: 'oracle' },
            { label: 'Sony', value: 'sony' },
        ]
    },


    {
        title: 'Hard Drives',
        options: [
            { label: 'Hitachi', value: 'hitachi' },
            { label: 'Seagate', value: 'seagate' },
            { label: 'Toshiba', value: 'toshiba' },
            { label: 'Western Digital', value: 'western_digital' }
        ]
    },

];

export const itemStockPhoto = [

    {
        title: 'Film',
        options: [
            { label: 'Eastman Kodak', value: 'eastman_kodak' },
            { label: 'Fuji', value: 'fuji' },
            { label: 'Agfa', value: 'agfa' },
            { label: 'Ilford', value: 'ilford' },
        ]
    },

    {
        title: 'Photo Papers',
        options: [
            { label: 'Kodak', value: 'kodak' },
            { label: 'Fujifilm', value: 'fujifilm' },
            { label: 'Ilford', value: 'ilford' },
            { label: 'Epson', value: 'epson' },
            { label: 'Hahnemühle', value: 'hahnemuhle' },
        ]
    },

    {
        title: 'Hard Drives',
        options: [
            { label: 'Hitachi', value: 'hitachi' },
            { label: 'Seagate', value: 'seagate' },
            { label: 'Toshiba', value: 'toshiba' },
            { label: 'Western Digital', value: 'western_digital' }
        ]
    },
];


export const frameRates = [
    { label: '16 fps', value: '16_fps' },
    { label: '23.98 fps', value: '23.98_fps' },
    { label: '24 fps', value: '24_fps' },
    { label: '25 fps', value: '25_fps' },
    { label: '30 fps', value: '30_fps' },
    { label: '48 fps', value: '48_fps' },
    { label: 'Variable', value: 'variable' }
];


export const bitDepthRatesVideo = [
    { label: 'Less than 8 Bit', value: 'under_8_bit' },
    { label: '8 Bit', value: '8_bit' },
    { label: '10 Bit', value: '10_bit' },
    { label: '16 Bit', value: '16_bit' },
    { label: '24 Bit', value: '24_bit' },
    { label: '32 Bit', value: '32_bit' },
];

export const bitDepthRatesDoc = [
    { label: 'Less than 8 Bit', value: 'under_8_bit' },
    { label: '8 Bit', value: '8_bit' },
    { label: '16 Bit', value: '16_bit' },
    { label: '24 Bit', value: '24_bit' },
    { label: '32 Bit', value: '32_bit' },
];

export const bitDepthRatesPhoto = [
    { label: 'Less than 8 Bit', value: 'under_8_bit' },
    { label: '8 Bit', value: '8_bit' },
    { label: '16 Bit', value: '16_bit' },
    { label: '32 Bit', value: '32_bit' },
    { label: '64 Bit', value: '64_bit' },
];


export const resolutionOptionsVideo = [
    { label: 'Standard Definition (SD)', value: 'sd' },
    { label: 'High Definition (HD)', value: 'hd' },
    { label: '2K', value: '2k' },
    { label: '4K', value: '4k' },
    { label: '6K', value: '6k' },
    { label: '8K', value: '8k' },
];

export const resolutionOptionsPhoto = [
    { label: 'Less than 600 ppi', value: 'under_600_ppi' },
    { label: '600 ppi', value: '600_ppi' },
    { label: '900 ppi', value: '900_ppi' },
    { label: '2700 ppi', value: '2700_ppi' },
    { label: '5000 ppi', value: '5000_ppi' },
    { label: '5080 ppi, 5.0 μm', value: '5080_ppi' },
    { label: '800 - 1600 ppi', value: '800_1600_ppi' },
    { label: '1100 - 3000 ppi', value: '1100_3000_ppi' },
    { label: '2000 - 3000 ppi', value: '2000_3000_ppi' },
    { label: '3000 - 4000 ppi', value: '3000_4000_ppi' },
    { label: '4000 - 5000 ppi', value: '4000_5000_ppi' },
];

export const resolutionOptionsDoc = [
    { label: 'Less than 400 ppi', value: 'under_400_ppi' },
    { label: '400 ppi', value: '400_ppi' },
    { label: '600 ppi', value: '600_ppi' },
    { label: '800 ppi', value: '800_ppi' },
];

export const itemObjectElementType = {
    tools_equipment: [
        'Cameras', 'Cans', 'Editing Tables', 'Clapperboards', 'Splicers'
    ],
    costumes: [
        'Historical / Period / Cultural', 'Fantasy / Sci-fi', 'Modern / Contemporary', 'Character / Special'
    ],
    props: [
        'Hand props', 'Personal / Character Props', 'Set Props', 'Set Dressing', 'Greens', 'Mechanical Special Effects'
    ],
    memorabilia: [
        'Merchandise', 'Film Cells', 'Autographed Items', 'Souvenirs'
    ]
};

export const itemAudiovisualsElementType = [
    { label: 'Color Positive', value: 'Color Positive' },
    { label: 'Color Negative', value: 'Color Negative' },
    { label: 'Copper Toned Positive', value: 'Copper Toned Positive' },
    { label: 'Cyan Matrix', value: 'Cyan Matrix' },
    { label: 'Direct BW Positive', value: 'Direct BW Positive' },
    { label: 'Original Negative', value: 'Original Negative' },
    { label: 'Duplicate Negative', value: 'Duplicate Negative' },
    { label: 'Negative Positive', value: 'Negative Positive' },
    { label: 'Original Positive', value: 'Original Positive' },
    { label: 'Duplicate Positive', value: 'Duplicate Positive' },
    { label: 'Lavender Image negative', value: 'Lavender Image negative' },
    { label: 'Sound Negative', value: 'Sound Negative' },
    { label: 'DCP', value: 'DCP' },
    { label: 'Other/Digital', value: 'other_digital' }
];

export const itemDocElementType = [
    { label: 'Acid-Free', value: 'Acid-Free' },
    { label: 'Coated', value: 'Coated' },
    { label: 'Newsprint', value: 'Newsprint' },
    { label: 'Proprietary', value: 'Proprietary' },
    { label: 'Tracing/Transparent', value: 'Tracing/Transparent' },
    { label: 'Paper', value: 'Paper' },
    { label: 'Board', value: 'Board' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Aniline', value: 'Aniline' },
    { label: 'Blueprint', value: 'Blueprint' },
    { label: 'Diazo', value: 'Diazo' },
    { label: 'Electrostatic', value: 'Electrostatic' },
    { label: 'Ferro-Gallic', value: 'Ferro-Gallic' },
    { label: 'Gel-Lithograph', value: 'Gel-Lithograph' },
    { label: 'Hectograph', value: 'Hectograph' },
    { label: 'Photostat', value: 'Photostat' },
    { label: 'VanDyke', value: 'VanDyke' },
    { label: 'Wash-Off', value: 'Wash-Off' },
    { label: 'Ball-Point Pen', value: 'Ball-Point Pen' },
    { label: 'Carbon Black Ink', value: 'Carbon Black Ink' },
    { label: 'Colored Pencils', value: 'Colored Pencils' },
    { label: 'Copying Pencil', value: 'Copying Pencil' },
    { label: 'Dye-Based Ink', value: 'Dye-Based Ink' },
    { label: 'Felt-Tip Pen/Marker', value: 'Felt-Tip Pen/Marker' },
    { label: 'Graphite', value: 'Graphite' },
    { label: 'Iron Gall Ink', value: 'Iron Gall Ink' },
    { label: 'Pigment-Based Ink', value: 'Pigment-Based Ink' },
    { label: 'Typewriter Ribbon', value: 'Typewriter Ribbon' },
    { label: 'Wax-Based', value: 'Wax-Based' },
    { label: 'Pencil/Crayon', value: 'Pencil/Crayon' },
    { label: 'Carbon', value: 'Carbon' },
    { label: 'Carbonless (NCR)', value: 'Carbonless (NCR)' },
    { label: 'Color Photocopy', value: 'Color Photocopy' },
    { label: 'Copybook / Roller', value: 'Copybook / Roller' },
    { label: 'Diffusion Transfer', value: 'Diffusion Transfer' },
    { label: 'Dual Spectrum', value: 'Dual Spectrum' },
    { label: 'Electrofax', value: 'Electrofax' },
    { label: 'Electrostatic/Xerox', value: 'Electrostatic/Xerox' },
    { label: 'Hectograph', value: 'Hectograph' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Inkjet', value: 'Inkjet' },
    { label: 'Laser', value: 'Laser' },
    { label: 'Lithograph (Office Litho)', value: 'Lithograph (Office Litho)' },
    { label: 'Photostat', value: 'Photostat' },
    { label: 'Spirit Duplicate', value: 'Spirit Duplicate' },
    { label: 'Stencil', value: 'Stencil' },
    { label: 'Thermal / Thermofax', value: 'Thermal / Thermofax' },
    { label: 'Typography', value: 'Typography' },
    { label: 'Color Positive', value: 'Color Positive' },
    { label: 'Color Negative', value: 'Color Negative' },
    { label: 'Copper Toned Positive', value: 'Copper Toned Positive' },
    { label: 'Cyan Matrix', value: 'Cyan Matrix' },
    { label: 'Original Positive', value: 'Original Positive' },
    { label: 'Duplicate Positive', value: 'Duplicate Positive' },
    { label: 'Lavender Image negative', value: 'Lavender Image negative' },
    { label: 'TIFF', value: 'TIFF' },
    { label: 'DNG', value: 'DNG' },
    { label: 'RAW', value: 'RAW' }
];

export const itemPhotoElementType = [
    { label: 'Color Positive', value: 'Color Positive' },
    { label: 'Color Negative', value: 'Color Negative' },
    { label: 'Copper Toned Positive', value: 'Copper Toned Positive' },
    { label: 'Cyan Matrix', value: 'Cyan Matrix' },
    { label: 'Direct BW Positive', value: 'Direct BW Positive' },
    { label: 'Original Negative', value: 'Original Negative' },
    { label: 'Duplicate Negative', value: 'Duplicate Negative' },
    { label: 'Negative Positive', value: 'Negative Positive' },
    { label: 'Original Positive', value: 'Original Positive' },
    { label: 'Duplicate Positive', value: 'Duplicate Positive' },
    { label: 'Lavender Image negative', value: 'Lavender Image negative' },
    { label: 'TIFF', value: 'TIFF' },
    { label: 'DNG', value: 'DNG' },
    { label: 'RAW', value: 'RAW' }
];


export const itemBase = [
    { label: 'Acetate', value: 'acetate' },
    { label: 'Acrylic', value: 'acrylic' },
    { label: 'Diacetate', value: 'diacetate' },
    { label: 'Mainly safety', value: 'mainly_safety' },
    { label: 'Mainly nitrate', value: 'mainly_nitrate' },
    { label: 'Mixed', value: 'mixed' },
    { label: 'Mylar', value: 'mylar' },
    { label: 'Nitrate', value: 'nitrate' },
    { label: 'Polyester', value: 'polyester' },
    { label: 'PVC', value: 'pvc' },
    { label: 'Safety', value: 'safety' },
    { label: 'Video', value: 'video' },
    { label: 'Vinyl', value: 'vinyl' }
];

export const itemBasePhoto = [
    { label: 'Acetate', value: 'acetate' },
    { label: 'Acrylic', value: 'acrylic' },
    { label: 'Diacetate', value: 'diacetate' },
    { label: 'Mainly safety', value: 'mainly_safety' },
    { label: 'Mainly nitrate', value: 'mainly_nitrate' },
    { label: 'Mixed', value: 'mixed' },
    { label: 'Mylar', value: 'mylar' },
    { label: 'Nitrate', value: 'nitrate' },
    { label: 'Polyester', value: 'polyester' },
    { label: 'PVC', value: 'pvc' },
    { label: 'Safety', value: 'safety' },
    { label: 'Image/Photo', value: 'image_photo' },
    { label: 'Vinyl', value: 'vinyl' }
];


export const bitDepthRatesAudio = [
    { label: '32kbps', value: '32kbps' },
    { label: '64kbps', value: '64kbps' },
    { label: '128kbps', value: '128kbps' },
    { label: '192kbps ', value: '192kbps ' },
    { label: '256kbps', value: '256kbps' },
    { label: '320kbps', value: '320kbps' },
];


export const samplingFrequencyAudio = [
    { label: '44.1kHz', value: '44.1kHz' },
    { label: '48kHz ', value: '48kHz ' },
    { label: '96kHz ', value: '96kHz ' },
    { label: '192kHz', value: '192kHz' },
];

export const audioTypeOptions = [
    { label: 'Sound', value: 'sound' },
    { label: 'No Sound (silent)', value: 'no_sound' },
    { label: 'Mute', value: 'mute' },
    { label: 'Combined', value: 'combined' },
    { label: 'Combined as Mute', value: 'combined_as_mute' },
    { label: 'Combined as Sound', value: 'combined_as_sound' },
    { label: 'Mixed', value: 'mixed' },
    { label: 'Temporary', value: 'temporary' },
];

export const audioSystemOptions = [
    { label: 'Dolby SR', value: 'dolby_sr' },
    { label: 'Dolby Digital', value: 'dolby_digital' },
    { label: 'Mute', value: 'mute' },
    { label: 'Combined Magnetic Sound', value: 'combined_magnetic_sound' },
    { label: 'Combined Optical Sound', value: 'combined_optical_sound' },
    { label: 'VA', value: 'va' },
    { label: 'RCA Duplex', value: 'rca_duplex' },
];

export const audioChannel = [
    { label: 'Monophonic', value: 'monophonic' },
    { label: 'Stereo', value: 'stereo' },
];

export const unitTypesAudioVisuals = [
    { value: 'cassette', label: 'Cassette' },
    { value: 'cartridge', label: 'Cartridge' },
    { value: 'reel', label: 'Reel' },
    { value: 'loop', label: 'Loop' },
    { value: 'disc', label: 'Disc' },
    { value: 'file', label: 'File' },
    { value: 'digital', label: 'Digital' },
    { value: 'tape', label: 'Tape' }
];

export const physicalUnitTypesAudioVisuals = [
    { value: 'feet', label: 'Feet' },
    { value: 'meters', label: 'Meters' },
    { value: 'mb', label: 'MB' },
    { value: 'gb', label: 'GB' },
    { value: 'tb', label: 'TB' }
];

export const unitTypesPhotos = [
    { value: 'negative', label: 'Negative' },
    { value: 'slide', label: 'Slide' },
    { value: 'positive', label: 'Positive' },
    { value: 'print', label: 'Print' },
    { value: 'file', label: 'File' },
];

export const physicalUnitTypesPhotos = [
    { value: 'mm_cm_inc', label: 'mm, cm or inch' },
    { value: 'mb', label: 'MB' },
    { value: 'gb', label: 'GB' },
];

export const unitTypesDoc = [
    { value: 'pages', label: 'Pages' },
    { value: 'sheets', label: 'Sheets' },
    { value: 'folios', label: 'Folios' },
    { value: 'volumes', label: 'Volumes' },
    { value: 'boxes', label: 'Boxes' },
    { value: 'folders', label: 'Folders' },
    { value: 'files', label: 'Files' },
];

export const physicalUnitTypesDoc = [
    { value: 'mm_cm_inc', label: 'mm, cm or inch' },
    { value: 'mb', label: 'MB' },
    { value: 'gb', label: 'GB' },
];

export const physicalUnitTypesObj = [
    { value: 'cm', label: 'cm' },
    { value: 'mm', label: 'mm' },
    { value: 'm', label: 'm' },
];

export const usageType = [
    { label: 'Dialogue Language(s)', value: 'dialogue_languages' },
    { label: 'Spoken', value: 'spoken' },
    { label: 'Sung', value: 'sung' },
    { label: 'Signed', value: 'signed' },
    { label: 'No Dialogue', value: 'no_dialogue' },
    { label: 'Written Language(s)', value: 'written_languages' },
    { label: 'Subtitle', value: 'subtitle' },
    { label: 'Captions', value: 'captions' },
    { label: 'Intertitles', value: 'intertitles' },
    { label: 'Containers summaries', value: 'containers_summaries' },
];

export const itemInspectionTypes = [
    { label: 'Visual Inspection', value: 'visual_inspection' },
    { label: 'Technical Inspection', value: 'technical_inspection' },
    { label: 'Chemical Inspection', value: 'chemical_inspection' },
];

export const itemPreservationTypes = [
    { label: 'Conservation Treatment', value: 'conservation_treatment' },
    { label: 'Preventive Conservation', value: 'preventive_conservation' },
];

export const itemAcquisitionTypes = [
    { label: 'Donation', value: 'donation' },
    { label: 'Purchase', value: 'purchase' },
    { label: 'Transfer', value: 'transfer' },
];

export const itemAudioVisualsCredits = [
    { label: 'Licensee', value: 'licensee' },
    { label: 'Preservation', value: 'preservation' },
    { label: 'Technician', value: 'technician' },
    { label: 'Inspector', value: 'inspector' },
    { label: 'Archivists', value: 'srchivists' },
];

export const itemPhotoDocCredits = [
    { label: 'Publisher', value: 'publisher' },
    { label: 'Manufacturer', value: 'manufacturer' },
    { label: 'Agent Responsible for Preservation', value: 'agent_responsible_for_preservation' },
    { label: 'Agent Responsible for Reproduction or Transfer', value: 'agent_responsible_for_reproduction_or_transfer' },
    { label: 'Agent Responsible for Archival Availability', value: 'agent_responsible_for_archival_availability' },
    { label: 'Agent Responsible for the Mere Availability', value: 'agent_responsible_for_the_mere_availability' },
    { label: 'Agent Unclear or Undetermined', value: 'agent_unclear_or_undetermined' },
    { label: 'Agent Not Identified', value: 'agent_not_identified' }
];

export const itemObjectsCredits = [
    { label: 'Author', value: 'author' },
    { label: 'Artist', value: 'artist' },
    { label: 'Manufacturer', value: 'manufacturer' },
    { label: 'Licensee', value: 'licensee' },
    { label: 'Owner', value: 'owner' },
    { label: 'Producer', value: 'producer' },
    { label: 'Inventor', value: 'inventor' },
    { label: 'Contributor', value: 'contributor' }
];

export const itemAccessCondition = {
    Base_Emulsion: [
        'Brittle', 'Buckled', 'Light Scratches', 'Heavy Scratches',
        'Tears', 'Warped', 'Hydrolysis'
    ],
    Perforations: [
        'Foil Patches', 'Torn', 'Pulled', 'Missing'
    ],
    Deposit: [
        'Mold', 'Rust', 'Oil deposits', 'Dirt', 'Drying Marks'
    ],
    Image: [
        'Discoloration', 'Magenta Bias', 'Faded', 'Print through in mold', 'Drop-outs'
    ],
    Decomposition: [
        'Powder', 'Sticky', 'Sticky at head'
    ],
    Shrinkage: [
        '1-1.5%', '1.5-2%', '2-2.5%'
    ]
};

export const itemAccessConditionObjects = {
    Physical_Structural: [
        'Brittle', 'Buckled', 'Light Scratches', 'Heavy Scratches',
        'Tears', 'Warped', 'Hydrolysis'
    ],
    Perforations: [
        'Torn', 'Pulled', 'Missing'
    ],
    Deposit: [
        'Mold', 'Rust', 'Oil deposits', 'Dirt', 'Drying Marks'
    ],
    Image: [
        'Discoloration', 'Faded'
    ],
    Decomposition: [
        'Powder', 'Sticky', 'Sticky at head'
    ],
};

export const itemAccessStatus = [
    { label: 'Master', value: 'master' },
    { label: 'Viewing', value: 'viewing' },
    { label: 'Accessioned', value: 'accessioned' },
    { label: 'On Loan', value: 'on_loan' },
    { label: 'Status pending', value: 'status_pending' },
    { label: 'Removed', value: 'removed' },

];
export const itemAccessStatusObject = [
    { label: 'Acquired / Accessioned', value: 'acquired_accessioned' },
    { label: 'On Loan (Out)', value: 'on_loan_out' },
    { label: 'On Loan (In)', value: 'on_loan_in' },
    { label: 'Prospect', value: 'prospect' },
    { label: 'Status pending', value: 'status_pending' },
    { label: 'Removed', value: 'removed' },
    { label: 'Physcial viewing', value: 'physcial_viewing' },
    { label: 'Digital viewing', value: 'digital_viewing' },
];



