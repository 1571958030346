import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import Heading from '../../../ui/Heading';
import Form from "../../../ui/Form";
import FormGroup from '../../../ui/FormGroup';
import Button from "../../../ui/Button";
import "react-datepicker/dist/react-datepicker.css";
import ItemTitles from './ItemTitles';
import ItemGeneralFields from './ItemGeneralFields';
import { useUser } from '../../authentication/useUser';
import ItemLocation from './ItemLocation';
import ItemFormat from './ItemFormat';
import ItemAgents from './ItemAgents';
import ItemExtent from './ItemExtent';
import ItemEvents from './ItemEvents';
import ItemMeasurements from './ItemMeasurements';
import ItemAccessCondition from './ItemAccessCondition';
import ItemAccessStatus from './ItemAccessStatus';
import { getCountryOptions } from '../../../utils/countries';
import { createItem } from '../../../services/apiItem';
import CustomModal from '../../../ui/CustomModal';
import PartOfItem from './PartOfItem';
import FormattedCategory from '../../../utils/formattedCategory';
import Warning from '../../../ui/Warning';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';

function CreateItemForm() {
    const methods = useForm({
        mode: 'onBlur',
    });
    const location = useLocation();
    const [agents, setAgents] = useState([{ id: uuidv4(), agent_type: '', agent_description: '' }]);
    const { user } = useUser();
    const userId = user.user.user_id;
    const [selectedManifestId, setSelectedManifestId] = useState({ manifestId: null, workId: null });
    const navigate = useNavigate();
    const [eventTypes, setEventTypes] = useState({
        inspection: false,
        preservation: false,
        acquisition: false
    });
    const [inspectionEvents, setInspectionEvents] = useState([]);
    const [licensingEvents, setLicensingEvents] = useState([]);
    const [preservationEvents, setPreservationEvents] = useState([]);
    const [acquisitionEvents, setAcquisitionEvents] = useState([]);

    const countryOptions = getCountryOptions();
    const [inspectionType, setInspectionType] = useState("");
    const [inspectionDate, setInspectionDate] = useState(new Date());
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [licensing, setLicensing] = useState("");
    const [preservationType, setPreservationType] = useState("");
    const [preservationDate, setPreservationDate] = useState(new Date());
    const [preservationNotes, setPreservationNotes] = useState("");
    const [acquisitionType, setAcquisitionType] = useState("");
    const [acquisitionSource, setAcquisitionSource] = useState("");
    const [acquisitionDate, setAcquisitionDate] = useState(new Date());
    const [acquisitionDetails, setAcquisitionDetails] = useState("");
    const { manifestationId, workId, category, manifest_title_proper, sharedFields } = location.state || {};
    const [manifestTitle, setManifestTitle] = useState(manifest_title_proper || "");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkedConditions, setCheckedConditions] = useState({
        Base_Emulsion: [],
        Physical_Structural: [],
        Perforations: [],
        Deposit: [],
        Image: [],
        Decomposition: [],
        Shrinkage: ''
    });
    const [warning, setWarning] = useState({ visible: false, message: '', color: '' });

    const {
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        watch
    } = methods;

    const accessStatus = watch("item_accessstatus");
    const formatResolution = watch("item_format_resolution");
    const formatDepth = watch("item_format_depth");
    const formatAudioRate = watch("item_format_audioRate");
    const formatFrequency = watch("item_format_audioFrequency");
    const itemGeneralFormat = watch("item_format_general");
    const itemSpecificFormat = watch("item_format_specific");


    const queryClient = useQueryClient();
    const { mutate, isLoading: isCreating } = useMutation({
        mutationFn: (itemData) => createItem({
            ...itemData, manifest_id: manifestationId ? manifestationId : selectedManifestId.manifestId,
            work_id: workId ? workId : selectedManifestId.workId
        }),
        onSuccess: () => {
            toast.success("New Item successfully created", category);
            queryClient.invalidateQueries({ queryKey: ["items"] });
            reset();
            navigate(-1);
        },
        onError: (err) => {
            const errorMessage = err.response?.data?.message || "An error occurred. Please try again.";
            toast.error(errorMessage);
        },
    });

    const handleSaveDraft = () => {
        const formData = {
            ...methods.getValues(),
            agents,
            selectedManifestId,
            eventTypes,
            inspectionType,
            inspectionDate,
            inspectionDetails,
            licensing,
            preservationType,
            preservationDate,
            preservationNotes,
            acquisitionType,
            acquisitionSource,
            acquisitionDate,
            acquisitionDetails
        };

        const serializedData = JSON.stringify(formData);
        localStorage.setItem('itemDraft', serializedData);
        toast.success("Draft saved successfully!");
    };

    const loadDraft = () => {
        const storedData = localStorage.getItem('itemDraft');
        if (storedData) {
            const draftData = JSON.parse(storedData);
            methods.reset(draftData);
            setAgents(draftData.agents);
            setSelectedManifestId(draftData.selectedManifestId);
            setEventTypes(draftData.eventTypes);
            setInspectionType(draftData.inspectionType);
            setInspectionDate(new Date(draftData.inspectionDate));
            setInspectionDetails(draftData.inspectionDetails);
            setLicensing(draftData.licensing);
            setPreservationType(draftData.preservationType);
            setPreservationDate(new Date(draftData.preservationDate));
            setPreservationNotes(draftData.preservationNotes);
            setAcquisitionType(draftData.acquisitionType);
            setAcquisitionSource(draftData.acquisitionSource);
            setAcquisitionDate(new Date(draftData.acquisitionDate));
            setAcquisitionDetails(draftData.acquisitionDetails);
        }
    };

    useEffect(() => {
        loadDraft();
        if (sharedFields) {
            setValue('item_format_general', sharedFields.general);
            setValue('item_format_specific', sharedFields.specific);
            setValue('item_format_color', sharedFields.color);
            setValue('item_format_colorstandard', sharedFields.colorstandard);
            setValue('item_format_aspect', sharedFields.aspect);
            setValue('item_format_aperture', sharedFields.aperture);
            setValue('item_format_stock', sharedFields.stock);
            setValue('item_format_framerate', sharedFields.framerate);
            setValue('item_format_resolution', sharedFields.resolution);
            setValue('item_format_depth', sharedFields.depth);
            setValue('item_format_audiofrequency', sharedFields.audiofrequency);
            setValue('item_format_audiorate', sharedFields.audiorate);
            setValue('item_format_audio', sharedFields.audio);
            setValue('item_format_audioformat', sharedFields.audioformat);
            setValue('item_format_audiosystem', sharedFields.audiosystem);
            setValue('item_format_audiochannel', sharedFields.audiochannel);
        }
    }, [sharedFields]);

    const handleConfirmSubmission = () => {
        setIsModalOpen(false);

        const data = methods.getValues();

        const itemData = {
            ...data,
            category,
            user_id: userId || "",
            part_of_manifest_id: manifestationId ? manifestationId : selectedManifestId.manifestId,
            part_of_work_id: workId ? workId : selectedManifestId.workId,
            agents: agents.map(agent => ({
                type: agent.type,
                description: agent.description,
            })),
            events: {
                licensing: licensingEvents,
                preservation: preservationEvents,
                inspection: inspectionEvents,
                acquisition: acquisitionEvents
            },

            item_accesscondition: checkedConditions,
            item_status: warning.color
        };
        try {
            JSON.stringify(itemData);
        } catch (error) {
            return;
        }

        mutate(itemData);
    };


    useEffect(() => {
        let message = "";
        let isVisible = false;

        if (category == 'audiovisuals') {
            if (accessStatus === "master" && (formatResolution === "hd" || formatResolution === "sd")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });

                return;
            }

            if (accessStatus === "master" && (formatAudioRate === "32kbps" || formatAudioRate === "64kbps")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "viewing" && formatResolution === "sd") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Access file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }


            if (accessStatus === "master" && (formatDepth === "8_bit" || formatDepth === "under_8_bit")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });

                return;
            }

            if (accessStatus === "viewing" && (formatDepth === "under_8_bit")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Access file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "master" && formatDepth === "10_bit") {
                setWarning({
                    visible: false,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'green'
                });
                return;
            }


            if (accessStatus === "master" && (formatFrequency === "44.1kHz" || formatFrequency === "48kHz")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "viewing" && formatFrequency === "44.1kHz") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Access file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "master" && (itemGeneralFormat == "digital" && itemSpecificFormat !== "dpx")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'green'
                });
                return;
            }
        }

        if (category == 'photos_and_images') {

            if (accessStatus === "master" && formatResolution === "under_600_ppi") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "master" && (formatDepth === "8_bit" || formatDepth === "under_8_bit")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "viewing" && formatDepth === "under_8_bit") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Access file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }
            if (accessStatus === "master" && (itemGeneralFormat == "digital" && itemSpecificFormat !== "tiff")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }


        }

        if (category == 'paper_based_documents') {

            if (accessStatus === "master" && formatResolution === "under_400_ppi") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }


            if (accessStatus === "master" && formatDepth === "under_8_bit") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "master" && (itemGeneralFormat == "digital" && itemSpecificFormat !== "tiff")) {
                setWarning({
                    visible: true,
                    message: "The file is not up to Master file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }

            if (accessStatus === "viewing" && formatDepth === "under_8_bit") {
                setWarning({
                    visible: true,
                    message: "The file is not up to Access file specifications standards. Would you like to continue commit registry?",
                    color: 'yellow'
                });
                return;
            }
        }

        setWarning({
            visible: isVisible,
            message: message,
            color: isVisible ? 'yellow' : 'green'
        });

    }, [selectedManifestId, accessStatus, formatResolution, formatDepth, formatAudioRate, formatFrequency, itemGeneralFormat, itemSpecificFormat, category]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        if (!manifestationId && !selectedManifestId.manifestId) {
            setWarning({
                visible: true,
                message: "The Item is required to be connected to a proper Manifestation. Would you like to continue commit registry?",
                color: 'yellow'
            });
            return setTimeout(() => setIsModalOpen(true), 1500);
        }

        setIsModalOpen(true);
    };


    const onError = () => {
        setWarning({
            visible: true,
            message: "A mandatory field must be filled",
            color: 'red'
        });
    };

    const toggleEventType = (type) => {
        setEventTypes(prev => ({ ...prev, [type]: !prev[type] }));
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <FormProvider {...methods}>
            <Heading as="h3">Item Form, starting new <FormattedCategory category={category} /></Heading>
            <Form type="tertiary" onSubmit={handleSubmit(onSubmit, onError)}>
                <FormGroup title="General Information">
                    <ItemGeneralFields category={category} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemTitles category={category} isCreating={isCreating} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemLocation errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemFormat category={category} errors={errors} sharedFields={sharedFields} />
                </FormGroup>
                {(category === 'objects') && (
                    <FormGroup>
                        <ItemMeasurements category={category} isCreating={isCreating} errors={errors} />
                    </FormGroup>
                )}
                {(category !== 'objects') && (
                    <FormGroup>
                        <ItemExtent category={category} isCreating={isCreating} errors={errors} />
                    </FormGroup>
                )}
                <FormGroup>
                    <ItemAccessCondition category={category} errors={errors} checkedConditions={checkedConditions} setCheckedConditions={setCheckedConditions} />
                </FormGroup>
                <FormGroup>
                    <ItemAccessStatus category={category} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ItemEvents
                        eventTypes={eventTypes}
                        toggleEventType={toggleEventType}
                        inspectionEvents={inspectionEvents}
                        setInspectionEvents={setInspectionEvents}
                        licensingEvents={licensingEvents}
                        setLicensingEvents={setLicensingEvents}
                        preservationEvents={preservationEvents}
                        setPreservationEvents={setPreservationEvents}
                        acquisitionEvents={acquisitionEvents}
                        setAcquisitionEvents={setAcquisitionEvents}
                        countryOptions={countryOptions}
                        errors={errors}
                    />
                </FormGroup>
                <FormGroup>
                    <ItemAgents
                        category={category}
                        agents={agents}
                        setAgents={setAgents}
                        errors={errors}
                    />
                </FormGroup>
                <FormGroup>
                    {manifestationId ? (
                        <Heading as={'h6'}>Item is a part of manifestation: {manifestationId} {manifestTitle}</Heading>
                    ) : (
                        <PartOfItem
                            selectedManifestId={selectedManifestId}
                            setSelectedManifestId={setSelectedManifestId}
                            category={category}
                            errors={errors}
                        />
                    )}
                </FormGroup>

                <Button variation="secondary" type="reset" onClick={() => { localStorage.removeItem('itemDraft'); }}>
                    Reset
                </Button>
                <Button variation="secondary" type="button" onClick={handleSaveDraft}>
                    Save
                </Button>
                <Button variation="primary" type="submit" disabled={isCreating}>
                    Record
                </Button>
                {warning.visible && (
                    <Warning message={warning.message} color={warning.color} />
                )}
                {isCreating && <SpinnerOverlay />}
                <CustomModal
                    isOpen={isModalOpen}
                    confirmationMessage={"Are you sure you want to submit the item?"}
                    onRequestClose={handleCloseModal}
                    onConfirm={handleConfirmSubmission}
                    message="Are you sure you want to submit the record?"
                />
            </Form>
        </FormProvider>
    );
}

export default CreateItemForm;
