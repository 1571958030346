import React, { useState, useEffect } from 'react';
import { fetchUsers, updateUserRole, deleteUser } from '../../services/apiUser';
import Button from '../../ui/Button';
import toast from 'react-hot-toast';
import { TablePagination } from '@mui/material';
import StyledFilterField from '../../ui/StyledFilterField';
import Table from '../../ui/Table';
import Heading from '../../ui/Heading';
import { MdDelete } from "react-icons/md";
import CustomModal from '../../ui/CustomModal';

function UserTable() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const fetchedUsers = await fetchUsers();
                setUsers(fetchedUsers);
                setFilteredUsers(fetchedUsers);
            } catch (error) {
                toast.error('Failed to load users.');
            }
        };
        loadUsers();
    }, []);

    const handleRoleChange = async (userId, newRole) => {
        try {
            const updatedUser = await updateUserRole(userId, { role: newRole });
            const updatedUsers = users.map(user =>
                user.user_id === userId ? { ...user, user_role: newRole } : user
            );
            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers);
            toast.success('Role updated successfully!');
        } catch (error) {
            toast.error('Failed to update role.');
        }
    };

    const handleDeleteUser = async () => {
        try {
            await deleteUser(selectedUser.user_id);
            const updatedUsers = users.filter(user => user.user_id !== selectedUser.user_id);
            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers);
            toast.success('User deleted successfully!');
            setIsModalOpen(false);
        } catch (error) {
            toast.error('Failed to delete user.');
        }
    };

    const handleOpenModal = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value.toLowerCase();
        setFilterText(value);
        const filtered = users.filter(user =>
            user.user_email.toLowerCase().includes(value)
        );
        setFilteredUsers(filtered);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderUserRow = (user) => {
        return (
            <React.Fragment key={user.user_id}>
                <Table.Row>
                    <div>{user.user_id}</div>
                    <div>{user.user_fname}</div>
                    <div>{user.user_mname}</div>
                    <div>{user.user_lname}</div>
                    <div>{user.user_email}</div>
                    <div>
                        <select
                            value={user.user_role}
                            onChange={(e) => handleRoleChange(user.user_id, e.target.value)}
                            style={{ width: '100%' }}
                        >
                            <option value="general_manager">General Manager</option>
                            <option value="manager">Manager</option>
                            <option value="entry_personnel">Entry Personnel</option>
                            <option value="viewer">Viewer</option>
                        </select>
                    </div>
                    <div>
                        <Button
                            variant="primary"
                            onClick={() => handleRoleChange(user.user_id, user.user_role)}
                            size="small"
                        >
                            Save Changes
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => handleOpenModal(user)}
                            size="small"
                            style={{ marginLeft: '10px' }}
                        >
                            <MdDelete />
                        </Button>
                    </div>
                </Table.Row>
            </React.Fragment>
        );
    };

    return (
        <div style={{ width: '100%', marginTop: '1rem' }}>
            <StyledFilterField
                label={<Heading as="h6">Search by email</Heading>}
                variant="outlined"
                value={filterText}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                style={{ marginBottom: '1rem' }}
            />
            <Table columns="repeat(7, 1fr)">
                <Table.Header>
                    <Heading as="h7">User ID</Heading>
                    <Heading as="h7">First Name</Heading>
                    <Heading as="h7">Middle Name</Heading>
                    <Heading as="h7">Last Name</Heading>
                    <Heading as="h7">Email</Heading>
                    <Heading as="h7">Role</Heading>
                    <Heading as="h7">Actions</Heading>
                </Table.Header>
                <Table.Body data={filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} render={renderUserRow} />
            </Table>
            <Table.Footer>
                <TablePagination
                    component="div"
                    count={filteredUsers.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Table.Footer>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                onConfirm={handleDeleteUser}
                confirmationMessage={
                    <>
                        Are you sure you want to delete user {selectedUser?.user_fname} {selectedUser?.user_lname}?
                        <br />
                        <span style={{ color: 'var(--color-red-800)', fontWeight: 'bold' }}>This action cannot be undone</span>
                    </>
                }
                messageClassName="warning-message"
            />
        </div>
    );
}

export default UserTable;
