import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Table from "../../ui/Table";
import Menus from "../../ui/Menus";
import Heading from "../../ui/Heading";
import { fetchTopUsers } from '../../services/apiUser';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const TopUsersTable = () => {
  const [topUsers, setTopUsers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const loadTopUsers = async () => {
      try {
        const users = await fetchTopUsers();
        setTopUsers(users);
      } catch (error) {
        toast.error('Failed to load top users.');
      }
    };
    loadTopUsers();
  }, []);

  return (
    <Menus>
      <Heading as="h3">{t('dashboard.top_user_chart')}</Heading>
      <Table columns="repeat(5, 1fr)">
        <Table.Header>
          <div>User ID</div>
          <div>User Name</div>
          <div>Work Count</div>
          <div>Manifestation Count</div>
          <div>Item Count</div>
        </Table.Header>
        <Table.Body data={topUsers} render={user => (
          <Table.Row key={user.id}>
            <div>{user.id}</div>
            <div>{user.userName}</div>
            <div>{user.workCount}</div>
            <div>{user.manifestationCount}</div>
            <div>{user.itemCount}</div>
          </Table.Row>
        )} />
      </Table>
    </Menus>
  );
}

export default TopUsersTable;
