import React from "react";
import HeaderButtonIcon from "./HeaderButtonIcon";
import { HiMiniLanguage } from "react-icons/hi2";
import { useLanguageSwitch } from "../context/LanguageSwitchContext";

const LanguageToggle = () => {
    const { toggleLanguage } = useLanguageSwitch();

    return (
        <div className="btn-container">
            <HeaderButtonIcon onClick={toggleLanguage}>
                <HiMiniLanguage />
            </HeaderButtonIcon>
        </div>
    );
};

export default LanguageToggle;