import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import DropDown from '../../ui/DropDown';
import FormRow from "../../ui/FormRow";
import Heading from '../../ui/Heading';
import { createUser } from '../../services/apiUser';
import Input from '../../ui/Input';
import FormGroup from '../../ui/FormGroup';
import FormRowVertical from '../../ui/FormRowVertical';
import Button from "../../ui/Button";

function CreateUserForm() {
    const methods = useForm();
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = methods;

    const { mutate, isLoading: isCreating } = useMutation({
        mutationFn: createUser,
        onSuccess: (response) => {

            toast.success('User successfully created!');
        },
        onError: (error) => {
            toast.error('Failed to create user. Please try again.');
        }
    });

    const onSubmit = async (data) => {
        mutate(data);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Heading as="h3">Create new user</Heading>

                <FormGroup>
                    <FormRowVertical>
                        <FormRow error={errors.user_fname?.message}>
                            <Input
                                id="user_fname"
                                name="user_fname"
                                type="text"
                                placeholder='First name'
                                {...register('user_fname', { required: "First name is required" })}
                            />
                        </FormRow>

                        <FormRow error={errors.user_mname?.message}>
                            <Input
                                id="user_mname"
                                name="user_mname"
                                placeholder='Middle name'
                                type="text"
                                {...register('user_mname')}
                            />
                        </FormRow>

                        <FormRow error={errors.user_lname?.message}>
                            <Input
                                id="user_lname"
                                name="user_lname"
                                placeholder='Last name'
                                type="text"
                                {...register('user_lname', { required: "Last name is required" })}
                            />
                        </FormRow>
                    </FormRowVertical>

                    <FormRowVertical>
                        <FormRow error={errors.user_email?.message}>
                            <Input
                                id="email"
                                name="user_email"
                                placeholder='Email'
                                type="email"
                                {...register('user_email', { required: "Email is required" })}
                            />
                        </FormRow>

                        <FormRow error={errors.user_pw?.message}>
                            <Input
                                id="user_pw"
                                name="user_pw"
                                type="password"
                                placeholder='Password'
                                {...register('user_pw', { required: "Password is required" })}
                            />
                        </FormRow>

                        <FormRow error={errors.user_role?.message}>
                            <DropDown
                                id="user_role"
                                name="user_role"
                                placeholder="Choose user role"
                                options={[
                                    { label: "Select a role", value: "" },
                                    { label: "General Manager", value: "general_manager" },
                                    { label: "Manager", value: "manager" },
                                    { label: "Entry Personnel", value: "entry_personnel" },
                                    { label: "Viewer", value: "viewer" },
                                ]}
                                {...register('user_role', { required: "Role is required" })}
                                onSelect={option => setValue('user_role', option.value)}
                            />
                        </FormRow>
                    </FormRowVertical>
                </FormGroup>

                <Button type="submit" disabled={isCreating}>Create User</Button>
            </form>
        </FormProvider>
    );
}

export default CreateUserForm;
