import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logout as logoutApi } from "../../services/apiAuth";
import { useNavigate } from "react-router-dom";
export function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: logout, isLoading } = useMutation(logoutApi, {
    onSuccess: () => {
      queryClient.removeQueries();
      localStorage.clear();
      navigate("/login", { replace: true });
    },
    onError: (error) => {
    }
  });

  return { logout, isLoading };
}