import styled from "styled-components";

const StyledFormRow = styled.div`
  display: grid;
  align-items: center;
  gap: 1.2rem;
  padding: 1.2rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-100);
  }

  grid-template-columns: ${({ childrenCount }) => `repeat(${childrenCount}, 1fr)`};
`;

const Label = styled.label`
  font-weight: 400;
  grid-column: span ${({ labelSpan }) => labelSpan};
`;

const Error = styled.span`
  font-size: 1.4rem;
  color: var(--color-red-700);
  grid-column: span ${({ childrenCount }) => childrenCount};
`;

function FormRow({ label, error, children }) {
  const childrenCount = Array.isArray(children) ? children.length + 1 : 2;
  const labelSpan = childrenCount - 1;

  return (
    <StyledFormRow childrenCount={childrenCount}>
      {label && <Label htmlFor={Array.isArray(children) ? children[0].props.id : children.props.id} labelSpan={labelSpan}>
        {label}
      </Label>}
      {children}
      {error && <Error childrenCount={childrenCount}>{error}</Error>}
    </StyledFormRow>
  );
}

export default FormRow;
