import countries from 'i18n-iso-countries';
import isoLanguages from 'iso-639-1';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const getCountryOptions = () => {
    return Object.entries(countries.getNames("en", { select: "official" }))
        .map(([code, name]) => ({
            label: name,
            value: code,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const getLanguageOptions = () => {
    return isoLanguages.getAllNames()
        .map(name => ({
            label: name,
            value: isoLanguages.getCode(name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const getCountryName = (countryCode) => {
    return countries.getName(countryCode, 'en') || countryCode;
};

export const convertCountryCodesToNames = (codes) => {
    if (!codes) return [];
    return Array.isArray(codes)
        ? codes.map(code => getCountryName(code))
        : [getCountryName(codes)];
};


export const getLanguageName = (languageCode) => {
    return isoLanguages.getName(languageCode) || languageCode;
};

export const convertLanguageCodesToNames = (codes) => {
    if (!codes) return [];
    return Array.isArray(codes)
        ? codes.map(code => getLanguageName(code))
        : [getLanguageName(codes)];
};
