import { HiOutlineMoon, HiOutlineSun } from "react-icons/hi2";
import HeaderButtonIcon from "./HeaderButtonIcon";
import { useDarkMode } from "../context/DarkModeContext";

function DarkModeToggle() {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  return (
    <HeaderButtonIcon onClick={toggleDarkMode}>
      {isDarkMode ? <HiOutlineSun /> : <HiOutlineMoon />}
    </HeaderButtonIcon>
  );
}

export default DarkModeToggle;
