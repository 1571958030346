
import { Box, IconButton } from '@mui/material';
import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';

const StyledTextField = styled.input`
flex-grow: 1;
border: 1px solid var(--color-grey-200);
  background-color: var(--color-grey-0);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm);
  padding: 0.8rem 1.2rem;
  color: var(--color-grey-900);
  &:hover {
    border-color: var(--color-brand-600);
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-grey-200);
    }
    &:hover fieldset {
      border-color: var(--color-brand-600);
    }
    &.Mui-focused fieldset {
      border-color: var(--color-grey-200);
    }
  }

  .MuiInputLabel-root {
    color: var(--color-grey-500); /* Default color */
  }

  .Mui-focused .MuiInputLabel-root,
  .MuiInputLabel-root.Mui-focused {
    color: var(--color-brand-600); /* Color when focused */
  }
`;

const StyledIconButton = styled(IconButton)`
  color: var(--color-grey-500);
margin-left: 1rem;
  color: var(--color-grey-500);

  &:hover {
    color: var(--color-brand-600);
  }

  &.Mui-focused {
    color: var(--color-brand-600);
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const MultiSelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const MultiSelectDisplay = styled.div`
  padding: 10px;
  border: 1px solid var(--color-grey-200);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: var(--color-grey-0);
  &:hover {
    border-color: var(--color-brand-600);
  }
  width: 100%;
`;

const MultiSelectTag = styled.span`
  background-color: #e9ecef;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
`;

const MultiSelectDropdown = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  max-height: 200px; /* Maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const MultiSelectOption = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const LabelStyle = styled.label`
  margin-left: 5px;
  color: var(--color-grey-500); /* Default color */
  &:hover {
    color: var(--color-brand-600); /* Color when hovered */
  }
`;


const StyledTextarea = styled.textarea`
  border: 1px solid var(--color-grey-200);
  background-color: var(--color-grey-0);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm);
  padding: 0.8rem 1.2rem;
  color: var(--color-grey-900);
  font-family: inherit;
  resize: none; 
  &:hover {
    border-color: var(--color-brand-600);
  }
  &:focus {
    border-color: var(--color-brand-600);
    outline: none;
  }
  &::placeholder {
    color: var(--label-color-grey-500);
  }
  overflow: hidden; 
`;



const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledSearchInput = styled.input`
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'var(--color-brand-600)' : 'white')};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid var(--color-grey-300);

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px var(--color-brand-100);
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export {
  StyledTextField,
  StyledIconButton,
  StyledBox,
  MultiSelectContainer,
  MultiSelectDisplay,
  MultiSelectTag,
  CloseButton,
  MultiSelectDropdown,
  MultiSelectOption,
  LabelStyle,
  StyledTextarea,
  Checkbox
};
