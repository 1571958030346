import React from 'react';

const FormattedCategory = ({ category }) => {
  const formatCategory = (category) => {
    switch (category) {
      case 'objects':
        return 'Object';
      case 'paper_based_documents':
        return 'Document';
      case 'audiovisuals':
        return 'Audiovisual';
      case 'photos_and_images':
        return 'Photo';
      default:

        if (category.endsWith('s')) {
          category = category.slice(0, -1);
        }

        category = category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
        return category;
    }
  };

  return <span>{formatCategory(category)}</span>;
};

export default FormattedCategory;
