import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RecordChart from "./RecordChart";
import StorageUsageChart from "./StorageUsageChart";
import TopUsersTable from "./TopUsersTable";
import UserStatsChart from './UserStatsChart';
import TypesCountChart from "./TypesCountChart";
import CountryWorkChart from "./CountryWorkChart";
const StyledDashboardLayout = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;  
grid-template-rows: auto auto auto;
gap: 1.6rem;
grid-template-areas: 
  "chart1 chart2"
  "chart3 chart4"
  "chart5 chart6";
width: 100%;
`;
const ChartBox = styled.div`
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-around;
  padding: 1.2rem 1.6rem;
  box-shadow: var(--shadow-md);
`;

function DashboardLayout() {
  return (
    <StyledDashboardLayout>
      <div style={{ gridArea: 'chart1' }}>
        <RecordChart />
      </div>
      <div style={{ gridArea: 'chart2' }}>

        <TypesCountChart />

      </div>
      <div style={{ gridArea: 'chart6' }}>

        <UserStatsChart />

      </div>
      <div style={{ gridArea: 'chart4' }}>

        <StorageUsageChart />

      </div>
      <div style={{ gridArea: 'chart5' }}>

        <TopUsersTable />

      </div>
      <div style={{ gridArea: 'chart3' }}>

        <CountryWorkChart />

      </div>
    </StyledDashboardLayout>
  );
}

export default DashboardLayout;
