import styled from "styled-components";

const StyledFormSection = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr;
  gap: 2.4rem;
  padding: 4rem 0;
`;

const Label = styled.label`
  font-weight: 400;
`;

function FormSection({ label, children }) {
  const childArray = Array.isArray(children) ? children : [children];
  const firstChildWithId = childArray.find(child => child.props && child.props.id);

  return (
    <StyledFormSection>
      {label && firstChildWithId && <Label htmlFor={firstChildWithId.props.id}>{label}</Label>}
      {children}
    </StyledFormSection>
  );
}

export default FormSection;