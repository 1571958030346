import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import Input from "../../../ui/Input";
import Heading from '../../../ui/Heading';
const ItemTitles = ({ category, errors }) => {
    const { register } = useFormContext();

    return (
        <>
            <FormRow error={errors.item_title_proper?.message}>
                <Heading as="h5">Title Proper</Heading>
                <Input
                    id="item_title_proper"
                    type="text"
                    placeholder="Title Proper"
                    {...register("item_title_proper", { required: "Please enter item title" })}
                />
            </FormRow>
            <FormRow>
                <Heading as="h5">Alternative Proper</Heading>
                <Input
                    type="text"
                    placeholder="Alternative Title"
                    {...register("item_title_alternative")}
                />
            </FormRow>
            <FormRow >
                <Heading as="h5">Supplied Proper</Heading>
                <Input
                    type="text"
                    placeholder="Supplied Title"
                    {...register("item_title_supplied")}
                />
            </FormRow>

            {category == 'photos_and_images' && (
                <FormRow>
                    <Heading as="h5">Caption Proper</Heading>
                    <Input
                        type="text"
                        placeholder="Caption Title"
                        {...register("item_title_caption")}
                    />
                </FormRow>
            )}
            <FormRow>
                <Heading as="h5">Other Proper</Heading>
                <Input
                    type="text"
                    placeholder="Other Title"
                    {...register("item_title_other")}
                />
            </FormRow>
        </>
    );
};

export default ItemTitles;
