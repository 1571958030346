import React from 'react';
import styled from "styled-components";
import Heading from './Heading';

const StyledFormGroup = styled.div`
    border: 1px solid var(--color-grey-200);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    transition: box-shadow 0.3s ease-in-out;
    background-color: var(--color-grey-0);
    border-radius: var(--border-radius-md);
`;

function FormGroup({ title, children }) {
    return (
        <StyledFormGroup>
            <Heading as="h2">{title}</Heading>
            {children}
        </StyledFormGroup>
    );
}

export default FormGroup;
