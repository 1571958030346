import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import DropDown from '../../../ui/DropDown';
import FormRow from '../../../ui/FormRow';
import Heading from '../../../ui/Heading';
import { StyledTextField } from '../../../ui/StyledComponents';
import { InputAdornment } from '@mui/material';
import { physicalUnitTypesObj } from '../../../data/itemData/itemData';

const ItemMeasurements = ({ isCreating, category }) => {
    const { register, setValue } = useFormContext();
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [lengthUnit, setLengthUnit] = useState('');
    const [widthUnit, setWidthUnit] = useState('');
    const [heightUnit, setHeightUnit] = useState('');

    const handleLengthChange = (event) => {
        setLength(event.target.value);
    };

    const handleWidthChange = (event) => {
        setWidth(event.target.value);
    };

    const handleHeightChange = (event) => {
        setHeight(event.target.value);
    };

    const handleUnitChange = (setter) => (option) => {
        setter(option.value || "");
    };

    useEffect(() => {
        const formattedMeasurements = `Length: ${length} ${lengthUnit}, Width: ${width} ${widthUnit}, Height: ${height} ${heightUnit}`;
        setValue('item_measurements', formattedMeasurements.trim());
    }, [length, width, height, lengthUnit, widthUnit, heightUnit, setValue]);

    return (
        <>
            <FormRow>
                <Heading as="h5">Length</Heading>
                <StyledTextField
                    type="number"
                    id="length"
                    onChange={handleLengthChange}
                    placeholder="Length"
                    {...register("length")}
                    disabled={isCreating}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{lengthUnit}</InputAdornment>,
                    }}
                />
                <DropDown
                    id="length_unit_type"
                    options={physicalUnitTypesObj}
                    onSelect={handleUnitChange(setLengthUnit)}
                    value={lengthUnit}
                    placeholder="Unit"
                />
            </FormRow>


            <FormRow>
                <Heading as="h5">Width</Heading>
                <StyledTextField
                    type="number"
                    id="width"
                    onChange={handleWidthChange}
                    placeholder="Width"
                    {...register("width")}
                    disabled={isCreating}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{widthUnit}</InputAdornment>,
                    }}
                />

                <DropDown
                    id="width_unit_type"
                    options={physicalUnitTypesObj}
                    onSelect={handleUnitChange(setWidthUnit)}
                    value={widthUnit}
                    placeholder="Unit"
                />
            </FormRow>

            <FormRow>
                <Heading as="h5">Height</Heading>
                <StyledTextField
                    type="number"
                    id="height"
                    onChange={handleHeightChange}
                    placeholder="Height"
                    {...register("height")}
                    disabled={isCreating}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{heightUnit}</InputAdornment>,
                    }}
                />
                <DropDown
                    id="height_unit_type"
                    options={physicalUnitTypesObj}
                    onSelect={handleUnitChange(setHeightUnit)}
                    value={heightUnit}
                    placeholder="Unit"
                />
            </FormRow>

            <FormRow>
                <Heading as="h5">Weight in KG</Heading>
                <StyledTextField
                    type="number"
                    id="weight"
                    onChange={handleHeightChange}
                    placeholder="Weight"
                    {...register("item_format_weight")}
                    disabled={isCreating}
                    fullWidth
                />
            </FormRow>

            <input type="hidden" {...register('item_measurements')} />
        </>
    );
};

export default ItemMeasurements;
