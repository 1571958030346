
import UserTable from "./UserTable";
function RecordsLayout() {

    return (

        <UserTable />
    );
}

export default RecordsLayout;
