import React from 'react';
import WorkTable from './tables/workTable/WorkTable';
import ManifestationTable from './tables/manifestationTable/ManifestationTable';
import ItemTable from './tables/itemTable/ItemTable';
import { Tabs, Tab } from '../../ui/Tabs';

function RecordTable() {

    return (
        <Tabs>
            <Tab title="titles.work">
                <WorkTable />
            </Tab>
            <Tab title="titles.manifestation">
                <ManifestationTable />
            </Tab>
            <Tab title="titles.item">
                <ItemTable />
            </Tab>
        </Tabs>
    );
}

export default RecordTable;
