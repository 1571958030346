import React, { useState, useEffect } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Sector, Tooltip } from 'recharts';
import styled from 'styled-components';
import { fetchRecordCounts } from '../../services/apiRecord';
import { useDarkMode } from '../../context/DarkModeContext';
import Heading from '../../ui/Heading';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const ChartBox = styled.div`
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-around;
  padding: 1.2rem 1.6rem;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  box-shadow: var(--shadow-md);

  & > *:first-child {
    margin-bottom: 1.2rem;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #333;
  min-width: 250px;
  width: 45%;
`;

function RecordChart() {
  const { t } = useTranslation();
  const { isDarkMode } = useDarkMode();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const counts = await fetchRecordCounts();
        const formattedData = [
          { name: "Objects", value: parseInt(counts.objects, 10), color: "#F5782B" },
          { name: "Photos and Images", value: parseInt(counts.photos, 10), color: "#6E1946" },
          { name: "Audiovisuals", value: parseInt(counts.audiovisuals, 10), color: "#91B9B4" },
          { name: "Documents and Texts", value: parseInt(counts.documents, 10), color: "#0F2837" },
        ];
        setData(formattedData);
      } catch (error) {
        setError('Failed to load record counts.');
        toast.error('Failed to load record counts.');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const total = data.reduce((acc, current) => acc + current.value, 0);

  const renderCustomizedLabel = ({ cx, cy, percent, index }) => (
    index === 0 ?
      <text x={cx} y={cy} fill={isDarkMode ? "white" : "black"} textAnchor="middle" dominantBaseline="central" fontSize="14px" fontWeight="regular">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      : null
  );

  return (
    <>
      <Heading as="h3">{t('dashboard.record_chart')}</Heading>
      <ChartBox>
        {data.map((item, index) => (
          <ChartContainer key={index}>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={[
                    { name: item.name, value: item.value, color: item.color },
                    { name: "Others", value: total - item.value, color: '#ccc' }
                  ]}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={70}
                  labelLine={false}
                  activeIndex={0}
                  activeShape={props => <Sector {...props} innerRadius={50} outerRadius={80} />}
                  label={renderCustomizedLabel}
                >
                  <Cell key={`cell-highlighted`} fill={item.color} />
                  <Cell key={`cell-others`} fill='#ccc' />
                </Pie>
                <Tooltip formatter={(value) => `${value} entries`} />
              </PieChart>
            </ResponsiveContainer>
            <Heading as="h6" color={isDarkMode ? "#fff" : "#000"}>{item.name}</Heading>
          </ChartContainer>
        ))}
      </ChartBox>
    </>
  );
}

export default RecordChart;
