import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import Input from "../../../ui/Input";
import Button from "../../../ui/Button";
import { v4 as uuidv4 } from 'uuid';
import DropDown from '../../../ui/DropDown';
import { itemAudioVisualsCredits, itemObjectsCredits } from "../../../data/itemData/itemData";
import Heading from '../../../ui/Heading';
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
const ItemAgents = ({ agents, setAgents, errors, category }) => {
    const { register, setValue, unregister, watch } = useFormContext();

    useEffect(() => {
        if (agents.length > 0) {
            agents.forEach((agent, index) => {
                setValue(`agents[${index}].id`, agent.id);
                setValue(`agents[${index}].type`, agent.type);
                setValue(`agents[${index}].description`, agent.description);
            });
        }
    }, [agents, setValue]);

    const handleAgentChange = (index, field, value) => {
        const updatedAgents = [...agents];
        updatedAgents[index][field] = value;
        setAgents(updatedAgents);
    };

    const addAgent = (event) => {
        event.preventDefault();
        setAgents([...agents, {
            id: uuidv4(),
            type: '',
            description: ''
        }]);
    };

    const removeAgent = (index, event) => {
        event.preventDefault();
        setAgents(agents.filter((_, i) => i !== index));
    };

    const getTitlesList = (category) => {
        switch (category) {
            case 'audiovisuals':
            case 'paper_based_documents':
            case 'photos_and_images':
                return itemAudioVisualsCredits;
            default:
                return itemObjectsCredits;
        }
    };

    const titlesList = getTitlesList(category);

    return (
        <>
            {agents.map((agent, index) => (
                <React.Fragment key={agent.id}>
                    <FormRow>
                        <Heading as="h5">Title</Heading>
                        <DropDown
                            id={`agent_type_${index}`}
                            options={titlesList}
                            onSelect={option => handleAgentChange(index, 'type', option.value || "")}
                            value={watch(`agents[${index}].type`) || ""}
                            {...register(`agents[${index}].type`)}
                            placeholder={"Choose Agent Title"}
                        />
                    </FormRow>
                    <FormRow>
                        <Heading as="h5">Description</Heading>
                        <Input
                            type="text"
                            value={agent.description}
                            onChange={(e) => handleAgentChange(index, 'description', e.target.value)}
                            placeholder="Enter Agent Description"
                        />
                    </FormRow>
                    <TextButtonRemove
                        handleRemoveIdentifier={(event) => removeAgent(index, event)}
                        label={"Remove Agent"}
                    />
                </React.Fragment>
            ))}
            <TextButtonAdd
                handleAddIdentifier={(event) => addAgent(event)}
                label={"Add Agent"}
            />
        </>
    );
};

export default ItemAgents;
