import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import Heading from '../../../ui/Heading';
import { StyledTextField } from '../../../ui/StyledComponents';
const WorkTitles = ({
    isCreating, errors, category
}) => {
    const { register } = useFormContext();

    return (
        <>
            <FormRow error={errors.work_title_identifying?.message}>
                <Heading as="h5">Identifying work title</Heading>
                <StyledTextField
                    type="text"
                    id="work_title_identifying"
                    {...register("work_title_identifying", { required: "Please enter work title" })}
                    disabled={isCreating}
                />
            </FormRow>
            <FormRow error={errors.work_title_alternative?.message}>
                <Heading as="h5">Alternative work title</Heading>
                <StyledTextField
                    type="text"
                    id="work_title_alternative"
                    {...register("work_title_alternative",)}
                    disabled={isCreating}
                />
            </FormRow>
            <FormRow error={errors.work_title_preferred?.message}>
                <Heading as="h5">Preferred work title</Heading>
                <StyledTextField
                    type="text"
                    id="work_title_preferred"
                    {...register("work_title_preferred",)}
                    disabled={isCreating}
                />
            </FormRow>
            {category == 'photos_and_images' && (

                <FormRow error={errors.work_title_caption?.message}>
                    <Heading as="h5">Caption work title</Heading>
                    <StyledTextField
                        type="text"
                        id="work_title_caption"
                        {...register("work_title_caption",)}
                        disabled={isCreating}
                    />
                </FormRow>
            )}

            < FormRow error={errors.work_title_other?.message}>
                <Heading as="h5">Other title information</Heading>
                <StyledTextField
                    type="text"
                    id="work_title_other"
                    {...register("work_title_other",)}
                    disabled={isCreating}
                />
            </FormRow >
        </>
    );
};

export default WorkTitles;
