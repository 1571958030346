import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import ProtectedRoute from "./ProtectedRoute";
import {
  HiHome,
  HiClipboardDocument,
  HiFolderOpen,
  HiBuildingOffice,
  HiMiniBookmark,
  HiOutlineUsers,
} from "react-icons/hi2";

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const StyledNavLink = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: var(--color-grey-600);
    font-family: "Noto Sans Arabic", "Noto Sans", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.2rem 2.4rem;
    transition: all 0.3s;
  }

  &:hover,
  &:active,
  &.active:link,
  &.active:visited {
    color: var(--color-grey-800);
    background-color: var(--color-grey-50);
    border-radius: var(--border-radius-sm);
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-grey-400);
    transition: all 0.3s;
  }

  &:hover svg,
  &:active svg,
  &.active:link svg,
  &.active:visited svg {
    color: var(--color-brand-600);
  }
`;

function MainNav() {
  const { t } = useTranslation();
  const canAccess = (rolesRequired) => {
    const userRole = localStorage.getItem('role');
    return rolesRequired.includes(userRole);
  };
  return (
    <nav>
      <NavList>
        {canAccess(['general_manager', 'manager']) && (
          <li>
            <StyledNavLink to="/dashboard">
              <HiHome /> <span>{t('titles.home')}</span>
            </StyledNavLink>
          </li>
        )}
        {canAccess(['general_manager', 'manager', 'viewer']) && (
          <li>
            <StyledNavLink to="/catalogue">
              <HiFolderOpen /> <span>{t('titles.catalogue')}</span>
            </StyledNavLink>
          </li>
        )}
        {canAccess(['general_manager', 'manager', 'entry_personnel']) && (
          <li>
            <StyledNavLink to="/records">
              <HiClipboardDocument /> <span>{t('titles.records')}</span>
            </StyledNavLink>
          </li>
        )}
        {canAccess(['general_manager']) && (
          <li>
            <StyledNavLink to="/users">
              <HiOutlineUsers /> <span>{t('titles.users')}</span>
            </StyledNavLink>
          </li>
        )}
        {canAccess(['general_manager', 'manager']) && (
          <li>
            <StyledNavLink to="holding-institutions">
              <HiBuildingOffice /> <span>{t('titles.holding_institutions')}</span>
            </StyledNavLink>
          </li>
        )}
        {canAccess(['general_manager', 'manager']) && (
          <li>
            <StyledNavLink to="templates">
              <HiMiniBookmark /> <span>{t('titles.templates')}</span>
            </StyledNavLink>
          </li>
        )}
      </NavList>
    </nav>
  );
}

export default MainNav;
