import React, { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import CustomInputWithPrefix from '../../../ui/CustomInputWithPrefix';
import FormRow from "../../../ui/FormRow";
import Heading from '../../../ui/Heading';
import GenerateID from '../../../ui/GenerateID';
import Input from "../../../ui/Input";
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
const ItemGeneralFields = ({ category }) => {
    const methods = useFormContext();
    const { register, setValue, control, formState: { errors }, trigger } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "item_identifiers"
    });


    useEffect(() => {
        setValue("item_identifier_type", "Item");
    }, [setValue, fields, append]);

    const handleAddIdentifier = () => {
        if (fields.length < 3) {
            append({ id: "", type: "" });
        }
    };

    return (
        <>
            <FormRow error={errors.item_nfaid?.message}>
                <Heading as="h5">NFA ID</Heading>
                <CustomInputWithPrefix
                    id="item_nfaid"
                    name="item_nfaid"
                    label="NFA ID"
                    prefix="SA-NFA-IYY-"
                    {...register("item_nfaid", { required: "Please enter NFA id" })}
                    GenerateIDComponent={GenerateID}
                />
            </FormRow>

            {fields.map((field, index) => (
                <>
                    <FormRow error={errors?.item_identifiers?.[index]?.id?.message}>
                        <Heading as="h5">Identifier ID {index + 1}</Heading>
                        <Input
                            id={`item_identifiers_${index}`}
                            type="text"
                            {...register(`item_identifiers[${index}].id`, { required: "Please enter ID" })}
                            placeholder={`Identifier ID ${index + 1}`}
                        />
                    </FormRow>
                    <FormRow error={errors?.item_identifiers?.[index]?.type?.message}>
                        <Heading as="h5">Identifier Type {index + 1}</Heading>
                        <Input
                            id={`item_identifiers_type_${index}`}
                            type="text"
                            {...register(`item_identifiers[${index}].type`, { required: "Please enter ID type" })}
                            placeholder={`Identifier Type ${index + 1}`}
                        />
                    </FormRow>
                    {index >= 0 && (
                        <TextButtonRemove handleRemoveIdentifier={() => remove(index)} label="Remove identifer" ></TextButtonRemove>
                    )}
                </>
            ))}
            {fields.length < 3 && (
                <TextButtonAdd handleAddIdentifier={handleAddIdentifier} label="Add new identifer" ></TextButtonAdd>
            )}
        </>
    );
};

export default ItemGeneralFields;
