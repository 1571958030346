import React, { forwardRef } from 'react';
import styled from "styled-components";

const TwoFactorInputStyled = styled.input`
  border: 1px solid var(--color-grey-200);
  background-color: var(--color-grey-0);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm);
  padding: 0.8rem 1.2rem;
  color: var(--color-grey-900);
  text-align: center;
  width: 40px;
  margin-right: 6px;

  &:hover {
    border-color: var(--color-brand-600);
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const TwoFactorInput = forwardRef((props, ref) => {
  return <TwoFactorInputStyled {...props} ref={ref} />;
});

export default TwoFactorInput;
