import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import { itemAccessCondition, itemAccessConditionObjects } from '../../../data/itemData/itemData';
import Heading from '../../../ui/Heading';
import Input from '../../../ui/Input';

const ItemAccessCondition = ({ category, checkedConditions, setCheckedConditions }) => {
    const currentData = category === 'objects' ? itemAccessConditionObjects : itemAccessCondition;

    const inputStyle = {
        margin: '5px'
    };

    const handleCheckboxChange = (condition, option) => {
        setCheckedConditions(prevState => ({
            ...prevState,
            [condition]: prevState[condition].includes(option)
                ? prevState[condition].filter(item => item !== option)
                : [...prevState[condition], option]
        }));
    };

    const handleRadioChange = (condition, option) => {
        setCheckedConditions(prevState => ({
            ...prevState,
            [condition]: option
        }));
    };

    const renderOptions = (condition, options) => (
        options.map(option => {
            const isConditionRadio = condition === 'Shrinkage';
            const checked = isConditionRadio ? checkedConditions[condition] === option : checkedConditions[condition]?.includes(option);

            return (
                <label key={option}>
                    <Input
                        type={isConditionRadio ? 'radio' : 'checkbox'}
                        name={condition}
                        value={option}
                        checked={checked}
                        onChange={() => isConditionRadio ? handleRadioChange(condition, option) : handleCheckboxChange(condition, option)}
                        style={inputStyle}
                    />
                    {option}
                </label>
            );
        })
    );

    return (
        <>
            {Object.keys(currentData).map(condition => (
                <div key={condition}>
                    <Heading as="h5">{condition.replace(/_/g, ' ')}</Heading>
                    {renderOptions(condition, currentData[condition])}
                </div>
            ))}
        </>
    );
};

export default ItemAccessCondition;
