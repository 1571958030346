import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import { StyledTextField } from '../../../ui/StyledComponents';
import GroupedDropDown from '../../../ui/GroupedDropDown';
import DropDown from '../../../ui/DropDown';
import { workCredits, workDocCredits, workPhotosCredits } from "../../../data/workData/workData";
import Heading from '../../../ui/Heading';
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../../ui/Spinner';

const WorkAgents = ({ agents, setAgents, category, errors }) => {
    const { setValue, trigger } = useFormContext();
    const [localErrors, setLocalErrors] = useState({});

    const getDefaultTitles = () => {
        switch (category) {
            case 'audiovisuals':
                return [
                    { title: 'Director', firstName: '', middleName: '', lastName: '' },
                    { title: 'Writer/Screen writer', firstName: '', middleName: '', lastName: '' },
                    { title: 'Producer', firstName: '', middleName: '', lastName: '' }
                ];
            case 'paper_based_documents':
                return [{ title: 'Creator/Author', firstName: '', middleName: '', lastName: '' }];
            case 'photos_and_images':
                return [{ title: 'Creator/Photographer', firstName: '', middleName: '', lastName: '' }];
            default:
                return [];
        }
    };

    useEffect(() => {
        if (agents.length === 0) {
            const defaultTitles = getDefaultTitles();
            setAgents(defaultTitles.map(agent => ({ ...agent, id: uuidv4() })));
        }
    }, [setAgents, agents.length, category]);

    useEffect(() => {
        if (agents.length > 0) {
            agents.forEach((agent, index) => {
                setValue(`agents[${index}].id`, agent.id);
                setValue(`agents[${index}].title`, agent.title);
                setValue(`agents[${index}].firstName`, agent.firstName);
                setValue(`agents[${index}].middleName`, agent.middleName);
                setValue(`agents[${index}].lastName`, agent.lastName);
                setValue(`agents[${index}].characterName`, agent.characterName);
            });
        }
    }, [agents, setValue]);

    const validateAgent = (index) => {
        const agent = agents[index] || {};
        let error = "";
        if (!agent.firstName || !agent.lastName || !agent.title) {
            error = "Please enter all information";
        }
        setLocalErrors(prevErrors => ({
            ...prevErrors,
            [index]: error
        }));
        return error;
    };

    const handleAgentChange = (index, field, value) => {
        const updatedAgents = [...agents];
        updatedAgents[index][field] = value;
        setAgents(updatedAgents);
        setValue(`agents[${index}].${field}`, value, { shouldValidate: true, shouldDirty: true });

        validateAgent(index);
        trigger(`agents[${index}].${field}`);
    };

    const addAgent = (event) => {
        event.preventDefault();
        setAgents([...agents, {
            id: uuidv4(),
            firstName: '',
            middleName: '',
            lastName: '',
            characterName: '',
            title: ''
        }]);
    };

    const removeAgent = (index, event) => {
        event.preventDefault();
        setAgents(agents.filter((_, i) => i !== index));
    };

    const getTitlesList = (category) => {
        switch (category) {
            case 'paper_based_documents':
                return workDocCredits;
            case 'photos_and_images':
                return workPhotosCredits;
            default:
                return workCredits;
        }
    };

    const titlesList = getTitlesList(category);

    const renderError = (index) => {
        const error = localErrors[index];
        return error ? <div style={{ color: 'red', marginTop: '5px' }}>{error}</div> : null;
    };

    if (agents.length === 0) {
        return <Spinner />;
    }

    return (
        <>
            {agents.map((agent, index) => (
                <React.Fragment key={agent.id}>
                    <FormRow>
                        <Heading as="h5">Agent title</Heading>
                        {getDefaultTitles().some(dt => dt.title === agent.title) ? (
                            <Heading as="h6">{agent.title.replace('_', ' ')}</Heading>
                        ) :
                            category === "audiovisuals" ?
                                (
                                    <GroupedDropDown
                                        id={`agent_title_${index}`}
                                        options={titlesList}
                                        onSelect={option => handleAgentChange(index, 'title', option.value)}
                                        value={agent.title}
                                        label={"Choose agent title"}
                                    />
                                )
                                :
                                <DropDown
                                    id={`agent_title_${index}`}
                                    options={titlesList}
                                    onSelect={option => handleAgentChange(index, 'title', option.value)}
                                    value={agent.title}
                                    label={"Choose agent title"}

                                />


                        }
                    </FormRow>
                    <FormRow error={localErrors[index]}>
                        <StyledTextField
                            id={`agent_first_name_${index}`}
                            type="text"
                            onChange={(e) => handleAgentChange(index, 'firstName', e.target.value)}
                            placeholder="First name"
                            value={agent.firstName}
                        />
                        <StyledTextField
                            id={`agent_middle_name_${index}`}
                            type="text"
                            onChange={(e) => handleAgentChange(index, 'middleName', e.target.value)}
                            placeholder="Middle name"
                            value={agent.middleName}
                        />
                        <StyledTextField
                            id={`agent_last_name_${index}`}
                            type="text"
                            onChange={(e) => handleAgentChange(index, 'lastName', e.target.value)}
                            placeholder="Last name"
                            value={agent.lastName}
                        />
                        {agent.title === 'key_cast' && (
                            <StyledTextField
                                id={`agent_character_name_${index}`}
                                type="text"
                                onChange={(e) => handleAgentChange(index, 'characterName', e.target.value)}
                                placeholder="Character name"
                                value={agent.characterName}
                            />
                        )}
                    </FormRow>
                    {!getDefaultTitles().some(dt => dt.title === agent.title) && (
                        <TextButtonRemove
                            handleRemoveIdentifier={(event) => removeAgent(index, event)}
                            label={"Remove agent"}
                        />
                    )}
                </React.Fragment>
            ))}
            <TextButtonAdd
                handleAddIdentifier={(event) => addAgent(event)}
                label={"Add agent"}
            />
        </>
    );
};

export default WorkAgents;
