import React, { useState, useEffect, useRef } from 'react';
import { StyledFormControl, StyledLabel, StyledInput, StyledOptionsContainer, StyledOption } from './StyledDropDown';
import { FaChevronDown } from 'react-icons/fa';

const DropDown = ({ options, onSelect, label, disabled, id, readOnly, value, initialValue, placeholder }) => {
    const [searchValue, setSearchValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [isOpenUpwards, setIsOpenUpwards] = useState(false);

    useEffect(() => {
        if (dropdownRef.current && isOpen) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const spaceBelow = window.innerHeight - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;
            setIsOpenUpwards(spaceBelow < 200 && spaceAbove > spaceBelow);
        }
    }, [isOpen]);

    useEffect(() => {
        const selectedOption = options.find(option => option.value === value);
        if (selectedOption) {
            setSearchValue(selectedOption.label);
        }
    }, [value, options]);

    useEffect(() => {
        if (initialValue && !value) {
            const initialOption = options.find(option => option.value === initialValue);
            if (initialOption) {
                setSearchValue(initialOption.label);
            }
        }
    }, [initialValue, options]);

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
        setIsOpen(true);
        setFilteredOptions(options.filter(option =>
            option.label.toLowerCase().includes(event.target.value.toLowerCase())
        ));
    };

    const handleOptionClick = (option) => {
        setSearchValue(option.label);
        onSelect(option);
        setIsOpen(false);
    };

    const handleBlur = (event) => {
        setTimeout(() => {
            if (event.relatedTarget && dropdownRef.current && !dropdownRef.current.contains(event.relatedTarget)) {
                setIsOpen(false);
            }
        }, 150);
    };

    const handleFocus = () => {
        if (!disabled && !readOnly) {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const dropdownStyle = {
        position: 'absolute',
        top: isOpenUpwards ? 'auto' : '100%',
        bottom: isOpenUpwards ? '100%' : 'auto',
        width: '100%',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1,
        background: 'white',
        border: '1px solid #ccc'
    };

    return (
        <StyledFormControl ref={dropdownRef}>
            <StyledLabel htmlFor={id}>{label}</StyledLabel>
            <div style={{ position: 'relative' }}>
                <StyledInput
                    id={id}
                    value={searchValue}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={disabled || readOnly}
                    placeholder={placeholder}
                    autoComplete="off"
                />
                <FaChevronDown
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '1rem',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                        color: 'var(--color-grey-600)'
                    }}
                />
            </div>
            {isOpen && (
                <div style={dropdownStyle}>
                    {filteredOptions.map(option => (
                        <StyledOption key={option.value} onClick={() => handleOptionClick(option)}>
                            {option.label}
                        </StyledOption>
                    ))}
                </div>
            )}
        </StyledFormControl>
    );
};

export default DropDown;
