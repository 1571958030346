import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../features/authentication/useUser';
import Spinner from './Spinner';
import styled from 'styled-components';

const FullPage = styled.div`
  height: 100vh;
  background-color: var(--color-grey-50);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const roleConfig = {
  entry_personnel: ['records', 'create-work', 'create-item', 'create-manifestation'],
  viewer: ['catalogue'],
  manager: ['dashboard', 'everything'],
  general_manager: ['everything']
};

function ProtectedRoute({ children }) {
  const { isLoading, isAuthenticated } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = localStorage.getItem('role');

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/login");
    } else if (isAuthenticated && userRole) {
      const allowedRoutes = roleConfig[userRole] || [];
      const currentPath = location.pathname.slice(1);

      if (currentPath === "" || currentPath === "login") {
        if (userRole === 'viewer') {
          navigate("/catalogue");
          return;
        } else if (userRole === 'entry_personnel') {
          navigate("/records");
          return;
        }
      }

      if (userRole !== 'general_manager' && !allowedRoutes.includes('everything') && !allowedRoutes.includes(currentPath)) {
        navigate("/unauthorized");
      }
    }
  }, [isAuthenticated, isLoading, navigate, userRole, location.pathname]);

  if (isLoading) {
    return <FullPage><Spinner /></FullPage>;
  }

  return children;
}

export default ProtectedRoute;
