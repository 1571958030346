import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Box, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchItemById } from '../../../../services/apiItem';
import { fetchManifestationById } from '../../../../services/apiManifestation';
import Heading from '../../../../ui/Heading';

function ItemDetails() {
    const { itemId } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState(null);
    const [manifestation, setManifestation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function loadItemDetails() {
            try {
                const data = await fetchItemById(itemId);
                setItem(data);
                if (data.manifest_id) {
                    fetchManifestationById(data.manifest_id)
                        .then(manifestData => {
                            setManifestation(manifestData);
                        })
                        .catch(err => {
                            console.error('Failed to fetch manifestation:', err);
                            setError('Failed to load manifestation data');
                        });
                }
            } catch (err) {
                setError('Failed to fetch item details');
                console.error('Fetch item error:', err);
            } finally {
                setIsLoading(false);
            }
        }
        loadItemDetails();
    }, [itemId]);



    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }


    const formatField = (label, value) => (
        <TableRow key={label}>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{label}</Heading>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{value || ''}</Heading>
            </TableCell>
        </TableRow>
    );

    const formatHeader = (label, value) => (
        <TableRow key={label}>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h7">{label}</Heading>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{value || ''}</Heading>
            </TableCell>
        </TableRow>
    );

    const renderAgents = (agents) => (
        agents.map((agent, index) => {
            return (
                <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{agent.agent_type}</Heading>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{agent.agent_description}</Heading>
                    </TableCell>
                </TableRow>
            );
        })
    );

    const renderEventDetails = (eventDetails) => (
        Object.entries(eventDetails).map(([key, value]) => {
            if (key === 'id') return null;
            return (
                <TableRow key={key}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{key.charAt(0).toUpperCase() + key.slice(1)}</Heading>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}><Heading as="h8">{value}</Heading></TableCell>
                </TableRow>
            );
        })
    );


    const renderEvents = (events, eventType) => (
        events.filter(event => event[eventType]).map((event, index) => (
            <React.Fragment key={index}>
                <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                        <Heading as="h7">
                            Event: {eventType.replace(/^event_/, '').charAt(0).toUpperCase() + eventType.replace(/^event_/, '').slice(1)}
                        </Heading>
                    </TableCell>
                </TableRow>
                {Array.isArray(event[eventType])
                    ? event[eventType].map((detail, idx) => (
                        <React.Fragment key={idx}>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                                    <Heading as="h7">
                                        Date: {detail.date || 'N/A'}
                                    </Heading>
                                </TableCell>
                            </TableRow>
                            {renderEventDetails(detail)}
                        </React.Fragment>
                    ))
                    : (
                        <>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                                    <Heading as="h7">
                                        Date: {event[eventType].date || 'N/A'}
                                    </Heading>
                                </TableCell>
                            </TableRow>
                            {renderEventDetails(event[eventType])}
                        </>
                    )
                }
            </React.Fragment>
        ))
    );

    return (
        <Box margin={3}>
            <IconButton onClick={() => navigate(-1)} color="var(--color-grey-700)">
                <ArrowBackIcon />
            </IconButton>
            <CardContent>
                <Heading as="h7">
                    Item
                </Heading>
                <TableContainer>
                    <TableBody>
                        {formatHeader('[Identifier] Identification number:', item?.item_nfaid)}
                    </TableBody>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Title [Title]</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Title type</Heading></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {formatField(item?.item_title_proper, 'Identifying')}
                            {formatField(item?.item_title_alternative, 'Alternative')}
                            {formatField(item?.item_title_supplied, 'Preferred')}
                            {item?.category === "photos_and_images" && formatField(item?.item_title_caption, 'Caption')}
                            {formatField(item?.item_title_other, 'Other')}
                        </TableBody>

                        <TableBody>
                            {(item.category === "photos_and_images" || item.category === "audiovisuals") && formatField('Physical Description:', item?.item_format_itemtype)}
                            {formatField('Status:', item?.item_accessstatus)}
                            {formatField('Item type:', item?.item_format_general)}
                            {formatField('Base:', item?.item_format_itembase)}
                            {(item.category === "photos_and_images" || item.category === "audiovisuals") && formatField('Colour Colour system:', item?.item_format_colorstandard)}
                            {(item.category === "audiovisuals") && formatField(item?.item_format_audio)}
                            {(item.category === "audiovisuals") && formatField('Sound system:', item?.item_format_audiosystem)}
                            {(item.category === "photos_and_images" || item.category === "audiovisuals") && formatField('Stock:', item?.item_format_stock)}
                            {item.category !== "objects" && formatField('[Original length] Length: ', item?.item_format_length)}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Agent type</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Agent description</Heading></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item?.item_agents && renderAgents(item.item_agents)}
                        </TableBody>
                        <TableBody>
                            {item?.item_events && renderEvents(item.item_events, 'event_inspection')}
                            {item?.item_events && renderEvents(item.item_events, 'event_acquisition')}
                            {item?.item_events && renderEvents(item.item_events, 'event_licensing')}
                            {item?.item_events && renderEvents(item.item_events, 'event_preservation')}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Relationship: Part of title</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">{manifestation?.manifest_title_proper}</Heading><Heading as="h7">{manifestation?.manifest_nfaid}</Heading></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </CardContent>

        </Box>
    );
}

export default ItemDetails;
