import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import Heading from '../../../ui/Heading';
import FormRow from "../../../ui/FormRow";
import Form from "../../../ui/Form";
import FormGroup from '../../../ui/FormGroup';
import Button from "../../../ui/Button";
import "react-datepicker/dist/react-datepicker.css";
import ManifestationTitles from './ManifestationTitles';
import ManifestationGeneralFields from './ManifestationGeneralFields';
import { useUser } from '../../authentication/useUser';
import ManifestationLanguage from './ManifestationLanguage';
import ManifestationFormat from './ManifestationFormat';
import ManifestationAgents from './ManifestationAgents';
import PartOfManifestation from './PartOfManifestation';
import ManifestationExtent from './ManifestationExtent';
import countries from 'i18n-iso-countries';
import { getCountryOptions, getLanguageOptions } from '../../../utils/countries';
import ManifestationEvents from './ManifestationEvents';
import { createManifestation } from '../../../services/apiManifestation';
import CustomModal from '../../../ui/CustomModal';
import FormattedCategory from '../../../utils/formattedCategory';
import Warning from '../../../ui/Warning';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function CreateManifestationForm() {
    const navigate = useNavigate();
    const countryOptions = getCountryOptions();
    const languageOptions = getLanguageOptions();
    const methods = useForm();
    const location = useLocation();
    const [manifestationLanguages, setManifestationLanguages] = useState([{ language: "", dialogue: false, written: false, dialogueType: "", writtenType: "" }]);
    const [agents, setAgents] = useState([{ id: uuidv4(), type: '', description: '' }]);
    const [isItem, setIsItem] = useState(false);
    const { user } = useUser();
    const userId = user.user.user_id;
    const [eventTypes, setEventTypes] = useState({
        publication: false,
        awardNomination: false,
        licensing: false,
        preservation: false,
        decision: false,
        manufacture: false
    });

    const [publicationEvents, setPublicationEvents] = useState([]);
    const [awardEvents, setAwardEvents] = useState([]);
    const [licensingEvents, setLicensingEvents] = useState([]);
    const [preservationEvents, setPreservationEvents] = useState([]);
    const [decisionEvents, setDecisionEvents] = useState([]);
    const [manufactureEvents, setManufactureEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [publicationType, setPublicationType] = useState("");
    const [publicationDate, setPublicationDate] = useState(new Date());
    const [publicationRegion, setPublicationRegion] = useState("");
    const [awardName, setAwardName] = useState("");
    const [awardDate, setAwardDate] = useState(new Date());
    const [awardCategory, setAwardCategory] = useState("");
    const [awardNominationOnly, setAwardNominationOnly] = useState(false);
    const [licensing, setLicensing] = useState("");
    const [preservationType, setPreservationType] = useState("");
    const [preservationDate, setPreservationDate] = useState(new Date());
    const [preservationRegion, setPreservationRegion] = useState("");
    const [preservationNotes, setPreservationNotes] = useState("");
    const [decisionType, setDecisionType] = useState("");
    const [decisionDate, setDecisionDate] = useState(new Date());
    const [decisionRegion, setDecisionRegion] = useState("");
    const [certificateNumber, setCertificateNumber] = useState("");
    const [verdict, setVerdict] = useState("");
    const [manufactureType, setManufactureType] = useState("");
    const [manufactureDate, setManufactureDate] = useState(new Date());
    const [manufactureRegion, setManufactureRegion] = useState("");
    const { work_id: workId, work_title_identifying, category } = location.state || {};
    const [workTitle, setWorkTitle] = useState(work_title_identifying || "");
    const [selectedWorkId, setSelectedWorkId] = useState(workId || "");
    const {
        handleSubmit,
        reset,
        formState: { errors },
    } = methods;


    const queryClient = useQueryClient();
    const { mutate, isLoading: isCreating } = useMutation({
        mutationFn: (manifestData) => createManifestation({ ...manifestData, work_id: workId ? workId : selectedWorkId }),
        onSuccess: (response) => {
            const formData = methods.getValues();
            toast.success("New Manifestation successfully created in", category);
            queryClient.invalidateQueries({ queryKey: ["manifestations"] });
            reset();
            if (isItem) {
                const manifestationId = response.manifest_id;
                const sharedFields = {
                    general: formData.manifest_format_general,
                    specific: formData.manifest_format_specific,
                    color: formData.manifest_format_colortype,
                    colorstandard: formData.manifest_format_colorstandard,
                    aspect: formData.manifest_format_aspect,
                    aperture: formData.manifest_format_aperture,
                    stock: formData.manifest_format_stock,
                    framerate: formData.manifest_format_frame,
                    resolution: formData.manifest_format_resolution,
                    depth: formData.manifest_format_depth,
                    audiofrequency: formData.manifest_format_audiofrequency,
                    audiorate: formData.manifest_format_audiorate,
                    audio: formData.manifest_format_audio,
                    audioformat: formData.manifest_format_audioformat,
                    audiosystem: formData.manifest_format_audiosystem,
                    audiochannel: formData.manifest_format_audiochannel
                };
                navigate("/create-item", {
                    replace: true, state: {
                        manifestationId, workId: workId || selectedWorkId, manifest_title_proper: response.manifestation.manifest_title_proper, category, sharedFields
                    }
                });

            }
            else {
                navigate(-1);
            }
            localStorage.removeItem('manifestationDraft');
        },
        onError: (err) => toast.error(err.message),
    });

    const handleSaveDraft = () => {

        const formData = {
            ...methods.getValues(),
            manifestationLanguages,
            agents,
            isItem,
            selectedWorkId,
            eventTypes,
            publicationType,
            publicationDate,
            publicationRegion,
            awardName,
            awardDate,
            awardCategory,
            awardNominationOnly,
            licensing,
            preservationType,
            preservationDate,
            preservationRegion,
            preservationNotes,
            decisionType,
            decisionDate,
            decisionRegion,
            certificateNumber,
            verdict,
            manufactureType,
            manufactureDate,
            manufactureRegion,
            workId
        };

        const serializedData = JSON.stringify(formData);
        localStorage.setItem('manifestationDraft', serializedData);
        toast.success("Draft saved successfully!");
    };

    const loadDraft = () => {
        const storedData = localStorage.getItem('manifestationDraft');
        if (storedData) {
            const draftData = JSON.parse(storedData);

            methods.reset(draftData);
            setManifestationLanguages(draftData.manifestationLanguages);
            setAgents(draftData.agents);
            setIsItem(draftData.isItem);
            setSelectedWorkId(draftData.selectedWorkId);
            setEventTypes(draftData.eventTypes);
            setPublicationType(draftData.publicationType);
            setPublicationDate(new Date(draftData.publicationDate));
            setPublicationRegion(draftData.publicationRegion);
            setAwardName(draftData.awardName);
            setAwardDate(new Date(draftData.awardDate));
            setAwardCategory(draftData.awardCategory);
            setAwardNominationOnly(draftData.awardNominationOnly);
            setLicensing(draftData.licensing);
            setPreservationType(draftData.preservationType);
            setPreservationDate(new Date(draftData.preservationDate));
            setPreservationRegion(draftData.preservationRegion);
            setPreservationNotes(draftData.preservationNotes);
            setDecisionType(draftData.decisionType);
            setDecisionDate(new Date(draftData.decisionDate));
            setDecisionRegion(draftData.decisionRegion);
            setCertificateNumber(draftData.certificateNumber);
            setVerdict(draftData.verdict);
            setManufactureType(draftData.manufactureType);
            setManufactureDate(new Date(draftData.manufactureDate));
            setManufactureRegion(draftData.manufactureRegion);
        }
    };

    useEffect(() => {
        loadDraft();
    }, []);


    const onSubmit = (data, event) => {
        event.preventDefault();
        if (!selectedWorkId || selectedWorkId === "") {
            toast.error("Please choose a work from the list");
            return;
        }

        setIsModalOpen(true);
    };

    const confirmSubmit = () => {
        setIsModalOpen(false);
        const formData = methods.getValues();
        const cleanData = JSON.parse(JSON.stringify(formData));
        const manifestationData = {
            ...cleanData,
            category,
            user_id: userId || "",
            part_of_work_id: selectedWorkId || "",
            agents: agents.map(agent => ({
                type: agent.type,
                description: agent.description,
            })),
            events: {
                publication: publicationEvents,
                awardNomination: awardEvents,
                licensing: licensingEvents,
                preservation: preservationEvents,
                manufacture: manufactureEvents,
                decision: decisionEvents
            },
            manifest_format_language: manifestationLanguages.filter(lang =>
                lang.language.trim() !== "" ||
                (lang.dialogue && lang.dialogueType.trim() !== "") ||
                (lang.written && lang.writtenType.trim() !== "")
            )
        };


        Object.keys(manifestationData).forEach(key => {
            if (manifestationData[key] === undefined) {
            }
        });

        try {
            JSON.stringify(manifestationData);
        } catch (error) {
            return;
        }

        mutate(manifestationData);
    };

    const onError = (errors) => {

    };

    const toggleEventType = (type) => {
        setEventTypes(prev => ({ ...prev, [type]: !prev[type] }));
    };

    return (
        <FormProvider {...methods}>
            <Heading as="h3">Manifestation Form, starting new <FormattedCategory category={category} /></Heading>
            <Form type="tertiary" onSubmit={handleSubmit(onSubmit, onError)}>
                <FormGroup>
                    <ManifestationGeneralFields errors={errors} category={category} />
                </FormGroup>
                <FormGroup>
                    <ManifestationTitles category={category} isCreating={isCreating} errors={errors} />
                </FormGroup>
                {(category !== "photos_and_images") && (
                    <FormGroup>
                        <ManifestationLanguage
                            languageOptions={languageOptions}
                            manifestationLanguages={manifestationLanguages}
                            setManifestationLanguages={setManifestationLanguages}
                            category={category}
                            errors={errors}
                        />
                    </FormGroup>
                )}
                <FormGroup>
                    <ManifestationFormat category={category} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <ManifestationExtent category={category} isCreating={isCreating} />
                </FormGroup>
                <FormGroup>
                    <ManifestationEvents
                        publicationEvents={publicationEvents}
                        setPublicationEvents={setPublicationEvents}
                        awardEvents={awardEvents}
                        setAwardEvents={setAwardEvents}
                        licensingEvents={licensingEvents}
                        setLicensingEvents={setLicensingEvents}
                        preservationEvents={preservationEvents}
                        setPreservationEvents={setPreservationEvents}
                        decisionEvents={decisionEvents}
                        setDecisionEvents={setDecisionEvents}
                        manufactureEvents={manufactureEvents}
                        setManufactureEvents={setManufactureEvents}
                        eventTypes={eventTypes}
                        toggleEventType={toggleEventType}
                        countryOptions={countryOptions}
                        category={category}
                    />
                </FormGroup>
                <FormGroup>
                    <ManifestationAgents
                        category={category}
                        agents={agents}
                        setAgents={setAgents}
                        errors={errors}
                    />
                </FormGroup>
                <FormGroup>
                    {workId ? (
                        <Heading as={'h6'}>Manifestation is a part of work: {workId} {work_title_identifying}</Heading>
                    ) : (
                        <PartOfManifestation
                            selectedWorkId={selectedWorkId}
                            setSelectedWorkId={setSelectedWorkId}
                            category={category}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <FormRow label="Does this manifestation have an Item?">
                        <input type="checkbox" checked={isItem} onChange={() => setIsItem(!isItem)} />
                    </FormRow>
                </FormGroup>
                <Button variation="secondary" type="reset" onClick={() => { localStorage.removeItem('manifestationDraft'); }}>
                    Reset
                </Button>
                <Button variation="secondary" type="reset" onClick={handleSaveDraft}>
                    Save
                </Button>
                <Button type="submit" disabled={isCreating}>Record</Button>
                {Object.keys(errors).length > 0 && (
                    <Warning message="A mandatory field must be filled" color="red" />
                )}
            </Form>
            {isCreating && <SpinnerOverlay />}
            <CustomModal
                isOpen={isModalOpen}
                confirmationMessage={"Are you sure you want to submit the manifestation?"}
                onRequestClose={() => setIsModalOpen(false)}
                onConfirm={confirmSubmit}
            />
        </FormProvider>
    );
}

export default CreateManifestationForm;
