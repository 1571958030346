import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { fetchStorageData } from '../../services/apiStorage';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';
import Heading from '../../ui/Heading';
import { useTranslation } from 'react-i18next';
const ChartBox = styled.div`
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);
  display: flex;
  padding: 20px;
  justify-content: space-around;
  padding: 2.4rem 3.2rem;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  box-shadow: var(--shadow-md);

  & > *:first-child {
    margin-bottom: 1.6rem;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #333;
  min-width: 350px;
  width: 100%;
`;
const convertToBytes = (size) => {
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  const [value, unit] = size.split(' ');
  const unitIndex = units.indexOf(unit);
  return parseFloat(value) * Math.pow(1024, unitIndex);
};

function StorageUsageChart() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const loadData = async () => {
      try {
        const storageData = await fetchStorageData();
        const formattedData = [
          { name: "Used Storage", value: convertToBytes(storageData.used_storage), color: "#F5782B" },
          { name: "Total Storage", value: convertToBytes(storageData.total_storage), color: "#91B9B4" }
        ];
        setData(formattedData);
      } catch (error) {
        setError('Failed to load storage data.');
        toast.error('Failed to load storage data.');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Heading as="h3">{t('dashboard.storage_chart')}</Heading>
      <ChartBox>
        <ChartContainer>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#91B9B4" />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      </ChartBox>
    </>

  );
}

export default StorageUsageChart;
