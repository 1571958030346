import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DropDown from '../../../ui/DropDown';
import toast from 'react-hot-toast';

const PartOfItem = ({ selectedManifestId, setSelectedManifestId, category }) => {
    const [manifestations, setManifestations] = useState([]);

    useEffect(() => {
        const fetchManifestations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/manifestations-by-category?category=${encodeURIComponent(category)}`);

                if (response.data.message) {
                    toast(response.data.message, {
                        icon: 'ℹ️',
                    });
                } else {
                    const uniqueManifestations = response.data.reduce((acc, manifest) => {
                        const newManifest = {
                            manifestId: manifest.manifest_id,
                            workId: manifest.work_id,
                            label: `${manifest.label}`,
                            value: manifest.manifest_id
                        };
                        if (!acc.some(item => item.value === newManifest.value)) {
                            acc.push(newManifest);
                        }
                        return acc;
                    }, []);
                    setManifestations(uniqueManifestations);
                }

            } catch (error) {
                toast(`Error fetching manifestations: ${error.response ? error.response.data.message : error.message}`, {
                    icon: '❌',
                });
            }
        };

        fetchManifestations();
    }, [category]);

    const handleSelectManifest = (option) => {
        const selectedManifest = manifestations.find(manifest => manifest.manifestId === option.value);
        if (selectedManifest) {
            setSelectedManifestId({
                manifestId: selectedManifest.manifestId,
                workId: selectedManifest.workId
            });
            toast.success('Manifestation selected successfully');
        } else {
            toast.error('Selected manifestation is not found in the list.');
        }
    };

    return (
        <div>
            {manifestations.length > 0 ? (
                <DropDown
                    key={manifestations.length}
                    id="part_of_manifest"
                    options={manifestations}
                    onSelect={handleSelectManifest}
                    value={selectedManifestId?.manifestId || ''}
                    label="This Item is Part of"
                />
            ) : (
                <p>Loading manifestations...</p>
            )}
        </div>
    );
};

export default PartOfItem;
