import axios from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response;
        if ((status === 401 || status === 403) && history.location.pathname !== '/api/login') {
            localStorage.removeItem('token');
            if (history.location.pathname !== '/login') {
                history.push('/login');
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
