import axios from 'axios';

export const fetchUnreadNotificationCount = async (userId) => {
    if (!userId) {
        console.error('No user ID provided');
        return 0;
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications/count/${userId}`);
        if (response.status === 200 && response.data && typeof response.data.count === 'number') {
            return response.data.count;
        } else {
            console.error('Invalid response structure:', response);
            return 0;
        }
    } catch (error) {
        console.error('Failed to fetch notifications count:', error);
        return 0;
    }
};

export const getNotifications = async (userId) => {
    if (!userId) {
        console.error('No user ID provided');
        return [];
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications/${userId}`);
        if (response.status === 200 && response.data && Array.isArray(response.data)) {
            return response.data;
        } else {
            console.error('Invalid response structure:', response);
            return [];
        }
    } catch (error) {
        console.error('Failed to fetch notifications:', error);
        return [];
    }
};

export const markNotificationAsRead = async (notificationIds) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/notifications/read/${notificationIds}`);
        return response.data;
    } catch (error) {
        console.error('Failed to mark notification as read:', error);
        throw error;
    }
};

export const deleteNotification = async (notificationId) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/notifications/${notificationId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to mark notification as read:', error);
        throw error;
    }
};