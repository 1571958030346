import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  to {
    transform: rotate(1turn)
  }
`;

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it is on top */

  &::before {
    content: "";
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    background: radial-gradient(farthest-side, var(--color-brand-600) 94%, #0000) top/10px 10px no-repeat,
    conic-gradient(#0000 30%, var(--color-brand-600));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
    animation: ${rotate} 1.5s infinite linear;
  }
`;

export default SpinnerOverlay;
