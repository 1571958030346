import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Warning = ({ message, color }) => {
    const warningRef = useRef(null);

    useEffect(() => {
        if (warningRef.current) {
            warningRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, []);

    const getStyles = (color) => {
        let backgroundColor, textColor, icon;

        switch (color.toLowerCase()) {
            case 'yellow':
                backgroundColor = '#ffeb3b';
                textColor = '#000000';
                icon = '⚠️';
                break;
            case 'red':
                backgroundColor = '#f44336';
                textColor = '#ffffff';
                icon = '❌';
                break;
            case 'green':
                backgroundColor = '#4caf50';
                textColor = '#ffffff';
                icon = '✅';
                break;
            default:
                backgroundColor = '#ffffff';
                textColor = '#000000';
                icon = '';
                break;
        }

        return {
            backgroundColor,
            textColor,
            icon,
            container: {
                display: 'flex',
                alignItems: 'center',
                padding: '16px',
                borderRadius: '4px',
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '10px 0',
                textAlign: 'center',
                position: 'fixed',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
            }
        };
    };

    const styles = getStyles(color);

    return (
        <div ref={warningRef} style={{ ...styles.container, backgroundColor: styles.backgroundColor, color: styles.textColor }}>
            <span style={{ marginRight: '8px' }}>{styles.icon}</span>
            {message}
        </div>
    );
};

Warning.propTypes = {
    message: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['Yellow', 'Red', 'Green']).isRequired,
};

export default Warning;
