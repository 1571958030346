import { HiArrowRightOnRectangle } from "react-icons/hi2";
import HeaderButtonIcon from "../../ui/HeaderButtonIcon";
import { useLogout } from "./useLogout";
import SpinnerMini from "../../ui/SpinnerMini";

function Logout() {
  const { logout, isLoading } = useLogout();
  return (
    <HeaderButtonIcon disabled={isLoading} onClick={logout}>
      {!isLoading ? <HiArrowRightOnRectangle /> : <SpinnerMini />}
    </HeaderButtonIcon>
  );
}

export default Logout;
