
export const workTypes = [
    { label: 'Analytic - serial ', value: 'analytic_serial ' },
    { label: 'Analytic - collection ', value: 'analytic_collection' },
    { label: 'Monographic', value: 'monographic' },
];


export const workTitleTypes = [
    { label: 'Identifying', value: 'identifying' },
    { label: 'Alternative', value: 'alternative' },
    { label: 'Preferred', value: 'preferred' },
    { label: 'Other', value: 'other' },
];


export const workDateTypes = [
    { label: 'Publication', value: 'publication' },
    { label: 'Award or Nomination', value: 'awardornomination' },
    { label: 'Production', value: 'production' },
    { label: 'Rights/Copyright/IPR Registration', value: 'rightsoriprregisteration' },
];

export const workDatePrecision = [
    { label: 'Precise', value: 'precise' },
    { label: 'Approximate', value: 'approximate' },
    { label: 'Estimated', value: 'estimated' },
];

export const workContentTypes = [
    { label: 'Synopsis', value: 'synopsis' },
    { label: 'Logline', value: 'logline' },
    { label: 'Review', value: 'review' },
    { label: 'Shootlist', value: 'shootlist' }
];

export const workGenresAudioVisual = [
    { label: 'Actuality', value: 'actuality' },
    { label: 'Adaptation', value: 'adaptation' },
    { label: 'Nonfiction Adventure', value: 'nonfiction_adventure' },
    { label: 'Adventure', value: 'adventure' },
    { label: 'Ancient World', value: 'ancient_world' },
    { label: 'Animal', value: 'animal' },
    { label: 'Art', value: 'art' },
    { label: 'Aviation', value: 'aviation' },
    { label: 'Nonfiction Biographical ', value: 'nonfiction_biographical' },
    { label: 'Biographical', value: 'biographical' },
    { label: 'Buddy', value: 'buddy' },
    { label: 'Caper', value: 'caper' },
    { label: 'Chase', value: 'chase' },
    { label: 'Children’s', value: 'childrens' },
    { label: 'College', value: 'college' },
    { label: 'Comedy', value: 'comedy' },
    { label: 'Crime', value: 'crime' },
    { label: 'Dance', value: 'dance' },
    { label: 'Dark Comedy', value: 'dark_comedy' },
    { label: 'Disability', value: 'disability' },
    { label: 'Disaster', value: 'disaster' },
    { label: 'Documentary', value: 'documentary' },
    { label: 'Domestic Comedy', value: 'domestic_comedy' },
    { label: 'Educational', value: 'educational' },
    { label: 'Espionage', value: 'espionage' },
    { label: 'Nonfiction Ethnic', value: 'nonfiction_ethnic' },
    { label: 'Ethnic', value: 'ethnic' },
    { label: 'Ethnographic', value: 'ethnographic' },
    { label: 'Experimental', value: 'experimental' },
    { label: 'Absolute', value: 'absolute' },
    { label: 'Abstract Live Action', value: 'abstract_live_action' },
    { label: 'Activist', value: 'activist' },
    { label: 'Autobiographical', value: 'autobiographical' },
    { label: 'City Symphony', value: 'city_symphony' },
    { label: 'Cubist', value: 'cubist' },
    { label: 'Dada', value: 'dada' },
    { label: 'Diary', value: 'diary' },
    { label: 'Feminist', value: 'feminist' },
    { label: 'Intermittent Animation', value: 'intermittent_animation' },
    { label: 'Landscape', value: 'landscape' },
    { label: 'Loop', value: 'loop' },
    { label: 'Lyrical', value: 'lyrical' },
    { label: 'Participatory', value: 'participatory' },
    { label: 'Portrait', value: 'portrait' },
    { label: 'Reflexive', value: 'reflexive' },
    { label: 'Street', value: 'street' },
    { label: 'Structural', value: 'structural' },
    { label: 'Surrealist', value: 'surrealist' },
    { label: 'Text', value: 'text' },
    { label: 'Trance', value: 'trance' },
    { label: 'Exploitation', value: 'exploitation' },
    { label: 'Fallen Woman', value: 'fallen_woman' },
    { label: 'Family', value: 'family' },
    { label: 'Fantasy', value: 'fantasy' },
    { label: 'Film Noir', value: 'film_noir' },
    { label: 'Game', value: 'game' },
    { label: 'Gangster', value: 'gangster' },
    { label: 'Historical', value: 'historical' },
    { label: 'Home Shopping', value: 'home_shopping' },
    { label: 'Horror', value: 'horror' },
    { label: 'Industrial', value: 'industrial' },
    { label: 'Instructional', value: 'instructional' },
    { label: 'Interview', value: 'interview' },
    { label: 'Journalism', value: 'journalism' },
    { label: 'Jungle', value: 'jungle' },
    { label: 'Juvenile ', value: 'juvenile' },
    { label: 'Delinquency', value: 'delinquency' },
    { label: 'Lecture', value: 'lecture' },
    { label: 'Legal', value: 'legal' },
    { label: 'Magazine', value: 'magazine' },
    { label: 'Martial Arts', value: 'martial_arts' },
    { label: 'Maternal', value: 'maternal' },
    { label: 'Melodrama', value: 'melodrama' },
    { label: 'Nonfiction Medical', value: 'nonfiction_melodrama' },
    { label: 'Medical', value: 'medical' },
    { label: 'Medical Melodrama', value: 'medical_melodrama' },
    { label: 'Military', value: 'military' },
    { label: 'Music', value: 'music' },
    { label: 'Music Video', value: 'music_video' },
    { label: 'Musical', value: 'musical' },
    { label: 'Mystery', value: 'mystery' },
    { label: 'Nature', value: 'nature' },
    { label: 'News', value: 'news' },
    { label: 'Newsreel', value: 'newsreel' },
    { label: 'Opera', value: 'opera' },
    { label: 'Operetta', value: 'operetta' },
    { label: 'Parody', value: 'parody' },
    { label: 'Police', value: 'police' },
    { label: 'Political', value: 'political' },
    { label: 'Prehistoric', value: 'prehistoric' },
    { label: 'Prison', value: 'prison' },
    { label: 'Propaganda', value: 'propaganda' },
    { label: 'Public Access', value: 'public_access' },
    { label: 'Public Affairs', value: 'public_affairs' },
    { label: 'Reality-based', value: 'reality_based' },
    { label: 'Religion', value: 'religion' },
    { label: 'Religious', value: 'religious' },
    { label: 'Road', value: 'road' },
    { label: 'Romance', value: 'romance' },
    { label: 'Science Fiction', value: 'science_fiction' },
    { label: 'Screwball Comedy', value: 'screwball_comedy' },
    { label: 'Show Business', value: 'show_business' },
    { label: 'Singing Cowboy', value: 'singing_cowboy' },
    { label: 'Situation Comedy', value: 'situation_comedy' },
    { label: 'Slapstick Comedy', value: 'slapstick_comedy' },
    { label: 'Slasher', value: 'slasher' },
    { label: 'Soap Opera', value: 'soap_opera' },
    { label: 'Social Guidance', value: 'social_guidance' },
    { label: 'Social Problem', value: 'social_problem' },
    { label: 'Sophisticated Comedy', value: 'sophisticated_comedy' },
    { label: 'Speculation', value: 'speculation' },
    { label: 'Sponsored', value: 'sponsored' },
    { label: 'Nonfiction Sports', value: 'nonfiction_sports' },
    { label: 'Sports', value: 'sports' },
    { label: 'Survival', value: 'survival' },
    { label: 'Talk', value: 'talk' },
    { label: 'Thriller', value: 'thriller' },
    { label: 'Training', value: 'training' },
    { label: 'Travelogue', value: 'travelogue' },
    { label: 'Trick', value: 'trick' },
    { label: 'Trigger', value: 'trigger' },
    { label: 'Variety', value: 'variety' },
    { label: 'Nonfiction War', value: 'nonfiction_war' },
    { label: 'War', value: 'war' },
    { label: 'Western', value: 'western' }
];

export const workGenresPhoto = [
    { label: 'Portraiture', value: 'portraiture' },
    { label: 'Landscape', value: 'landscape' },
    { label: 'Still Life', value: 'still_life' },
    { label: 'Abstract', value: 'abstract' },
    { label: 'Documentary', value: 'documentary' },
    { label: 'Street', value: 'street' },
    { label: 'Fine Art', value: 'fine_art' },
    { label: 'Fashion', value: 'fashion' },
    { label: 'Wildlife', value: 'wildlife' },
    { label: 'Architectural', value: 'architectural' }
];

export const workGenresDoc = [
    { label: 'Fiction', value: 'fiction' },
    { label: 'Non-fiction', value: 'non_fiction' },
    { label: 'Biography', value: 'biography' },
    { label: 'Autobiography', value: 'autobiography' },
    { label: 'Memoir', value: 'memoir' },
    { label: 'History', value: 'history' },
    { label: 'Historical Fiction', value: 'historical_fiction' },
    { label: 'Science Fiction', value: 'science_fiction' },
    { label: 'Fantasy', value: 'fantasy' },
    { label: 'Mystery', value: 'mystery' },
    { label: 'Thriller', value: 'thriller' },
    { label: 'Horror', value: 'horror' },
    { label: 'Romance', value: 'romance' },
    { label: 'Adventure', value: 'adventure' },
    { label: 'Western', value: 'western' },
    { label: 'Graphic Novel', value: 'graphic_novel' },
    { label: 'Comic Book', value: 'comic_book' },
    { label: 'Poetry', value: 'poetry' },
    { label: 'Drama', value: 'drama' },
    { label: 'Essay', value: 'essay' },
    { label: 'Travelogue', value: 'travelogue' },
    { label: 'Cookbook', value: 'cookbook' },
    { label: 'Self-Help', value: 'self_help' },
    { label: 'Reference', value: 'reference' },
    { label: 'Literary Criticism', value: 'literary_criticism' },
    { label: 'Essays on Literary Theory', value: 'essays_on_literary_theory' },
    { label: 'Short Story Collection', value: 'short_story_collection' },
    { label: 'Children\'s Picture Book', value: 'childrens_picture_book' },
    { label: 'Young Adult (YA)', value: 'young_adult_ya' },
    { label: 'Adult', value: 'adult' },
    { label: 'Satire', value: 'satire' },
    { label: 'Fable', value: 'fable' },
    { label: 'Parable', value: 'parable' },
    { label: 'Mythology', value: 'mythology' },
    { label: 'Folklore', value: 'folklore' },
    { label: 'Fairy Tale', value: 'fairy_tale' },
    { label: 'Legend', value: 'legend' },
    { label: 'Epistolary Novel', value: 'epistolary_novel' }
];

export const workFormsAudioVisuals = [
    { label: 'Advertising', value: 'advertising' },
    { label: 'Amateur', value: 'amateur' },
    { label: 'Animation', value: 'animation' },
    { label: 'Anthology', value: 'anthology' },
    { label: 'Audition', value: 'audition' },
    { label: 'Excerpt', value: 'excerpt' },
    { label: 'Feature', value: 'feature' },
    { label: 'Outtake', value: 'outtake' },
    { label: 'Performance', value: 'performance' },
    { label: 'Puppet', value: 'puppet' },
    { label: 'Serial', value: 'serial' },
    { label: 'Series', value: 'series' },
    { label: 'Short', value: 'short' },
    { label: 'Stock Shot', value: 'stock_shot' },
    { label: 'Television', value: 'television' },
    { label: 'Television Commercial', value: 'television_commercial' },
    { label: 'Television Feature', value: 'television_feature' },
    { label: 'Television Mini-Series', value: 'television_mini_series' },
    { label: 'Television Pilot', value: 'television_pilot' },
    { label: 'Television Series', value: 'television_series' },
    { label: 'Trailer', value: 'trailer' },
    { label: 'Unedited', value: 'unedited' }
];

export const workFormsPhoto = [
    { label: 'Black and White Photography', value: 'black_and_white_photography' },
    { label: 'Color Photography', value: 'color_photography' },
    { label: 'Digital Photography', value: 'digital_photography' },
    { label: 'Long Exposure Photography', value: 'long_exposure_photography' },
    { label: 'Film Photography', value: 'film_photography' },
    { label: 'Polaroid/Instant Photography', value: 'polaroid_instant_photography' },
    { label: 'Astrophotography', value: 'astrophotography' },
    { label: 'High-Speed Photography', value: 'high_speed_photography' },
    { label: 'Pinhole Photography', value: 'pinhole_photography' },
    { label: 'Experimental Photography', value: 'experimental_photography' },
    { label: 'Underwater Photography', value: 'underwater_photography' },
    { label: 'Pin-up Photography', value: 'pin_up_photography' },
    { label: 'Architectural Photography', value: 'architectural_photography' },
    { label: 'Macro Photography', value: 'macro_photography' },
    { label: 'Aerial Photography', value: 'aerial_photography' },
    { label: 'Infrared Photography', value: 'infrared_photography' },
    { label: 'Travel Photography', value: 'travel_photography' },
    { label: 'Sports Photography', value: 'sports_photography' },
    { label: 'Composite Photography', value: 'composite_photography' },
    { label: 'Light Painting Photography', value: 'light_painting_photography' },
    { label: 'Candid Photography', value: 'candid_photography' },
    { label: 'Abstract Photography', value: 'abstract_photography' },
    { label: 'Painting', value: 'painting' },
    { label: 'Drawing', value: 'drawing' },
    { label: 'Printmaking', value: 'printmaking' },
    { label: 'Collage', value: 'collage' },
    { label: 'Mixed Media', value: 'mixed_media' },
    { label: 'Digital Art', value: 'digital_art' }
];

export const workFormsDoc = [
    { label: 'Anthology', value: 'anthology' },
    { label: 'Almanac', value: 'almanac' },
    { label: 'Encyclopedia', value: 'encyclopedia' },
    { label: 'Magazine/Journal (Periodical)', value: 'magazine_journal_periodical' },
    { label: 'Newspaper', value: 'newspaper' },
    { label: 'Art Book', value: 'art_book' },
    { label: 'Photography Book', value: 'photography_book' },
    { label: 'Music Score', value: 'music_score' },
    { label: 'Play Script', value: 'play_script' },
    { label: 'Screenplay', value: 'screenplay' },
    { label: 'Epic', value: 'epic' },
    { label: 'Saga', value: 'saga' },
    { label: 'Manuscript', value: 'manuscript' },
    { label: 'Pamphlet', value: 'pamphlet' },
    { label: 'Brochure', value: 'brochure' },
    { label: 'Catalog', value: 'catalog' },
    { label: 'Yearbook', value: 'yearbook' },
    { label: 'Atlas', value: 'atlas' },
    { label: 'Map', value: 'map' },
    { label: 'Guidebook', value: 'guidebook' },
    { label: 'Instruction Manual/Guide', value: 'instruction_manual_guide' },
    { label: 'Handbook', value: 'handbook' },
    { label: 'Dictionary', value: 'dictionary' },
    { label: 'Thesaurus', value: 'thesaurus' },
    { label: 'Report', value: 'report' },
    { label: 'Thesis/Dissertation', value: 'thesis_dissertation' },
    { label: 'Journal', value: 'journal' },
    { label: 'Notebook', value: 'notebook' },
    { label: 'Scrapbook', value: 'scrapbook' },
    { label: 'Diary', value: 'diary' },
    { label: 'Calendar', value: 'calendar' },
    { label: 'Recipe Book', value: 'recipe_book' },
    { label: 'Sheet Music', value: 'sheet_music' },
    { label: 'Manuscript Score', value: 'manuscript_score' },
    { label: 'Journal Article', value: 'journal_article' },
    { label: 'Review', value: 'review' }
];

export const workCredits = [
    {
        title: 'Production Department',
        options: [
            { label: 'Executive Producer', value: 'executive_producer' },
            { label: 'Line Producer', value: 'line_producer' },
            { label: 'Production Manager', value: 'production_manager' },
            { label: 'Production Coordinator', value: 'production_coordinator' },
            { label: 'Production Secretary', value: 'production_secretary' },
            { label: 'Production Accountant', value: 'production_accountant' },
            { label: 'Post-production Supervisor', value: 'post_production_supervisor' },
            { label: 'Assistant Director', value: 'assistant_director' },
            { label: 'Production Assistant / Production Runner', value: 'production_assistant_runner' },
            { label: 'Stunt Coordinator', value: 'stunt_coordinator' },
            { label: 'Casting Director', value: 'casting_director' },
            { label: 'Storyboard Artist', value: 'storyboard_artist' },
            { label: 'Caterers', value: 'caterers' },
            { label: 'Unit Nurse', value: 'unit_nurse' },
            { label: 'Unit Publicist', value: 'unit_publicist' },
            { label: 'Stills Photographer', value: 'stills_photographer' }
        ]
    },
    {
        title: 'Script Department',
        options: [
            { label: 'Story Producer', value: 'story_producer' },
            { label: 'Script Editor', value: 'script_editor' },
            { label: 'Script Coordinator', value: 'script_coordinator' }
        ]
    },
    {
        title: 'Location Department',
        options: [
            { label: 'Location Manager', value: 'location_manager' },
            { label: 'Location Assistant', value: 'location_assistant' },
            { label: 'Location Scout', value: 'location_scout' }
        ]
    },
    {
        title: 'Camera Department',
        options: [
            { label: 'Director of Photography/Cinematographer', value: 'director_of_photography_cinematographer' },
            { label: 'Camera Operator', value: 'camera_operator' },
            { label: 'Assistant Camera', value: 'assistant_camera' },
            { label: 'Loader', value: 'loader' },
            { label: 'Camera Production Assistant', value: 'camera_production_assistant' },
            { label: 'Digital Imaging Technician (DIT)', value: 'digital_imaging_technician_dit' },
            { label: 'Data Wrangler', value: 'data_wrangler' },
            { label: 'Steadicam Operator', value: 'steadicam_operator' },
            { label: 'Motion Control Technician/Operator', value: 'motion_control_technician_operator' }
        ]
    },
    {
        title: 'Sound Department',
        options: [
            { label: 'Production Sound Mixer (Sound Recordist)', value: 'production_sound_mixer_sound_recordist' },
            { label: 'Boom Operator (Boom Swinger)', value: 'boom_operator_boom_swinger' }
        ]
    },
    {
        title: 'Grip Department',
        options: [
            { label: 'Key Grip', value: 'key_grip' },
            { label: 'Best Boy (Grip)', value: 'best_boy_grip' },
            { label: 'Dolly Grip', value: 'dolly_grip' }
        ]
    },
    {
        title: 'Electrical Department',
        options: [
            { label: 'Gaffer', value: 'gaffer' },
            { label: 'Best Boy (Electrical)', value: 'best_boy_electrical' },
            { label: 'Lighting Technician', value: 'lighting_technician' }
        ]
    },
    {
        title: 'Art Department',
        options: [
            { label: 'Production Designer', value: 'production_designer' },
            { label: 'Art Director', value: 'art_director' },
            { label: 'Set Designer', value: 'set_designer' },
            { label: 'Illustrator', value: 'illustrator' },
            { label: 'Set Decorator', value: 'set_decorator' },
            { label: 'Buyer', value: 'buyer' },
            { label: 'Set Dresser', value: 'set_dresser' },
            { label: 'Props Master', value: 'props_master' },
            { label: 'Standby Props', value: 'standby_props' },
            { label: 'Props Builder', value: 'props_builder' },
            { label: 'Armorer', value: 'armorer' },
            { label: 'Construction Coordinator / Construction Manager', value: 'construction_coordinator_construction_manager' },
            { label: 'Key Scenic', value: 'key_scenic' },
            { label: 'Greensman', value: 'greensman' }
        ]
    },
    {
        title: 'Hair and Make-up Department',
        options: [
            { label: 'Make-up Artist', value: 'makeup_artist' },
            { label: 'Hairdresser', value: 'hairdresser' }
        ]
    },
    {
        title: 'Wardrobe Department',
        options: [
            { label: 'Costume Designer', value: 'costume_designer' },
            { label: 'Costume Supervisor', value: 'costume_supervisor' },
            { label: 'Costume Standby', value: 'costume_standby' },
            { label: 'Art Finisher', value: 'art_finisher' },
            { label: 'Buyer', value: 'wardrobe_buyer' },
            { label: 'Cutter/Fitter', value: 'cutter_fitter' }
        ]
    },
    {
        title: 'Post-Production',
        options: [
            { label: 'Film Editor', value: 'film_editor' },
            { label: 'Assistant Editor', value: 'assistant_editor' },
            { label: 'Colorist', value: 'colorist' },
            { label: 'Negative Cutter', value: 'negative_cutter' },
            { label: 'Sound Designer', value: 'sound_designer' },
            { label: 'Dialogue Editor', value: 'dialogue_editor' },
            { label: 'Sound Editor', value: 'sound_editor' },
            { label: 'Re-recording Mixer', value: 're_recording_mixer' },
            { label: 'Music Supervisor', value: 'music_supervisor' },
            { label: 'Composer', value: 'composer' },
            { label: 'Foley Artist', value: 'foley_artist' }
        ]
    },
    {
        title: 'Visual Effect (VFX)',
        options: [
            { label: 'Visual Effects Supervisor', value: 'visual_effects_supervisor' },
            { label: 'Compositor', value: 'compositor' },
            { label: 'Roto/Paint Artist', value: 'roto_paint_artist' },
            { label: 'Matte Painter', value: 'matte_painter' }
        ]
    },
    {
        title: 'Keycast',
        options: [
            { label: 'Keycast', value: 'key_cast' }
        ]
    }
];


export const workDocCredits = [
    { label: 'Author', value: 'author' },
    { label: 'Co-author', value: 'co_author' },
    { label: 'Editor', value: 'editor' },
    { label: 'Copyeditor', value: 'copyeditor' },
    { label: 'Proofreader', value: 'proofreader' },
    { label: 'Translator', value: 'translator' },
    { label: 'Illustrator', value: 'illustrator' },
    { label: 'Photographer', value: 'photographer' },
    { label: 'Graphic Designer', value: 'graphic_designer' },
    { label: 'Typesetter', value: 'typesetter' },
    { label: 'Printer', value: 'printer' },
    { label: 'Publisher', value: 'publisher' },
    { label: 'Contributor', value: 'contributor' }
];

export const workPhotosCredits = [
    { label: 'Photographer', value: 'photographer' },
    { label: 'Model', value: 'model' },
    { label: 'Artist/Illustrator', value: 'artist_illustrator' },
    { label: 'Designer', value: 'designer' },
    { label: 'Director or Art Director', value: 'director_art_director' },
    { label: 'Stylist', value: 'stylist' },
    { label: 'Makeup Artist', value: 'makeup_artist' },
    { label: 'Hair Stylist', value: 'hair_stylist' },
    { label: 'Wardrobe Stylist', value: 'wardrobe_stylist' },
    { label: 'Set Designer', value: 'set_designer' },
    { label: 'Lighting Technician', value: 'lighting_technician' },
    { label: 'Assistant Photographer', value: 'assistant_photographer' },
    { label: 'Retoucher or Image Editor', value: 'retoucher_image_editor' },
    { label: 'Publisher or Distributor', value: 'publisher_distributor' },
    { label: 'Client or Commissioning Entity', value: 'client_commissioning_entity' },
    { label: 'Location Scout', value: 'location_scout' },
    { label: 'Production Coordinator', value: 'production_coordinator' },
    { label: 'Casting Director', value: 'casting_director' },
    { label: 'Art Department Assistant', value: 'art_department_assistant' },
    { label: 'Marketing Team', value: 'marketing_team' }
];


export const workPublication = [
    { label: 'Release', value: 'release' },
    { label: 'Publication', value: 'publication' },
    { label: 'Distribution', value: 'distribution' },
    { label: 'Broadcast', value: 'broadcast' },
    { label: 'Internet', value: 'internet' },
    { label: 'Pre-Release', value: 'pre_release' },
    { label: 'Theatrical distribution', value: 'theatrical_distribution' },
    { label: 'Non-theatrical distribution', value: 'non_theatrical_distribution' },
    { label: 'Not for release', value: 'not_for_release' },
    { label: 'Home Video Publication', value: 'home_video_publication' },
    { label: 'Unknown', value: 'unknown' },
];

export const workProdcution = [
    { label: 'Casting', value: 'casting' },
    { label: 'Outdoor Shooting', value: 'outdoor_shooting' },
    { label: 'Indoor Shooting', value: 'indoor_shooting' },
    { label: 'Post-Production', value: 'post_production' },
];

