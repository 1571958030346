
import CatalogueLayout from "../features/catalogue/CatalogueLayout"
import Heading from "../ui/Heading";
import Row from "../ui/Row";
import { useTranslation } from 'react-i18next';
function Catalogue() {
    const { t } = useTranslation();
    return (
        <>
            <Row type="horizontal">
                <Heading as="h1">{t('titles.catalogue')}</Heading>
            </Row>
            <CatalogueLayout />
        </>
    );
}

export default Catalogue;
