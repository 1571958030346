import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { Typography, CircularProgress } from '@mui/material';
import { fetchUserStats } from '../../services/apiUser';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import Heading from '../../ui/Heading';
import { useTranslation } from 'react-i18next';
const ChartBox = styled.div`
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);
  display: flex;
  padding: 20px;
  justify-content: space-around;
  padding: 2.4rem 3.2rem;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  box-shadow: var(--shadow-md);

  & > *:first-child {
    margin-bottom: 1.6rem;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #333;
  min-width: 350px;
  width: 100%;
`;

const initializeRoles = (data) => {
    const allRoles = ["general_manager", "manager", "entry_personnel", "viewer"];
    const roleCounts = allRoles.map(role => ({
        user_role: role,
        count: 0
    }));

    data.forEach(item => {
        const roleIndex = roleCounts.findIndex(role => role.user_role === item.user_role);
        if (roleIndex !== -1) {
            roleCounts[roleIndex].count = item.count;
        }
    });

    return roleCounts;
};

function UserStatsChart() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [chartKey, setChartKey] = useState(0);

    useEffect(() => {
        const loadUserStats = async () => {
            try {
                const stats = await fetchUserStats();
                const initializedData = initializeRoles(stats);
                setData(initializedData);
                setChartKey(prevKey => prevKey + 1);
            } catch (error) {
                setError('Failed to load user statistics.');
                toast.error('Failed to load user statistics.');
            } finally {
                setLoading(false);
            }
        };
        loadUserStats();
    }, []);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <>
            <Heading as="h3">{t('dashboard.user_stat_chart')}</Heading>
            <ChartBox>
                <ChartContainer>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart key={chartKey} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="user_role" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" fill="#F5782B" animationBegin={0} animationDuration={1000} animationEasing="ease-out" />
                        </BarChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </ChartBox>
        </>
    );
}

export default UserStatsChart;
