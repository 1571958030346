import styled from "styled-components";
import NotificationsList from "./NotificationsList";
import { useUser } from "../authentication/useUser";
const StyledNotificationLayout = styled.main`
display: grid;
grid-template-rows: auto auto;
gap: 1.6rem;
width: 100%;
`;


function NotificationLayout() {
    const { user } = useUser();
    const userId = user?.user.user_id;
    return (
        <>
            <StyledNotificationLayout>
                <NotificationsList userId={userId} />
            </StyledNotificationLayout>
        </>
    );
}

export default NotificationLayout;
