import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

// UI Components
import Heading from '../../../ui/Heading';
import Form from '../../../ui/Form';
import FormGroup from '../../../ui/FormGroup';
import Button from '../../../ui/Button';
import CustomModal from '../../../ui/CustomModal';
import Warning from '../../../ui/Warning';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';

// Form Sections
import WorkTitles from './WorkTitles';
import WorkDateEntries from './WorkDateEntries';
import WorkEvents from './WorkEvents';
import WorkGeneralFields from './WorkGeneralFields';
import WorkLocalization from './WorkLocalization';
import WorkContent from './WorkContent';
import WorkAgents from './WorkAgents';

// Data
import { workDateTypes } from '../../../data/workData/workData';
import countries from 'i18n-iso-countries';
import { fetchWorkById, updateWork } from '../../../services/apiWork';
import { useUser } from '../../authentication/useUser';
import { getCountryOptions, getLanguageOptions } from '../../../utils/countries';

import 'react-datepicker/dist/react-datepicker.css';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function EditWorkForm() {
    const navigate = useNavigate();
    const { workId } = useParams();
    const countryOptions = getCountryOptions();
    const languageOptions = getLanguageOptions();
    const methods = useForm();
    const { user } = useUser();
    const userId = user.user.user_id;

    const [workType, setWorkType] = useState('');
    const [identifiers, setIdentifiers] = useState([{ id: '', type: '' }]);
    const [cast, setCast] = useState([{ id: uuidv4(), firstName: '', middleName: '', lastName: '', characterName: '' }]);
    const [agents, setAgents] = useState([]);
    const [events, setEvents] = useState([]);
    const [workContentType, setWorkContentType] = useState('');
    const [workContent, setWorkContent] = useState('');
    const [workGenre, setWorkGenre] = useState([]);
    const [workForm, setWorkForm] = useState([]);
    const [workSubjects, setWorkSubjects] = useState('');
    const [workCountry, setWorkCountry] = useState([]);
    const [workOriginalLanguage, setWorkOriginalLanguage] = useState('');
    const [workLanguages, setWorkLanguages] = useState([]);
    const [dateEntries, setDateEntries] = useState([{
        id: uuidv4(),
        startDate: new Date(),
        endDate: new Date(),
        startDatePrecision: '',
        endDatePrecision: '',
        dateType: '',
    }]);
    const [eventTypes, setEventTypes] = useState({
        publication: false,
        awardNomination: false,
        production: false,
        rightsRegistration: false,
    });

    const [publicationEvents, setPublicationEvents] = useState([]);
    const [awardEvents, setAwardEvents] = useState([]);
    const [productionEvents, setProductionEvents] = useState([]);
    const [registrationEvents, setRegistrationEvents] = useState([]);
    const [isManifestation, setIsManifestation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const queryClient = useQueryClient();
    const isDataLoaded = useRef(false);

    const mapDateFieldsToEntries = (data) => {
        const dateFields = [
            'publication',
            'awardornomination',
            'production',
            'rightsoriprregisteration'
        ];

        return dateFields.reduce((acc, field) => {
            const startDate = data[`work_date_start_${field}`];
            const endDate = data[`work_date_end_${field}`];
            const startDatePrecision = data[`work_date_precision_start_${field}`];
            const endDatePrecision = data[`work_date_precision_end_${field}`];

            if (startDate || endDate || startDatePrecision || endDatePrecision) {
                acc.push({
                    id: uuidv4(),
                    startDate: startDate || '',
                    endDate: endDate || '',
                    startDatePrecision: startDatePrecision || '',
                    endDatePrecision: endDatePrecision || '',
                    dateType: field,
                });
            }
            return acc;
        }, []);
    };

    const mapEventsToState = (events) => {
        const publication = [];
        const award = [];
        const production = [];
        const registration = [];
        if (events.length > 0) {
            events[0].event_publication?.forEach(event => publication.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
                region: Array.isArray(event.region) ? event.region[0] : event.region
            }));
            events[0].event_award?.forEach(event => award.push({ ...event, id: uuidv4(), date: new Date(event.date) }));
            events[0].event_production?.forEach(event => production.push({ ...event, id: uuidv4(), date: new Date(event.date) }));
            events[0].event_registration?.forEach(event => registration.push({ ...event, id: uuidv4(), date: new Date(event.date) }));
        }
        return {
            publication,
            award,
            production,
            registration,
        };
    };

    const { data: workData, isLoading: isFetching } = useQuery(['work', workId], () => fetchWorkById(workId), {
        onSuccess: (data) => {
            if (!isDataLoaded.current) {
                const {
                    work_type,
                    work_nfaid,
                    work_identifiers,
                    work_agents,
                    work_events,
                    work_content_type,
                    work_content,
                    work_genres,
                    work_forms,
                    work_subjects,
                    work_country,
                    work_original_language,
                    work_languages,
                } = data;
                const { publication, award, production, registration } = mapEventsToState(work_events || []);
                methods.reset(data);
                setWorkType(work_type || '');
                setIdentifiers(work_identifiers || []);
                setAgents(work_agents.map(agent => ({
                    ...agent,
                    id: uuidv4(),
                    firstName: agent.agent_first_name,
                    middleName: agent.agent_middle_name,
                    lastName: agent.agent_last_name,
                    characterName: agent.agent_character_name,
                    title: agent.agent_title,
                })) || []);
                setPublicationEvents(publication);
                setAwardEvents(award);
                setProductionEvents(production);
                setRegistrationEvents(registration);
                setEventTypes({
                    publication: publication.length > 0,
                    awardNomination: award.length > 0,
                    production: production.length > 0,
                    rightsRegistration: registration.length > 0,
                });
                setWorkContentType(work_content_type || '');
                setWorkContent(work_content || '');
                setWorkGenre(work_genres || []);
                setWorkForm(work_forms || []);
                setWorkSubjects(work_subjects || '');
                setWorkCountry(work_country || []);
                setWorkOriginalLanguage(work_original_language || '');
                setWorkLanguages(work_languages || []);
                setDateEntries(mapDateFieldsToEntries(data));

                isDataLoaded.current = true; // Set the flag to true after initial data load
            }
        },
        onError: (err) => toast.error('Error fetching work data: ' + err.message),
    });

    const { mutate, isLoading: isUpdating } = useMutation({
        mutationFn: updateWork,
        onSuccess: () => {
            toast.success('Work updated successfully');
            queryClient.invalidateQueries(['work', workId]);
            navigate(-1);
        },
        onError: (err) => toast.error('Error updating work: ' + err.message),
    });

    const handleSaveDraft = () => {
        const formData = {
            ...methods.getValues(),
            workType,
            identifiers,
            cast,
            agents,
            publicationEvents,
            awardEvents,
            productionEvents,
            registrationEvents,
            workContentType,
            workContent,
            workGenre,
            workForm,
            workSubjects,
            workCountry,
            workOriginalLanguage,
            workLanguages,
            dateEntries: dateEntries.map(entry => ({
                ...entry,
                startDate: entry.startDate,
                endDate: entry.endDate,
            })),
            eventTypes,
            isManifestation,
        };

        const serializedData = JSON.stringify(formData);
        localStorage.setItem('editWorkDraft', serializedData);
        toast.success('Draft saved successfully!');
    };

    const loadDraft = () => {
        const storedData = localStorage.getItem('editWorkDraft');
        if (storedData) {
            const draftData = JSON.parse(storedData);

            methods.reset(draftData);
            setWorkType(draftData.workType);
            setIdentifiers(draftData.identifiers);
            setCast(draftData.cast);
            setAgents(draftData.agents);
            setPublicationEvents(draftData.publicationEvents);
            setAwardEvents(draftData.awardEvents);
            setProductionEvents(draftData.productionEvents);
            setRegistrationEvents(draftData.registrationEvents);
            setWorkContentType(draftData.workContentType);
            setWorkContent(draftData.workContent);
            setWorkGenre(draftData.workGenre);
            setWorkForm(draftData.workForm);
            setWorkSubjects(draftData.workSubjects);
            setWorkCountry(draftData.workCountry);
            setWorkOriginalLanguage(draftData.workOriginalLanguage);
            setWorkLanguages(draftData.workLanguages);
            setDateEntries(draftData.dateEntries.map(entry => ({
                ...entry,
                id: uuidv4(),
                startDate: new Date(entry.startDate),
                endDate: new Date(entry.endDate),
            })));
            setEventTypes(draftData.eventTypes);
            setIsManifestation(draftData.isManifestation);
        }
    };

    useEffect(() => {
        loadDraft();
    }, []);

    const getDateFieldNames = (dateType) => {
        const typeName = dateType.replace(/_/g, '');
        return {
            startDateField: `work_date_start_${typeName}`,
            endDateField: `work_date_end_${typeName}`,
            startPrecisionField: `work_date_precision_start_${typeName}`,
            endPrecisionField: `work_date_precision_end_${typeName}`,
        };
    };

    const handleConfirmSubmission = () => {
        setIsModalOpen(false);

        const data = methods.getValues();
        const formatDate = (date) => date ? new Date(date).toISOString().split('T')[0] : '';

        const workData = {
            ...data,
            user_id: userId,
            work_country: workCountry,
            work_original_language: workOriginalLanguage,
            work_languages: workLanguages,
            work_content_type: workContentType,
            work_genres: workGenre,
            work_forms: workForm,
            work_identifiers: data.work_identifiers,
            agents: agents?.map(agent => ({
                agent_title: agent.title,
                agent_first_name: agent.firstName,
                agent_middle_name: agent.middleName,
                agent_last_name: agent.lastName,
                agent_character_name: agent.characterName,
            })),
            events: {
                publication: publicationEvents.map(event => ({ ...event, date: formatDate(event.date) })),
                awardNomination: awardEvents.map(event => ({ ...event, date: formatDate(event.date) })),
                production: productionEvents.map(event => ({ ...event, date: formatDate(event.date) })),
                rightsRegistration: registrationEvents.map(event => ({ ...event, date: formatDate(event.date) })),
            },
        };

        for (let type of workDateTypes.map(type => type.value)) {
            const { startDateField, endDateField, startPrecisionField, endPrecisionField } = getDateFieldNames(type);
            workData[startDateField] = '';
            workData[endDateField] = '';
            workData[startPrecisionField] = '';
            workData[endPrecisionField] = '';
        }

        for (let entry of dateEntries) {
            const { startDateField, endDateField, startPrecisionField, endPrecisionField } = getDateFieldNames(entry.dateType);
            workData[startDateField] = entry.startDate ? formatDate(entry.startDate) : '';
            workData[endDateField] = entry.endDate ? formatDate(entry.endDate) : '';
            workData[startPrecisionField] = entry.startDatePrecision;
            workData[endPrecisionField] = entry.endDatePrecision;
        }
        try {
            JSON.stringify(workData);
        } catch (error) {
            return;
        }

        mutate({ workId, workData });
    };

    const onSubmit = (data, event) => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    const onError = (errors) => {
    };

    if (isFetching) return <SpinnerOverlay />;

    return (
        <FormProvider {...methods}>
            <Heading as="h3">Edit Work</Heading>
            <Form type="tertiary" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <FormGroup>
                    <WorkGeneralFields
                        workType={workType}
                        setWorkType={setWorkType}
                        errors={methods.formState.errors}
                        identifiers={identifiers}
                        setIdentifiers={setIdentifiers}
                    />
                </FormGroup>
                <FormGroup>
                    <WorkTitles category={workData.category} isCreating={isUpdating} errors={methods.formState.errors} />
                </FormGroup>
                <FormGroup>
                    <WorkDateEntries dateEntries={dateEntries} setDateEntries={setDateEntries} errors={methods.formState.errors} />
                </FormGroup>
                {workData.category !== 'photos_and_images' && (
                    <FormGroup>
                        <WorkLocalization
                            category={workData.category}
                            workCountry={workCountry}
                            setWorkCountry={setWorkCountry}
                            workOriginalLanguage={workOriginalLanguage}
                            setWorkOriginalLanguage={setWorkOriginalLanguage}
                            workLanguages={workLanguages}
                            setWorkLanguages={setWorkLanguages}
                            countryOptions={countryOptions}
                            languageOptions={languageOptions}
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <WorkContent
                        workContentType={workContentType}
                        setWorkContentType={setWorkContentType}
                        workContent={workContent}
                        setWorkContent={setWorkContent}
                        workGenre={workGenre}
                        setWorkGenre={setWorkGenre}
                        workForm={workForm}
                        setWorkForm={setWorkForm}
                        workSubjects={workSubjects}
                        setWorkSubjects={setWorkSubjects}
                        category={workData.category}
                    />
                </FormGroup>

                <FormGroup>
                    {!isFetching && agents.length > 0 && (
                        <WorkAgents
                            category={workData.category}
                            agents={agents}
                            setAgents={setAgents}
                            errors={methods.formState.errors}
                        />
                    )}
                </FormGroup>
                <FormGroup>
                    <WorkEvents
                        eventTypes={eventTypes}
                        toggleEventType={(type) => setEventTypes(prevState => ({ ...prevState, [type]: !prevState[type] }))}
                        publicationEvents={publicationEvents}
                        setPublicationEvents={setPublicationEvents}
                        awardEvents={awardEvents}
                        setAwardEvents={setAwardEvents}
                        productionEvents={productionEvents}
                        setProductionEvents={setProductionEvents}
                        registrationEvents={registrationEvents}
                        setRegistrationEvents={setRegistrationEvents}
                        countryOptions={countryOptions}
                    />
                </FormGroup>

                <Button variation="primary" type="submit" disabled={isUpdating}>Update</Button>
                {Object.keys(methods.formState.errors).length > 0 && (
                    <Warning message="A mandatory field must be filled" color="red" />
                )}
            </Form>
            {isUpdating && <SpinnerOverlay />}
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmSubmission}
                confirmationMessage="Are you sure you want to update the work?"
            />
        </FormProvider>
    );
}

export default EditWorkForm;
