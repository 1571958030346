import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import Heading from '../../../ui/Heading';
import { StyledTextField } from '../../../ui/StyledComponents';

const ManifestationTitles = ({
    isCreating, errors, category
}) => {
    const { register } = useFormContext();

    return (
        <>
            <FormRow error={errors.manifest_title_proper?.message}>
                <Heading as="h5">Proper manifestation Title</Heading>
                <StyledTextField
                    type="text"
                    id="manifest_title_proper"
                    {...register("manifest_title_proper", { required: "Please enter manifestation title" })}
                    disabled={isCreating}
                />
            </FormRow>
            <FormRow error={errors.manifest_title_alternative?.message}>
                <Heading as="h5">Alternative manifestation Title</Heading>
                <StyledTextField
                    type="text"
                    id="manifest_title_alternative"
                    {...register("manifest_title_alternative")}
                    disabled={isCreating}
                />
            </FormRow>
            <FormRow error={errors.manifest_title_supplied?.message}>
                <Heading as="h5">Supplied/Devised manifestation Title</Heading>
                <StyledTextField
                    type="text"
                    id="manifest_title_supplied"
                    {...register("manifest_title_supplied")}
                    disabled={isCreating}
                />
            </FormRow>
            {category == 'photos_and_images' && (
                <FormRow error={errors.manifest_title_caption?.message}>
                    <Heading as="h5">Caption Manifestation Title</Heading>
                    <StyledTextField
                        type="text"
                        id="manifest_title_caption"
                        {...register("manifest_title_caption")}
                        disabled={isCreating}
                    />
                </FormRow>
            )}
            <FormRow error={errors.manifest_title_other?.message}>
                <Heading as="h5">Other Title Information</Heading>
                <StyledTextField
                    type="text"
                    id="manifest_title_other"
                    {...register("manifest_title_other")}
                    disabled={isCreating}
                />
            </FormRow>
        </>
    );
};

export default ManifestationTitles;
