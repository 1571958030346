import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import { StyledTextField } from '../../../ui/StyledComponents';
import DatePickerWrapper from '../../../ui/DatePickerWrapper';
import DatePickerInput from '../../../ui/DatePickerInput';
import "react-datepicker/dist/react-datepicker.css";
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
import { itemInspectionTypes, itemPreservationTypes, itemAcquisitionTypes } from "../../../data/itemData/itemData";
import Heading from '../../../ui/Heading';
import { v4 as uuidv4 } from 'uuid';
const ItemEvents = ({
    eventTypes,
    toggleEventType,
    inspectionEvents,
    setInspectionEvents,
    licensingEvents,
    setLicensingEvents,
    preservationEvents,
    setPreservationEvents,
    acquisitionEvents,
    setAcquisitionEvents,
}) => {


    const addInspectionEvent = (event) => {
        event.preventDefault();
        setInspectionEvents([...inspectionEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            details: ''
        }]);
    };

    const removeInspectionEvent = (index) => {
        setInspectionEvents(inspectionEvents.filter((_, i) => i !== index));
    };

    const handleInspectionChange = (index, field, value) => {
        const updatedEvents = [...inspectionEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setInspectionEvents(updatedEvents);
    };

    const addAcquisitionEvents = (event) => {
        event.preventDefault();
        setAcquisitionEvents([...acquisitionEvents, {
            id: uuidv4(),
            date: new Date(),
            type: '',
            source: '',
            details: '',
        }]);
    };

    const removeAcquisitionEvents = (index) => {
        setAcquisitionEvents(acquisitionEvents.filter((_, i) => i !== index));
    };

    const handleAcquisitionChange = (index, field, value) => {
        const updatedEvents = [...acquisitionEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setAcquisitionEvents(updatedEvents);
    };

    const addLicensingEvent = (event) => {
        event.preventDefault();
        setLicensingEvents([...licensingEvents, {
            id: uuidv4(),
            notes: '',
        }]);
    };

    const removeLicensingEvent = (index) => {
        setLicensingEvents(licensingEvents.filter((_, i) => i !== index));
    };

    const handleLicensingChange = (index, field, value) => {
        const updatedEvents = [...licensingEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setLicensingEvents(updatedEvents);
    };

    const addPreservationEvent = (event) => {
        event.preventDefault();
        setPreservationEvents([...preservationEvents, {
            id: uuidv4(),
            type: '',
            date: new Date(),
            notes: '',
        }]);
    };

    const removePreservationEvent = (index) => {
        setPreservationEvents(preservationEvents.filter((_, i) => i !== index));
    };

    const handlePreservationChange = (index, field, value) => {
        const updatedEvents = [...preservationEvents];
        updatedEvents[index] = { ...updatedEvents[index], [field]: value };
        setPreservationEvents(updatedEvents);
    };




    return (
        <div >
            <FormRow>
                <Heading as="h5">Inspection</Heading>
                <input type="checkbox" checked={eventTypes.inspection} onChange={() => toggleEventType('inspection')} />
            </FormRow>
            {eventTypes.inspection && (
                <>
                    {inspectionEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Inspection Type</Heading>
                                <DropDown
                                    id="inspection_type"
                                    options={itemInspectionTypes}
                                    onSelect={option => handleInspectionChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose Inspection Type"}

                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Inspection Date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleInspectionChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown

                                />
                            </FormRow>
                            <FormRow >
                                <Heading as="h5">Inspection Details</Heading>
                                <StyledTextField
                                    type="text"
                                    value={event.details}
                                    onChange={e => handleInspectionChange(index, 'details', e.target.value)}
                                    placeholder="Enter Inspection Details"
                                    multiline
                                />
                            </FormRow>
                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeInspectionEvent(index)}
                                label={"Remove Inspection Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addInspectionEvent}
                        label={"Add Inspection Event"}
                    />
                </>

            )}
            <FormRow>
                <Heading as="h5">Licensing</Heading>
                <input type="checkbox" checked={eventTypes.licensing} onChange={() => toggleEventType('licensing')} />
            </FormRow>

            {eventTypes.licensing && (
                <>
                    {licensingEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <StyledTextField
                                    id="licensing"
                                    value={event.notes}
                                    onChange={e => handleLicensingChange(index, 'notes', e.target.value)}
                                    placeholder="Enter Licensing Information"
                                    multiline
                                />

                            </FormRow>


                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeLicensingEvent(index)}
                                label={"Remove Licensing Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addLicensingEvent}
                        label={"Add Licensing Event"}
                    />
                </>

            )}

            <FormRow >
                <Heading as="h5">Preservation</Heading>
                <input type="checkbox" checked={eventTypes.preservation} onChange={() => toggleEventType('preservation')} />
            </FormRow>
            {eventTypes.preservation && (
                <>
                    {preservationEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Preservation Type</Heading>
                                <DropDown
                                    id="preservation_type"
                                    options={itemPreservationTypes}
                                    onSelect={option => handlePreservationChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder={"Choose Preservation Type"}
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Preservation Date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handlePreservationChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    customInput={<DatePickerInput />}
                                />
                            </FormRow>
                            <FormRow >
                                <Heading as="h5">Preservation Notes</Heading>
                                <StyledTextField
                                    id="preservation_notes"
                                    value={event.notes}
                                    onChange={e => handlePreservationChange(index, 'notes', e.target.value)}
                                    placeholder="Enter Preservation Notes"
                                    multiline
                                />
                            </FormRow>

                            <TextButtonRemove
                                handleRemoveIdentifier={() => removePreservationEvent(index)}
                                label={"Remove Preservation Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addPreservationEvent}
                        label={"Add Preservation Event"}
                    />


                </>
            )}
            <FormRow >
                <Heading as="h5">Acquisition</Heading>
                <input type="checkbox" checked={eventTypes.acquisition} onChange={() => toggleEventType('acquisition')} />
            </FormRow>
            {eventTypes.acquisition && (
                <>
                    {acquisitionEvents.map((event, index) => (
                        <React.Fragment key={event.id}>
                            <FormRow>
                                <Heading as="h5">Acquisition Type</Heading>
                                <DropDown
                                    id="acquisition_type"
                                    options={itemAcquisitionTypes}
                                    onSelect={option => handleAcquisitionChange(index, 'type', option.value)}
                                    value={event.type}
                                    placeholder="Choose Acquisition Type"
                                />
                            </FormRow>
                            <FormRow >
                                <Heading as="h5">Acquisition Source</Heading>
                                <StyledTextField
                                    type="text"
                                    value={event.source}
                                    onChange={e => handleAcquisitionChange(index, 'source', e.target.value)}
                                    placeholder="Enter Acquisition Source"
                                />
                            </FormRow>
                            <FormRow>
                                <Heading as="h5">Acquisition Date</Heading>
                                <DatePickerWrapper
                                    selected={event.date}
                                    onChange={date => handleAcquisitionChange(index, 'date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    customInput={<DatePickerInput />}
                                />
                            </FormRow>
                            <FormRow >
                                <Heading as="h5">Acquisition Details</Heading>
                                <StyledTextField
                                    type="text"
                                    value={event.details}
                                    onChange={e => handleAcquisitionChange(index, 'details', e.target.value)}
                                    placeholder="Enter Acquisition Details"
                                    multiline
                                />
                            </FormRow>

                            <TextButtonRemove
                                handleRemoveIdentifier={() => removeAcquisitionEvents(index)}
                                label={"Remove Acquisition Event"}
                            />
                        </React.Fragment>
                    ))}
                    <TextButtonAdd
                        handleAddIdentifier={addAcquisitionEvents}
                        label={"Add Acquisition Event"}
                    />
                </>
            )}
        </div>
    );
};

export default ItemEvents;
