
import axios from './axiosConfig';


export async function login({ user_email, user_pw }) {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { user_email, user_pw });
    if (result.data.message === '2FA code sent to your email.') {
      return result.data;
    } else if (result.data.token) {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('role', result.data.user.user_role);
      return result.data;
    } else {
      throw new Error('Login failed.');
    }
  } catch (err) {
    handleError(err);
  }
}

export async function verify2fa({ user_email, code }) {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-2fa`, { user_email, code });
    if (result.data.token) {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('role', result.data.user.user_role);
      return result.data;
    } else {
      throw new Error('2FA verification failed.');
    }
  } catch (err) {
    handleError(err);
  }
}
function handleError(error) {
  if (error.response) {
    throw new Error(error.response.data.message || 'Server error');
  } else if (error.request) {
    throw new Error('No response from server. Please try again later.');
  } else {
    throw new Error('Error: ' + error.message);
  }
}

export async function getCurrentUser() {
  const token = localStorage.getItem('token');
  if (!token) {
    return { isAuthenticated: false };
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { user: response.data.user, isAuthenticated: response.data.isAuthenticated };
  } catch (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      return { isAuthenticated: false };
    }
    return { isAuthenticated: false };
  }
}

export function isTokenExpired(token) {
  const payloadBase64 = token.split('.')[1];
  const decodedJson = atob(payloadBase64);
  const decoded = JSON.parse(decodedJson);
  const exp = decoded.exp * 1000;
  return Date.now() > exp;
}


export async function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  return axios.post(`${process.env.REACT_APP_API_URL}/logout`)
    .catch(err => console.error('Logout failed:', err));
}

