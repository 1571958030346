import axios from './axiosConfig';

export async function createManifestation(manifestationData) {
    try {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add-manifest`, manifestationData);

        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchManifestations(userId, role) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/manifestations?userId=${userId}&role=${role}`);
        const manifestations = response.data;

        for (const manifestation of manifestations) {
            const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/manifestations/${manifestation.manifest_id}/category`);
            manifestation.category = categoryResponse.data.category;
        }

        return manifestations;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchManifestationById(manifestId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/manifestations/${manifestId}`);
        const manifestation = response.data;
        const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/manifestations/${manifestation.manifest_id}/category`);
        manifestation.category = categoryResponse.data.category;
        return manifestation;
    } catch (error) {
        handleApiError(error);
    }
}

export async function updateManifestation({ manifestId, formData }) {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/manifestations/${manifestId}`, formData);
        return response.data;
    } catch (error) {
        console.error("Error updating manifestation:", error);
        handleApiError(error);
    }
}
export async function deleteManifestation(manifestId) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/manifestations/${manifestId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

function handleApiError(error) {
    if (error.response) {
        const message = `HTTP Error: ${error.response.status} ${error.response.data}`;
        console.error("API HTTP error:", message);
        alert(message);
    } else if (error.request) {
        console.error("API no response error:", error.request);
        alert('No response from server. Please check your network connection.');
    } else {
        console.error("API error:", error.message);
        alert('Error processing request. Please try again later.');
    }
    throw error;
}
