import { useEffect } from 'react';
import { useQuery } from "@tanstack/react-query";
import { getCurrentUser, isTokenExpired } from "../../services/apiAuth";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export function useUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, data: user, error } = useQuery({
    queryKey: ["user"],
    queryFn: getCurrentUser,
    retry: false
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (token && isTokenExpired(token)) {
        toast.error(t('login.expired'))
        localStorage.clear();
        navigate("/login", { replace: true });
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [navigate]);

  if (error) {
    localStorage.clear();
    navigate("/login", { replace: true });

  }



  return { isLoading, user, isAuthenticated: user?.isAuthenticated };
}
