import styled, { css } from "styled-components";


const Heading = styled.h1`
  ${(props) =>
    props.as === "h1" &&
    css`
      font-size: 26px;
      font-weight: 600;
      color: ${props => props.color};
    `}
  ${(props) =>
    props.as === "h2" &&
    css`
      font-size: 18px;
      font-weight: 600;
      color: ${props => props.color};
    `}
    ${(props) =>
    props.as === "h3" &&
    css`
        font-size: 20px;
        font-weight: 600;
        color: ${props => props.color};
      `}
  ${(props) =>
    props.as === "h4" &&
    css`
      font-size: 23px;
      font-weight: 600;
      text-align: center;
      color: ${props => props.color};
    `}
    ${(props) =>
    props.as === "h5" &&
    css`
        font-size: 15px;
        font-weight: 600;
        color: var(--color-grey-600);
      `}
      ${(props) =>
    props.as === "h6" &&
    css`
            font-size: 13px;
            font-weight: 600;
            color: ${props => props.color};
          `}
      ${(props) =>
    props.as === "h7" &&
    css`
            font-size: 13px;
            font-weight: 600;
            color: var(--color-grey-600);
          `}
    ${(props) =>
    props.as === "h8" &&
    css`
                    font-size: 13px;
                    font-weight: 400;
                    color: var(--color-grey-600);
                  `}
  margin: 10px;
`;


export default Heading;
