import axios from './axiosConfig';

export async function createItem(itemData) {
    try {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add-item`, itemData);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchItems(userId, role) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/items?userId=${userId}&role=${role}`);
        const items = response.data;

        for (const item of items) {
            const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/items/${item.item_id}/category`);
            item.category = categoryResponse.data.category;
        }

        return items;
    } catch (error) {
        handleApiError(error);
    }
}

export async function fetchItemDetails(itemId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/items/${itemId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}


export async function fetchItemById(itemId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/items/${itemId}`);
        const item = response.data;
        const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/items/${item.item_id}/category`);
        item.category = categoryResponse.data.category;
        return item
    } catch (error) {
        handleApiError(error);
    }
}

export async function updateItem(params) {
    try {
        const { itemId, itemData } = params;
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/items/${itemId}`, itemData);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}


export async function deleteItem(itemId) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/items/${itemId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
}

function handleApiError(error) {
    if (error.response) {
        const message = `HTTP Error: ${error.response.status} ${error.response.data}`;
        alert(message);
    } else if (error.request) {
        alert('No response from server. Please check your network connection.');
    } else {
        alert('Error processing request. Please try again later.');
    }
    throw error;
}
