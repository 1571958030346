import React, { useEffect, useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { useDarkMode } from '../context/DarkModeContext';
import Button from './Button';
import { format, parseISO, isValid, parse } from 'date-fns';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Box from '@mui/material/Box';

export default function DatePickerWrapper({ selected, onChange, yearOnly }) {
  const { isDarkMode } = useDarkMode();
  const [date, setDate] = useState(null);
  const [internalYearOnly, setInternalYearOnly] = useState(yearOnly);

  useEffect(() => {
    let parsedDate;
    if (typeof selected === 'string' && selected.length === 4) {
      parsedDate = parse(selected, 'yyyy', new Date());
    } else if (typeof selected === 'string' && selected.length === 10) {
      parsedDate = parseISO(selected);
    } else {
      parsedDate = new Date(selected);
    }
    setDate(isValid(parsedDate) ? parsedDate : new Date());
    setInternalYearOnly(yearOnly);
  }, [selected, yearOnly]);


  const handleToggle = (event) => {
    event.preventDefault();
    setInternalYearOnly(!internalYearOnly);
  };

  const handleChange = (date) => {
    if (!date) return;
    const formattedDate = internalYearOnly ? format(date, 'yyyy') : format(date, 'yyyy-MM-dd');
    setDate(date);
    onChange(formattedDate);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (internalYearOnly && value.length === 4 && /^\d{4}$/.test(value)) {
      const date = new Date();
      date.setFullYear(value, 0, 1);
      handleChange(date);
    }
  };

  const theme = useMemo(() => createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#F5782B',
      },
      secondary: {
        main: '#F5782B',
      },
    },
    components: {
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            backgroundColor: isDarkMode ? 'var(--color-grey-50)' : 'var(--color-grey-50)',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'var(--color-grey-50)',
            },
          },
        },
      },
    },
  }), [isDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <DatePicker
            views={internalYearOnly ? ['year'] : ['year', 'month', 'day']}
            value={date}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Choose the date"
                inputProps={{
                  ...params.inputProps,
                  readOnly: internalYearOnly ? false : true,
                }}
                onChange={handleInputChange}
              />
            )}
            inputFormat={internalYearOnly ? "yyyy" : "yyyy-MM-dd"}
            mask={internalYearOnly ? "____" : "____-__-__"}
          />
          <Button
            type="button"
            onClick={handleToggle}
            variant="contained"
            color="primary"
            startIcon={<SwapHorizIcon />}
            sx={{
              marginTop: 2,
              backgroundColor: '#F5782B',
              color: 'white',
              '&:hover': {
                backgroundColor: '#d4661d',
              },
            }}
          >
            {internalYearOnly ? "Full date" : "Year view"}
          </Button>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
