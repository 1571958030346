
import { useTranslation } from 'react-i18next';
import NotificationLayout from "../features/notification/NotificationLayout";
import Heading from "../ui/Heading";
import Row from "../ui/Row";

function Notification() {
    const { t } = useTranslation();
    return (
        <>
            <Row type="horizontal">
                <Heading as="h1">{t('titles.notification')}</Heading>
            </Row>
            <NotificationLayout />
        </>
    );
}

export default Notification;
