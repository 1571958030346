import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { StyledTextField, StyledIconButton, StyledBox } from './StyledComponents';

const CustomInputWithPrefix = ({ name, label, prefix, rules, GenerateIDComponent }) => {
    const { register, setValue, getValues, formState: { errors } } = useFormContext();
    const [inputValue, setInputValue] = useState(getValues(name) || prefix);
    const [isDisabled, setIsDisabled] = useState(!!getValues(name));

    useEffect(() => {
        setValue(name, inputValue);
    }, [name, setValue, inputValue]);

    const handleChange = (event) => {
        const value = event.target.value;
        const numericPart = value.slice(prefix.length);
        if (value.startsWith(prefix) && /^\d*$/.test(numericPart) && numericPart.length <= 6) {
            setInputValue(value);
            setValue(name, value);
        }
    };

    const handleGenerate = () => {
        const newID = `${prefix}${Math.floor(100000 + Math.random() * 900000)}`;
        setInputValue(newID);
        setValue(name, newID);
        setIsDisabled(true);
    };

    return (
        <StyledBox>
            <StyledTextField
                label={label}
                value={inputValue}
                onChange={handleChange}
                error={!!errors[name]}
                helperText={errors[name] ? errors[name].message : ''}
                fullWidth
                {...register(name, {
                    ...rules,
                    validate: value => {
                        const numericPart = value.slice(prefix.length);
                        return numericPart.length === 6 || "Please enter NFA id";
                    }
                })}
                disabled={isDisabled}
            />
            {GenerateIDComponent && !isDisabled && (
                <StyledIconButton onClick={handleGenerate} aria-label="generate id">
                    <AutorenewIcon />
                </StyledIconButton>
            )}
        </StyledBox>
    );
};

export default CustomInputWithPrefix;
