
import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Button from './Button';

Modal.setAppElement('#root');

const StyledModalContent = styled.div`
 background-color: var(--color-grey-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  p {
    text-align: center;
  }
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
};

const CustomModal = ({ isOpen, onRequestClose, onConfirm, confirmationMessage, children }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
        >
            <StyledModalContent>
                <p>{confirmationMessage}</p>
                <Button onClick={onConfirm}>Confirm</Button>
                <Button variation="secondary" onClick={onRequestClose}>Cancel</Button>
            </StyledModalContent>
        </Modal>
    );
};

export default CustomModal;
