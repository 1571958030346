import React, { useEffect, useState } from 'react';
import { fetchWorks, deleteWork } from '../../../../services/apiWork';
import { fetchUserById } from '../../../../services/apiUser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Heading from '../../../../ui/Heading';
import FormRow from '../../../../ui/FormRow';
import CustomModal from '../../../../ui/CustomModal';
import EditRequestForm from '../../EditRequestForm';
import SpinnerMini from '../../../../ui/SpinnerMini';
import PreviewContent from '../../../../ui/PreviewContent';
import {
    Typography,
    Collapse,
    Box,
    TablePagination,
    Table as MuiTable,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
} from '@mui/material';

import { MdOutlineExpandLess, MdOutlineExpandMore, MdEdit, MdDelete } from "react-icons/md";
import Table from '../../../../ui/Table';
import StyledFilterField from '../../../../ui/StyledFilterField';
import { useUser } from '../../../authentication/useUser';
import { convertLanguageCodesToNames, convertCountryCodesToNames } from '../../../../utils/countries';
import TextButton from '../../../../ui/TextButton';

const StyledNavLink = styled(NavLink)`
    cursor: pointer;
    &:hover {
        background: transparent;
        color: var(--color-brand-600);
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        color: var(--color-grey-400);
        h5 {
            color: var(--color-grey-400);
        }
    }
    &:link,
    &:visited {
        display: flex;
        align-items: center;
        color: var(--color-grey-600);
        transition: all 0.3s;
    }
`;
function WorkTable() {
    const { t } = useTranslation();
    const { user } = useUser();
    const [works, setWorks] = useState([]);
    const [filteredWorks, setFilteredWorks] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, workId: null });
    const [requestEdit, setRequestEdit] = useState({ open: false, workId: null });
    const [userDetails, setUserDetails] = useState({});
    const [editRequestFormOpen, setEditRequestFormOpen] = useState(false);
    const [selectedWorkId, setSelectedWorkId] = useState(null);
    // const [showEditForm, setShowEditForm] = useState(false);
    const userId = user?.user?.user_id;
    const navigate = useNavigate();
    useEffect(() => {
        async function loadWorks() {
            setIsLoading(true);
            try {
                if (user) {
                    const data = await fetchWorks(user.user.user_id, user.user.user_role);
                    setWorks(data);
                    setFilteredWorks(data);
                    setError(null);
                }
            } catch (err) {
                setError('Failed to fetch works');
            } finally {
                setIsLoading(false);
            }
        }
        loadWorks();
    }, [user]);

    useEffect(() => {
        works.forEach(work => {
            if (!userDetails[work.user_id]) {
                getUserDetails(work.user_id);
            }
        });
    }, [works]);

    // useEffect(() => {
    //     if (selectedWorkId != null) {
    //         setEditRequestFormOpen(true);
    //     }
    // }, [selectedWorkId]);

    async function getUserDetails(userId) {
        if (userDetails[userId]) {
            return;
        }
        setIsLoading(true);
        try {
            const userDetailsResponse = await fetchUserById(userId);
            if (userDetailsResponse) {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [userId]: `${userDetailsResponse.user_fname} ${userDetailsResponse.user_lname}`
                }));
            }
            else {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [userId]: 'Unknown User'
                }));
            }
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            setUserDetails(prevDetails => ({
                ...prevDetails,
                [userId]: 'Unknown User'
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const handleFilterChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(searchText);
        const filtered = works.filter(work =>
            work.work_title_identifying.toLowerCase().includes(searchText) ||
            work.work_title_alternative.toLowerCase().includes(searchText) ||
            work.work_title_preferred.toLowerCase().includes(searchText) ||
            work.work_title_other.toLowerCase().includes(searchText) ||
            work.work_type.toLowerCase().includes(searchText) ||
            (work.work_nfaid && work.work_nfaid.toLowerCase().includes(searchText)) ||
            (work.category && work.category.toLowerCase().includes(searchText))
        );
        setFilteredWorks(filtered);
        setPage(0);
    };


    const handleDeleteClick = (workId) => {
        setConfirmDelete({ open: true, workId });
    };

    const handleDelete = async () => {
        if (confirmDelete.workId !== null) {
            setIsLoading(true);
            try {
                await deleteWork(confirmDelete.workId, userId);
                setWorks(works.filter(work => work.work_id !== confirmDelete.workId));
                setFilteredWorks(filteredWorks.filter(work => work.work_id !== confirmDelete.workId));
                setError(null);
            } catch (err) {
                setError('Failed to delete work');
            } finally {
                setIsLoading(false);
                setConfirmDelete({ open: false, workId: null });
            }
        }
    };


    // const handleEditClick = (workId) => {
    //     setSelectedWorkId(workId);
    //     setEditRequestFormOpen(true);
    // };

    // const confirmEdit = () => {
    //     setEditRequestFormOpen(false);
    //     setShowEditForm(true);
    // };

    const handleEdit = (workId) => {
        navigate(`/edit-work/${workId}`);
    };


    const handleExpandClick = (workId) => {
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(workId)) {
            newExpandedRows.delete(workId);
        } else {
            newExpandedRows.add(workId);
        }
        setExpandedRows(newExpandedRows);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return <SpinnerMini />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    const renderEventDetails = (eventType, eventDetails) => {
        switch (eventType) {
            case 'event_publication':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <Heading as='h8'>Publication Event:</Heading>
                        <p>Date: {eventDetail.date}</p>
                        <p>Type: {eventDetail.type}</p>
                        <p>Region: {convertCountryCodesToNames(eventDetail.region)}</p>
                    </div>
                ));
            case 'event_award':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <Heading as='h8'>Award Event:</Heading>
                        <p>Name: {eventDetail.name}</p>
                        <p>Date: {eventDetail.date}</p>
                        <p>Achievement: {eventDetail.achievement}</p>
                        <p>Nomination: {eventDetail.nomination ? 'Yes' : 'No'}</p>
                    </div>
                ));
            case 'event_production':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <Heading as='h8'>Production Event:</Heading>
                        <p>Type: {eventDetail.type}</p>
                        <p>Location: {eventDetail.location}</p>
                        <p>Date: {eventDetail.date}</p>
                        <p>Details: {eventDetail.details}</p>
                    </div>
                ));
            case 'event_registration':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <Heading as='h8'>Registration Event:</Heading>
                        <p>Date: {eventDetail.date}</p>
                        <p>Agency: {eventDetail.agency}</p>
                        <p>Regional Scope: {eventDetail.regionalScope}</p>
                        <p>Applicant: {eventDetail.applicant}</p>
                        <p>Number: {eventDetail.number}</p>
                    </div>
                ));
            default:
                return <Heading as='h8'>No data available</Heading>;
        }
    };


    const renderWorkRow = (work) => (
        <React.Fragment key={work.work_id}>
            <Table.Row>
                <Heading as={"h8"}>
                    {work.work_title_identifying}
                    <br />
                    <Typography style={{ fontSize: '0.8rem' }}>
                        {work.work_nfaid}
                    </Typography>
                    <br />
                    <Typography variant="caption" style={{ display: 'block' }}>
                        {`Entered by : ${userDetails[work.user_id] ? userDetails[work.user_id] : <SpinnerMini />}`}
                    </Typography>

                </Heading>
                <Heading as={"h8"}>{work.category}</Heading>
                <Heading as={"h8"}>{work.work_type}</Heading>
                <Heading as={"h8"}>{work.work_forms?.join(', ')}</Heading>
                <Heading as={"h8"}>{work.work_genres?.join(', ')}</Heading>
                <FormRow>

                    {expandedRows.has(work.work_id) ? <StyledNavLink onClick={() => handleExpandClick(work.work_id)} ><MdOutlineExpandLess /> </StyledNavLink> : <StyledNavLink onClick={() => handleExpandClick(work.work_id)}> <MdOutlineExpandMore /></StyledNavLink>}
                    <StyledNavLink as="div" onClick={() => handleEdit(work.work_id)}><MdEdit /></StyledNavLink>
                    <StyledNavLink onClick={() => handleDeleteClick(work.work_id)}> <MdDelete /></StyledNavLink>
                    <TextButton label="Fiaf card" link={`/work-details/${work.work_id}`}></TextButton>
                </FormRow>
            </Table.Row>
            <Collapse in={expandedRows.has(work.work_id)} timeout="auto" unmountOnExit>
                <Box margin={2}>
                    <TableBody>
                        {Object.entries({
                            'work_table.alternative_title': work.work_title_alternative ? work.work_title_alternative : "No data available",
                            'work_table.preferred_title': work.work_title_preferred ? work.work_title_preferred : "No data available",
                            'work_table.other_title': work.work_title_other ? work.work_title_other : "No data available",
                            ...(work.category === "photos_and_images" ? { 'work_table.caption_title': work.work_title_caption ? work.work_title_caption : "No data available" } : {}),
                            'work_table.content_type': work.work_content_type ? work.work_content_type : "No data available",
                            'work_table.content': work.work_content ? work.work_content : "No data available",
                            'work_table.subjects': work.work_subjects ? work.work_subjects : "No data available",
                            ...(work.category === "audiovisuals" ? { 'work_table.country': work.work_country ? convertCountryCodesToNames(work.work_country).join(', ') : "No data available" } : {}),
                            'work_table.content_type': work.work_content_type ? work.work_content_type : "No data available",
                            ...(work.category !== "photos_and_images" ? { 'work_table.language': work.work_original_language ? convertLanguageCodesToNames(work.work_original_language).join(', ') : "No data available" } : {}),
                            ...(work.category !== "photos_and_images" ? { 'work_table.languages': work.work_languages ? convertLanguageCodesToNames(work.work_languages).join(', ') : "No data available" } : {}),
                            'work_table.publication_date_start': work.work_date_start_publication ? work.work_date_start_publication : "No data available",
                            'work_table.publication_date_start_precision': work.work_date_precision_start_publication ? work.work_date_precision_start_publication : "No data available",
                            'work_table.publication_date_end': work.work_date_end_publication ? work.work_date_end_publication : "No data available",
                            'work_table.publication_date_end_precision': work.work_date_precision_end_publication ? work.work_date_precision_end_publication : "No data available",
                            'work_table.award_date_start': work.work_date_start_awardornomination ? work.work_date_start_awardornomination : "No data available",
                            'work_table.award_date_start_precision': work.work_date_precision_start_awardornomination ? work.work_date_precision_start_awardornomination : "No data available",
                            'work_table.award_date_end': work.work_date_end_awardornomination ? work.work_date_end_awardornomination : "No data available",
                            'work_table.award_date_end_precision': work.work_date_precision_end_awardornomination ? work.work_date_precision_end_awardornomination : "No data available",
                            'work_table.production_date_start': work.work_date_start_production ? work.work_date_start_production : "No data available",
                            'work_table.production_date_start_precision': work.work_date_precision_start_production ? work.work_date_precision_start_production : "No data available",
                            'work_table.production_date_end': work.work_date_end_production ? work.work_date_end_production : "No data available",
                            'work_table.production_date_end_precision': work.work_date_precision_end_production ? work.work_date_precision_end_production : "No data available",
                            'work_table.registration_date_start': work.work_date_start_rightsoriprregisteration ? work.work_date_start_rightsoriprregisteration : "No data available",
                            'work_table.registration_date_start_precision': work.work_date_precision_start_rightsoriprregisteration ? work.work_date_precision_start_rightsoriprregisteration : "No data available",
                            'work_table.registration_date_end': work.work_date_end_rightsoriprregisteration ? work.work_date_end_rightsoriprregisteration : "No data available",
                            'work_table.registration_date_end_precision': work.work_date_precision_end_rightsoriprregisteration ? work.work_date_precision_end_rightsoriprregisteration : "No data available",
                        }).map(([label, value], index) => (
                            <Table.Row key={index}>

                                <Heading as='h7'>
                                    {t(label)}
                                </Heading>
                                <Heading as={'h8'}>
                                    {value}
                                </Heading>
                            </Table.Row>
                        ))}
                    </TableBody>

                    <Table.Accordion
                        summary={t('table.agents')}
                        details={
                            <MuiTable size="small">
                                <TableHead>
                                    <Table.Cell>{t('agent.first_name')}</Table.Cell>
                                    <Table.Cell>{t('agent.middle_name')}</Table.Cell>
                                    <Table.Cell>{t('agent.last_name')}</Table.Cell>
                                    <Table.Cell>{t('agent.title')}</Table.Cell>
                                    <Table.Cell>{t('agent.character_name')}</Table.Cell>
                                </TableHead>
                                <TableBody>
                                    {(work.work_agents || []).map((agent, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell><Heading as={'h8'}>{agent.agent_first_name ? agent.agent_first_name : "No data available"}</Heading></TableCell>
                                            <TableCell><Heading as={'h8'}>{agent.agent_middle_name ? agent.agent_middle_name : "No data available"}</Heading></TableCell>
                                            <TableCell><Heading as={'h8'}>{agent.agent_last_name ? agent.agent_last_name : "No data available"}</Heading></TableCell>
                                            <TableCell><Heading as={'h8'}>{agent.agent_title ? agent.agent_title : "No data available"}</Heading></TableCell>
                                            <TableCell><Heading as={'h8'}>{agent.agent_character_name ? agent.agent_character_name : "No data available"}</Heading></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </MuiTable>
                        }
                    />
                    <Table.Accordion
                        summary={t('table.events')}
                        details={
                            <MuiTable size="small">
                                <TableHead>
                                    <TableCell><Heading as='h7'>{t('event.type')}</Heading></TableCell>
                                    <TableCell ><Heading as='h7'>{t('event.details')}</Heading></TableCell>
                                </TableHead>
                                <TableBody>
                                    {['event_publication', 'event_award', 'event_production', 'event_registration'].map((eventType, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell><Heading as='h7'>{t(eventType)}</Heading></TableCell>
                                            <TableCell>
                                                {work.work_events[0] && work.work_events[0][eventType] && work.work_events[0][eventType].length > 0 ?
                                                    renderEventDetails(eventType, work.work_events[0][eventType])
                                                    : <Heading as='h8'>No data available</Heading>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </MuiTable>
                        }

                    />
                    <Table.Accordion
                        summary={t('table.preview')}
                        details={
                            <PreviewContent category={work.category} />
                        }
                    />
                </Box>
            </Collapse>

        </React.Fragment>
    );

    return (
        <div style={{ width: '100%', marginTop: '1rem' }}>
            <Heading as="h3" > {t('titles.work')}</Heading>
            <StyledFilterField
                label={<Heading as="h6" > {t('table.filter')}</Heading>}
                variant="outlined"
                value={filterText}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                style={{ marginBottom: '1rem' }}
            />
            <Table columns="repeat(7, 1fr)">
                <Table.Header>
                    <Heading as="h6" > {t('work_table.title')}</Heading>
                    <Heading as="h6" > {t('table.category')}</Heading>
                    <Heading as="h6" > {t('work_table.type')}</Heading>
                    <Heading as="h6" > {t('work_table.forms')}</Heading>
                    <Heading as="h6" > {t('work_table.genres')}</Heading>
                    <Heading as="h6" > {t('actions.actions')}</Heading>
                </Table.Header>
                <Table.Body data={filteredWorks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} render={renderWorkRow} />
            </Table>
            <Table.Footer>
                <TablePagination
                    component="div"
                    count={filteredWorks.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Table.Footer>

            {/* <CustomModal
                isOpen={editRequestFormOpen}
                onRequestClose={() => setEditRequestFormOpen(false)}
                title="Request Edit"
                confirmationMessage="Do you want to request an edit for this work?"
                onConfirm={confirmEdit}
            />
            {showEditForm && (
                <EditRequestForm
                    recordId={selectedWorkId}
                    userId={userId}
                    onSuccessfulSubmit={() => {
                        setShowEditForm(false);
                        alert('Edit request submitted successfully.');
                    }}
                />
            )} */}




            <CustomModal
                isOpen={confirmDelete.open}
                onRequestClose={() => setConfirmDelete({ open: false, workId: null })}
                onConfirm={handleDelete}
                confirmationMessage={
                    <>
                        Are you sure you want to delete this work?
                        <br />
                        <span style={{ color: 'var(--color-red-800)', fontWeight: 'bold' }}>This action cannot be undone</span>
                    </>
                }
            />
        </div>
    );
}

export default WorkTable;
