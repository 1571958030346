import styled from "styled-components";
import React from "react";
import { IoIosTime, IoMdClose } from "react-icons/io";

const StyledFormRow = styled.div`
  border: 1px solid var(--color-grey-200);
   background-color: ${({ isNew }) => isNew ? 'var(--color-light-blue)' : 'var(--color-grey-0)'};
  border-radius: 8px;
  display: grid;
  align-items: center;
  gap: 1.2rem;
  padding: 1.5rem 2rem; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.2rem;
  grid-template-columns: auto max-content 1fr auto auto auto auto;
  grid-template-areas: "recency label message accept decline time delete"; 
  transition: background-color 0.3s ease;
`;

const RecencyLabel = styled.label`
  font-weight: bold; 
  background-color: var(--color-green-100); 
  color:var(--color-grey-600);
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  font-size: 1rem;
  text-align: center;
  grid-area: recency;
  width: 50px;
  margin-right: 10px;
`;

const Label = styled.label`
  font-weight: 500; 
  background-color: var(--color-brand-600);
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  font-size: 1.2rem;
  grid-area: label;
`;

const Message = styled.span`
  font-size: 1.2rem;
  padding: 1.6rem 2.4rem;
  grid-area: message;
  letter-spacing: 0.4px;
  color: var(--color-grey-600);
`;

const Time = styled.span`
  font-size: 1rem;
  color: var(--color-grey-600);
  display: flex;
  align-items: center;
  grid-area: time;
  svg {
    margin-right: 0.5rem;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  grid-area: delete;
  color: var(--color-grey-500);
  &:hover {
    color: var(--color-red-500);
  }
`;


function NotificationRow({ label, message, time, isNew, onDelete, onMarkAsRead }) {
  return (
    <StyledFormRow isNew={isNew} onMarkAsRead={onMarkAsRead}>

      {isNew && <RecencyLabel>New</RecencyLabel>}
      <Label>{label}</Label>
      <Message>{message}</Message>
      <DeleteButton onClick={onDelete}>
        <IoMdClose />
      </DeleteButton>
      <Time>
        <IoIosTime />
        {new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </Time>

    </StyledFormRow>
  );
}

export default NotificationRow;
