import styled, { css } from "styled-components";

const Form = styled.form`
  ${(props) =>
    props.type === "regular" &&
    css`
      padding: 2.4rem 4rem;

      /* Box */
      background-color: var(--color-grey-0);
      border: 1px solid var(--color-grey-100);
      border-radius: var(--border-radius-md);
    `}

  ${(props) =>
    props.type === "modal" &&
    css`
      width: 100%;
    `}
  
    ${(props) =>
    props.type === "secondary" &&
    css`
        padding: 2.4rem 4rem;
  
        /* Box */
        background-color: var(--color-grey-0);
        border-radius: var(--border-radius-md);
      `}

      ${(props) =>
    props.type === "tertiary" &&
    css`
            padding: 2.4rem 4rem;
      
            /* Box */
            background-color: none;
          `}
    
  overflow: hidden;
  font-size: 1.4rem;
`;
Form.defaultProps = {
  type: "regular",
  type: "secondary"
};
export default Form;
