import React from 'react';
import IconButton from '@mui/material/IconButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const GenerateID = ({ onGenerate, prefix }) => {
    const handleClick = () => {
        const newID = `${prefix}${Math.floor(100000 + Math.random() * 900000)}`;
        onGenerate(newID);
    };

    return (
        <IconButton onClick={handleClick} aria-label="generate id">
            <AutorenewIcon />
        </IconButton>
    );
};

export default GenerateID;
