import React, { useEffect } from 'react';
import CustomInputWithPrefix from '../../../ui/CustomInputWithPrefix';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import { workTypes } from "../../../data/workData/workData";
import { useFormContext, useFieldArray, FormProvider } from 'react-hook-form';
import Heading from '../../../ui/Heading';
import GenerateID from '../../../ui/GenerateID';
import Input from "../../../ui/Input";
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';

const WorkGeneralFields = () => {
    const methods = useFormContext();
    const { register, setValue, control, formState: { errors }, trigger } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "work_identifiers"
    });

    const handleWorkTypeSelect = (option) => {
        setValue("work_type", option.value);
        trigger("work_type");
    };

    useEffect(() => {
        setValue("work_identifier_type", "work");
    }, [setValue]);

    const handleAddIdentifier = (event) => {
        event.preventDefault();
        if (fields.length < 3) {
            append({ id: "", type: "" });
        }
    };

    return (
        <FormProvider {...methods}>
            <FormRow error={errors.work_type?.message}>
                <Heading as="h5">Work type</Heading>
                <DropDown
                    id="work_type"
                    options={workTypes}
                    onSelect={handleWorkTypeSelect}
                    initialValue={methods.getValues("work_type")}
                    placeholder={"Choose work type"}
                    {...register("work_type", {
                        required: "Please choose work type",
                    })}
                />
            </FormRow>
            <FormRow error={errors.work_nfaid?.message}>
                <Heading as="h5">NFA id</Heading>
                <CustomInputWithPrefix
                    id="work_nfaid"
                    name="work_nfaid"
                    prefix="SA-NFA-WYY"
                    {...register("work_nfaid", { required: "Please enter NFA id" })}
                    GenerateIDComponent={GenerateID}
                />
            </FormRow>
            {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                    <FormRow error={errors?.work_identifiers?.[index]?.id?.message}>
                        <Heading as="h5">Identifier id {index + 1}</Heading>
                        <Input
                            id={`work_identifiers_${index}_id`}
                            {...register(`work_identifiers[${index}].id`, { required: "Please enter id" })}
                            placeholder={`Identifier ID ${index + 1}`}
                        />
                    </FormRow>
                    <FormRow error={errors?.work_identifiers?.[index]?.type?.message}>
                        <Heading as="h5">Identifier type {index + 1}</Heading>
                        <Input
                            id={`work_identifiers_type_${index}`}
                            {...register(`work_identifiers[${index}].type`, { required: "Please enter id type" })}
                            placeholder={`Identifier Type ${index + 1}`}
                        />
                    </FormRow>
                    {index >= 0 && (
                        <TextButtonRemove handleRemoveIdentifier={() => remove(index)} label="Remove identifier"></TextButtonRemove>
                    )}
                </React.Fragment>
            ))}
            {fields.length < 3 && (
                <TextButtonAdd handleAddIdentifier={handleAddIdentifier} label="Add identifier"></TextButtonAdd>
            )}
        </FormProvider>
    );
};

export default WorkGeneralFields;
