import React, { useState, useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import DropDown from '../../../ui/DropDown';
import FormRow from '../../../ui/FormRow';
import { useFormContext } from 'react-hook-form';
import { unitTypesAudioVisuals, physicalUnitTypesAudioVisuals, unitTypesPhotos, physicalUnitTypesPhotos, unitTypesDoc, physicalUnitTypesDoc } from '../../../data/itemData/itemData';
import Input from '../../../ui/Input';
import Heading from '../../../ui/Heading';
import { StyledTextField } from '../../../ui/StyledComponents';

const ItemExtent = ({ isCreating, category }) => {
    const { register, setValue, watch, getValues } = useFormContext();
    const [firstLength, setFirstLength] = useState('');
    const [unitType, setUnitType] = useState('');
    const [secondLength, setSecondLength] = useState('');
    const [physicalUnitType, setPhysicalUnitType] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');

    const handleFirstLengthChange = (event) => {
        setFirstLength(event.target.value);
    };

    const handleSecondLengthChange = (event) => {
        setSecondLength(event.target.value);
    };

    const handleUnitTypeSelect = (option) => {
        setUnitType(option.value || "");
    };

    const handlePhysicalUnitTypeSelect = (option) => {
        setPhysicalUnitType(option.value || "");
    };

    const handleDurationChange = (event, setter) => {
        let value = event.target.value;
        if (value.length > 2) {
            value = value.slice(0, 2);
        }
        setter(value);
    };

    useEffect(() => {
        const lengthValue = getValues('item_format_length');
        if (lengthValue) {
            const [first, unit, second, physicalUnit] = lengthValue.split(' ');
            setFirstLength(first || '');
            setUnitType(unit || '');
            setSecondLength(second || '');
            setPhysicalUnitType(physicalUnit || '');
        }
    }, [getValues]);

    useEffect(() => {
        const durationValue = getValues('item_format_duration');
        if (durationValue) {
            const [h, m, s] = durationValue.split(':');
            setHours(h || '');
            setMinutes(m || '');
            setSeconds(s || '');
        }
    }, [getValues]);

    useEffect(() => {
        const length = `${firstLength} ${unitType} ${secondLength} ${physicalUnitType}`.trim();
        setValue('item_format_length', length || "");
    }, [firstLength, unitType, secondLength, physicalUnitType, setValue]);

    useEffect(() => {
        const lengthUnit = `${unitType} ${physicalUnitType}`.trim();
        setValue('item_format_lengthunit', lengthUnit || "");
    }, [unitType, physicalUnitType, setValue]);

    useEffect(() => {
        const duration = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
        setValue('item_format_duration', duration || "");
    }, [hours, minutes, seconds, setValue]);

    return (
        <>
            <FormRow>
                <Heading as="h5">Primary length</Heading>
                <StyledTextField
                    type="number"
                    id="first_length"
                    onChange={handleFirstLengthChange}
                    placeholder="Primary length"
                    disabled={isCreating}
                    value={firstLength}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{unitType}</InputAdornment>,
                    }}
                />
                <Heading as="h5">Unit Type</Heading>
                <DropDown
                    id="unit_type"
                    options={category === 'audiovisuals' ? unitTypesAudioVisuals :
                        category === 'photos_and_images' ? unitTypesPhotos :
                            unitTypesDoc}
                    onSelect={handleUnitTypeSelect}
                    value={unitType}
                    initialValue={unitType}
                    placeholder={"Unit type"}
                />
            </FormRow>

            <FormRow>
                <Heading as="h5">Secondary length</Heading>
                <StyledTextField
                    type="number"
                    id="second_length"
                    onChange={handleSecondLengthChange}
                    placeholder="Secondary length"
                    value={secondLength}
                    disabled={isCreating}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{unitType}</InputAdornment>,
                    }}
                />
                <Heading as="h5">Physical Unit Type</Heading>
                <DropDown
                    id="physical_unit_type"
                    options={category === 'audiovisuals' ? physicalUnitTypesAudioVisuals :
                        category === 'photos_and_images' ? physicalUnitTypesPhotos :
                            physicalUnitTypesDoc}
                    onSelect={handlePhysicalUnitTypeSelect}
                    value={physicalUnitType}
                    initialValue={physicalUnitType}
                    placeholder={"Physical unit"}
                />
            </FormRow>

            {(category === 'audiovisuals') && (
                <FormRow>
                    <Heading as="h5">Duration</Heading>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Input
                            type="number"
                            value={hours}
                            onChange={(e) => handleDurationChange(e, setHours)}
                            placeholder="HH"
                            inputProps={{ min: 0, maxLength: 2 }}
                        />
                        <Input
                            type="number"
                            value={minutes}
                            onChange={(e) => handleDurationChange(e, setMinutes)}
                            placeholder="MM"
                            inputProps={{ min: 0, max: 59, maxLength: 2 }}
                        />
                        <Input
                            type="number"
                            value={seconds}
                            onChange={(e) => handleDurationChange(e, setSeconds)}
                            placeholder="SS"
                            inputProps={{ min: 0, max: 59, maxLength: 2 }}
                        />
                    </div>
                </FormRow>
            )}
            <input type="hidden" {...register('item_format_length')} />
            <input type="hidden" {...register('item_format_duration')} />
        </>
    );
};

export default ItemExtent;
