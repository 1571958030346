import React, { forwardRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const DatePickerInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <TextField
        ref={ref}
        value={value}
        onClick={onClick}
        placeholder={placeholder}
        fullWidth
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <CalendarTodayIcon />
                </InputAdornment>
            ),
        }}
    />
));

export default DatePickerInput;
