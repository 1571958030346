import React from 'react';
import UserLayout from "../features/users/UsersLayout";
import Heading from "../ui/Heading";
import Row from "../ui/Row";
import Button from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function User() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToCreateUser = () => {
        navigate('/create-user');
    };

    return (
        <>
            <Row type="horizontal" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Heading as="h1">{t('titles.users')}</Heading>
                <Button
                    variant="primary"
                    size="medium"
                    onClick={navigateToCreateUser}
                >
                    + Create New User
                </Button>
            </Row>
            <UserLayout />
        </>
    );
}

export default User;
