import React from 'react';
import styled from 'styled-components';

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledLabel = styled.label`
  font-size: 1.2rem;
  color: var(--color-grey-500);
  margin-bottom: 0.4rem;
`;

const StyledInput = styled.input`
  border: 1px solid var(--color-grey-200);
  background-color: var(--color-grey-0);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm);
  padding: 0.8rem 1.2rem;
  color: var(--color-grey-900);
  &:hover {
    border-color: var(--color-brand-600);
  }
  &:focus {
    outline: none;
    border-color: var(--color-brand-600);
  }
`;

const StyledFilterField = ({ label, ...props }) => {
  return (
    <StyledInputContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput {...props} />
    </StyledInputContainer>
  );
};

export default StyledFilterField;
