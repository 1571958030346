import React, { useState, useEffect, createContext } from 'react';
import { fetchUnreadNotificationCount } from '../services/apiNotification';
import { useUserId } from '../features/authentication/useUserId';
import { io } from 'socket.io-client';

export const NotificationContext = createContext({
    notificationCount: 0,
    isLoading: false,
    error: null,
    setError: () => { },
    setIsLoading: () => { },
    setNotificationCount: () => { }
});

export const NotificationProvider = ({ children }) => {
    const [notificationCount, setNotificationCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);

    const { user } = useUserId();
    const userId = user?.user?.user_id;
    const socket = io(process.env.REACT_APP_API_URL, { autoConnect: false });

    const value = {
        notificationCount,
        setNotificationCount,
        isLoading,
        setIsLoading,
        error,
        setError
    };

    useEffect(() => {
        if (!userId) return;

        socket.on('connect', () => {
            setIsConnected(true);
            fetchUnreadNotificationCount(userId)
                .then(count => {
                    setNotificationCount(count);
                    socket.emit('join', userId);
                })
                .catch(err => {
                    setError(err.message);
                })
                .finally(() => setIsLoading(false));
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
        });

        const eventName = `notification:${userId}`;
        socket.on(eventName, (data) => {
            setNotificationCount(prevCount => prevCount + 1);
        });


        socket.connect();

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off(eventName);
            socket.disconnect();
        };
    }, [userId]);

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};
