
import ItemTable from "../records/tables/itemTable/ItemTable"

function RecordsLayout() {
    return (
        <>
            <ItemTable />
        </>
    );
}

export default RecordsLayout;
