import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Box, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchManifestationById } from '../../../../services/apiManifestation';
import { fetchWorkById } from '../../../../services/apiWork';
import Heading from '../../../../ui/Heading';
import countries from 'i18n-iso-countries';
import { convertLanguageCodesToNames, convertCountryCodesToNames } from '../../../../utils/countries';
import FormRow from '../../../../ui/FormRow';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en);

function ManifestationDetails() {
    const { manifestId } = useParams();
    const navigate = useNavigate();
    const [manifestation, setManifestation] = useState(null);
    const [work, setWork] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function loadManifestationDetails() {
            try {
                const data = await fetchManifestationById(manifestId);
                setManifestation(data);
                if (data.work_id) {
                    fetchWorkById(data.work_id)
                        .then(workData => {
                            setWork(workData);
                        })
                        .catch(err => {
                            console.error('Failed to fetch manifestation:', err);
                            setError('Failed to load manifestation data');
                        });
                }
            } catch (err) {
                setError('Failed to fetch manifestation details');
            } finally {
                setIsLoading(false);
            }
        }
        loadManifestationDetails();
    }, [manifestId]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    const formatField = (label, value) => (
        <TableRow key={label}>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{label}</Heading>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{value || ''}</Heading>
            </TableCell>
        </TableRow>
    );

    const formatHeader = (label, value) => (
        <TableRow key={label}>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h7">{label}</Heading>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{value || ''}</Heading>
            </TableCell>
        </TableRow>
    );

    const formatLanguageField = (languages) => {
        return languages.map((language, index) => (
            <TableRow key={`language-${index}`}>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Heading as="h8"> {convertLanguageCodesToNames(language.language)}</Heading>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    {language.written && <Heading as="h8"><strong>Written:</strong> ({language.writtenType})</Heading>}
                    {language.dialogue && <Heading as="h8"><strong>Dialogue:</strong>({language.dialogueType})</Heading>}
                </TableCell>
            </TableRow>
        ));
    };


    const renderAgents = (agents) => (
        agents.map((agent, index) => {
            return (
                <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{agent.agent_type}</Heading>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{agent.agent_description}</Heading>
                    </TableCell>
                </TableRow>
            );
        })
    );


    const renderEventDetails = (eventDetails) => (
        Object.entries(eventDetails).map(([key, value]) => {
            if (key === 'id') return null;
            if (key === 'nomination') {
                value = value ? 'Yes' : 'No';
            }
            if (key === 'region' || key === 'scope') {
                value = Array.isArray(value) ? convertCountryCodesToNames(value).join(', ') : convertCountryCodesToNames([value]).join(', ');
            }
            return (
                <TableRow key={key}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{key.charAt(0).toUpperCase() + key.slice(1)}</Heading>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}><Heading as="h8">{value}</Heading></TableCell>
                </TableRow>
            );
        })
    );


    const renderEvents = (events, eventType) => (
        events.filter(event => event[eventType]).map((event, index) => (
            <React.Fragment key={index}>
                <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                        <Heading as="h7">
                            Event: {eventType.replace(/^event_/, '').charAt(0).toUpperCase() + eventType.replace(/^event_/, '').slice(1)}
                        </Heading>
                    </TableCell>
                </TableRow>
                {Array.isArray(event[eventType])
                    ? event[eventType].map((detail, idx) => (
                        <React.Fragment key={idx}>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                                    <Heading as="h7">
                                        Date: {detail.date || 'N/A'}
                                    </Heading>
                                </TableCell>
                            </TableRow>
                            {renderEventDetails(detail)}
                        </React.Fragment>
                    ))
                    : (
                        <>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                                    <Heading as="h7">
                                        Date: {event[eventType].date || 'N/A'}
                                    </Heading>
                                </TableCell>
                            </TableRow>
                            {renderEventDetails(event[eventType])}
                        </>
                    )
                }
            </React.Fragment>
        ))
    );


    const RenderLanguageDetails = ({ languages, category }) => {
        if (!Array.isArray(languages) || languages.length === 0) {
            return <Heading as={"h8"}>No languages available</Heading>;
        }

        return languages.map((lang, index) => (
            <div key={index}>
                {category === "audiovisuals" && (
                    <FormRow>
                        <Heading as={"h8"}>Language: {convertLanguageCodesToNames(lang.language)}</Heading>
                        {lang.written && <Heading as={"h8"}>Written: {lang.writtenType}</Heading>}
                        {lang.dialogue && <Heading as={"h8"}>Dialogue: {lang.dialogueType}</Heading>}
                    </FormRow>
                )}
                {category === "paper_based_documents" && (
                    <FormRow>
                        <Heading as={"h8"}>Language: {convertLanguageCodesToNames(lang.language)}</Heading>
                        <Heading as={"h8"}>Written: {lang.writtenType}</Heading>
                    </FormRow>
                )}
            </div>
        ));
    };

    return (
        <Box margin={3}>
            <IconButton onClick={() => navigate(-1)} color="var(--color-grey-700)">
                <ArrowBackIcon />
            </IconButton>
            <CardContent>
                <Heading as="h7">
                    Manifestation
                </Heading>
                <TableContainer>
                    <TableBody>
                        {formatHeader('[Identifier] Identification number:', manifestation?.manifest_nfaid)}
                    </TableBody>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Title [Title]</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Title type</Heading></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {formatField(manifestation?.manifest_title_proper, 'Identifying')}
                            {formatField(manifestation?.manifest_title_alternative, 'Alternative')}
                            {formatField(manifestation?.manifest_title_supplied, 'Preferred')}
                            {manifestation?.category === "photos_and_images" && formatField(manifestation?.manifest_title_caption, 'Caption')}
                            {formatField(manifestation?.manifest_title_other, 'Other')}
                        </TableBody>

                        <TableBody>
                            {formatHeader('Manifestation type: ', manifestation?.manifest_type)}
                        </TableBody>
                        {manifestation.category !== "photos_and_images" &&
                            <>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ borderBottom: 'none' }} ><Heading as="h7">Language [Language]</Heading></TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }} ><Heading as="h7">Usage</Heading></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <RenderLanguageDetails category={manifestation.category} languages={manifestation.manifest_format_language} />

                                </TableBody>
                            </>
                        }
                        <TableBody>
                            {formatField('[Original format] General Format:', manifestation?.manifest_format_general)}
                            {formatField('Specific Format:', manifestation?.manifest_format_specific)}
                            {(manifestation.category === "photos_and_images" || manifestation.category === "audiovisuals") && formatField('Colour Colour system:', manifestation?.manifest_format_colorstandard)}
                            {(manifestation.category === "audiovisuals") && formatField(manifestation?.manifest_format_audio)}
                            {(manifestation.category === "audiovisuals") && formatField('[Original duration] Running time –', manifestation?.manifest_format_duration)}
                            {formatField('[Original length] Length: ', manifestation?.manifest_format_length)}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Agent type</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Agent description</Heading></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {manifestation?.manifestation_agents && renderAgents(manifestation.manifestation_agents)}
                        </TableBody>
                        <TableBody>
                            {manifestation?.manifestation_events && renderEvents(manifestation.manifestation_events, 'event_publication')}
                            {manifestation?.manifestation_events && renderEvents(manifestation.manifestation_events, 'event_award')}
                            {manifestation?.manifestation_events && renderEvents(manifestation.manifestation_events, 'event_licensing')}
                            {manifestation?.manifestation_events && renderEvents(manifestation.manifestation_events, 'event_preservation')}
                            {manifestation?.manifestation_events && renderEvents(manifestation.manifestation_events, 'event_decision')}
                            {manifestation?.manifestation_events && renderEvents(manifestation.manifestation_events, 'event_manufacture')}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Relationship: Part of title</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">{work?.work_title_identifying}</Heading><Heading as="h7">{work?.work_nfaid}</Heading></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </CardContent>

        </Box>
    );
}

export default ManifestationDetails;
