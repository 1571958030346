import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import "react-datepicker/dist/react-datepicker.css";
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import TextButtonAdd from '../../../ui/TextButtonAdd';
import TextButtonRemove from '../../../ui/TextButtonRemove';
import { workDatePrecision, workDateTypes } from "../../../data/workData/workData";
import Heading from '../../../ui/Heading';
import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";
import DatePickerWrapper from '../../../ui/DatePickerWrapper';
import DatePickerInput from '../../../ui/DatePickerInput';

const parseDate = (dateStr) => {
    if (!dateStr) return null;
    if (dateStr.length === 4) {
        return new Date(parseInt(dateStr, 10), 0, 1);
    }
    return new Date(dateStr);
};

const formatDate = (date, yearOnly) => {
    if (!date) return null;
    return yearOnly ? date.getUTCFullYear().toString() : date.toISOString().split('T')[0];
};

const WorkDateEntries = ({ dateEntries, setDateEntries, errors }) => {
    const { control, setValue } = useFormContext();

    const handleDateChange = (id, key, value, yearOnly = false) => {
        const formattedDate = value instanceof Date ? formatDate(value, yearOnly) : value;
        setDateEntries(dateEntries.map(entry =>
            entry.id === id ? { ...entry, [key]: formattedDate } : entry
        ));
        const index = dateEntries.findIndex(entry => entry.id === id);
        setValue(`dateEntries.${index}.${key}`, formattedDate, { shouldValidate: false });
    };


    const addDateEntry = (event) => {
        event.preventDefault();
        if (dateEntries.length < 4) {
            const newEntry = {
                id: uuidv4(),
                startDate: formatDate(new Date(), false),
                endDate: formatDate(new Date(), false),
                startDatePrecision: workDatePrecision[0].value,
                endDatePrecision: workDatePrecision[0].value,
                dateType: workDateTypes[0].value,
            };
            setDateEntries([...dateEntries, newEntry]);
        }
    };

    const removeDateEntry = (id, event) => {
        event.preventDefault();
        if (dateEntries.length > 1) {
            setDateEntries(dateEntries.filter(entry => entry.id !== id));
        } else {
            toast.error("At least one date entry is required.");
        }
    };

    useEffect(() => {
        dateEntries.forEach((entry, index) => {
            setValue(`dateEntries.${index}.startDate`, entry.startDate, { shouldValidate: false });
            setValue(`dateEntries.${index}.endDate`, entry.endDate, { shouldValidate: false });
            setValue(`dateEntries.${index}.startDatePrecision`, entry.startDatePrecision, { shouldValidate: false });
            setValue(`dateEntries.${index}.endDatePrecision`, entry.endDatePrecision, { shouldValidate: false });
            setValue(`dateEntries.${index}.dateType`, entry.dateType, { shouldValidate: false });
        });
    }, [dateEntries, setValue]);

    return (
        <>
            {dateEntries.map((entry, index) => (
                <React.Fragment key={entry.id}>
                    <FormRow error={errors?.dateEntries?.[index]?.startDate?.message || ''}>
                        <Heading as="h5">Start date</Heading>
                        <div key={entry.id}>
                            <Controller
                                control={control}
                                name={`dateEntries.${index}.startDate`}
                                defaultValue={parseDate(entry.startDate)}
                                render={({ field }) => (
                                    <DatePickerWrapper
                                        selected={field.value}
                                        onChange={date => {
                                            const yearOnly = field.value && field.value.length === 4;
                                            field.onChange(date);
                                            handleDateChange(entry.id, 'startDate', date, yearOnly);
                                        }}
                                        yearOnly={entry.startDate && entry.startDate.length === 4}
                                    />
                                )}
                                rules={{ required: "Start Date is required" }}
                            />

                        </div>
                    </FormRow>
                    <FormRow error={errors?.dateEntries?.[index]?.startDatePrecision?.message || ''}>
                        <Heading as="h5">Start date precision</Heading>
                        <DropDown
                            id={`date_precision_start_${index}`}
                            options={workDatePrecision}
                            placeholder={"Choose date precision"}
                            onSelect={option => handleDateChange(entry.id, 'startDatePrecision', option.value)}
                            initialValue={entry.startDatePrecision}
                        />
                    </FormRow>
                    <FormRow error={errors?.dateEntries?.[index]?.endDate?.message || ''}>
                        <Heading as="h5">End date</Heading>
                        <div key={entry.id}>

                            <Controller
                                control={control}
                                name={`dateEntries.${index}.endDate`}
                                defaultValue={parseDate(entry.endDate)}
                                render={({ field }) => (
                                    <DatePickerWrapper
                                        selected={field.value}
                                        onChange={date => {
                                            const yearOnly = field.value && field.value.length === 4;
                                            field.onChange(date);
                                            handleDateChange(entry.id, 'endDate', date, yearOnly);
                                        }}
                                        yearOnly={entry.endDate && entry.endDate.length === 4}
                                    />
                                )}
                                rules={{ required: "End Date is required" }}
                            />

                        </div>
                    </FormRow>
                    <FormRow error={errors?.dateEntries?.[index]?.endDatePrecision?.message || ''}>
                        <Heading as="h5">End date precision</Heading>
                        <DropDown
                            id={`date_precision_end_${index}`}
                            options={workDatePrecision}
                            placeholder={"Choose date precision"}
                            onSelect={option => handleDateChange(entry.id, 'endDatePrecision', option.value)}
                            initialValue={entry.endDatePrecision}
                        />
                    </FormRow>
                    <FormRow error={errors?.dateEntries?.[index]?.dateType?.message || ''}>
                        <Heading as="h5">Date type</Heading>
                        <DropDown
                            id={`date_type_${index}`}
                            options={workDateTypes}
                            onSelect={option => handleDateChange(entry.id, 'dateType', option.value)}
                            initialValue={entry.dateType}
                            placeholder={"Choose date type"}
                        />
                    </FormRow>

                    <TextButtonRemove
                        handleRemoveIdentifier={(event) => removeDateEntry(entry.id, event)}
                        label="Remove date"
                        disabled={index === 0}
                    />
                </React.Fragment>
            ))}
            <TextButtonAdd handleAddIdentifier={(event) => addDateEntry(event)} label={"Add new date"} disabled={dateEntries.length >= 4}></TextButtonAdd>
        </>
    );
};

export default WorkDateEntries;
