import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { StyledFormControl, StyledLabel, StyledSelect, StyledOptionGroup, StyledOption } from './StyledGroupedDropDown';

const GroupedDropDown = ({ id, options, onSelect, value, label, disabled }) => {
    const { setValue, register } = useFormContext();
    const selectRef = useRef(null);

    useEffect(() => {
        if (selectRef.current && !disabled) {
            register(id);
        }
    }, [disabled, selectRef, register, id]);

    return (
        <StyledFormControl>
            <StyledLabel htmlFor={id}></StyledLabel>
            <StyledSelect
                id={id}
                value={value || ''}
                onChange={e => {
                    const selectedOption = e.target.value;
                    setValue(id, selectedOption, { shouldValidate: true });
                    onSelect({ value: selectedOption });
                }}
                disabled={disabled}
                ref={selectRef}
            >
                <option value="">{label}</option>
                {options.map(group => (
                    <StyledOptionGroup label={group.title} key={group.title}>
                        {group.options.map(option => (
                            <StyledOption key={option.value} value={option.value}>
                                {option.label}
                            </StyledOption>
                        ))}
                    </StyledOptionGroup>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
};

GroupedDropDown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired
                })
            ).isRequired
        })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
};

GroupedDropDown.defaultProps = {
    options: [],
};

export default GroupedDropDown;
