import { useMutation, useQueryClient } from "@tanstack/react-query";
import { login as loginApi, verify2fa as verify2faApi } from "../../services/apiAuth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useUser } from './useUser';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isAuthenticated } = useUser();
  const [is2FAPending, setIs2FAPending] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));

  const handleRedirection = (role) => {
    if (role === 'viewer') {
      navigate("/catalogue");
    } else if (role === 'entry_personnel') {
      navigate("/records");
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleRedirection(role);
    }
  }, [isAuthenticated, role]);

  const { mutate: login, isLoading } = useMutation(loginApi, {
    onSuccess: (data) => {
      if (data.message === '2FA code sent to your email.') {
        setIs2FAPending(true);
        toast.success(t('verify.email_success'));
      } else {
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.user.user_role);
        setRole(data.user.user_role);

        toast.success(t('login.success'));
        queryClient.invalidateQueries(["user"]);
      }
    },
    onError: (error) => {
      toast.error(t('login.failed'));
    }
  });

  const { mutate: verify2fa, isLoading: is2FAVerifying } = useMutation(verify2faApi, {
    onSuccess: (data) => {
      localStorage.setItem("token", data.token);
      localStorage.setItem("role", data.user.user_role);
      setRole(data.user.user_role);

      toast.success(t('verify.success'));
      queryClient.invalidateQueries(["user"]);
    },
    onError: (error) => {
      toast.error(t('verify.failed'));
    }
  });

  return { login, verify2fa, isLoading, is2FAPending, is2FAVerifying };
}
