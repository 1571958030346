import React from 'react';
import styled from 'styled-components';
import RemoveIcon from '@mui/icons-material/Remove';
import Heading from './Heading';

const StyledIconButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: var(--border-radius-sm);

    &:hover {
        background: transparent;
        color: var(--color-brand-600);
        h5 {
            color: var(--color-brand-600);
        }
    }

    &:focus {
        outline: none;
    }

    &:disabled, &[disabled] {
        cursor: not-allowed;
        color: var(--color-grey-400);
        h5 {
            color: var(--color-grey-400);
        }
    }
`;

const StyledHeading = styled.h5`
    margin-left: 0.5rem;
    color: var(--color-grey-900);
`;

const TextButtonRemove = ({ handleRemoveIdentifier, label, disabled }) => {
    return (
        <StyledIconButton onClick={disabled ? undefined : handleRemoveIdentifier}
            aria-label={label.toLowerCase()}
            disabled={disabled}>
            <RemoveIcon />
            <StyledHeading>
                <Heading as="h5">{label}</Heading>
            </StyledHeading>
        </StyledIconButton>
    );
};

export default TextButtonRemove;
