import React, { createContext, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitchContext = createContext();

export const useLanguageSwitch = () => useContext(LanguageSwitchContext);

export const LanguageSwitchProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            setCurrentLanguage(lng);
            document.body.dir = i18n.dir();
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === "en" ? "ar" : "en";
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <LanguageSwitchContext.Provider value={{ currentLanguage, toggleLanguage }}>
            {children}
        </LanguageSwitchContext.Provider>
    );
};
