import React, { useEffect, useState } from 'react';
import { fetchItems, deleteItem, updateItem } from '../../../../services/apiItem';
import { fetchUserById } from '../../../../services/apiUser';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SpinnerMini from '../../../../ui/SpinnerMini';
import {
    Typography,
    IconButton,
    Collapse,
    Box,
    TablePagination,
    Table as MuiTable,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
} from '@mui/material';
import { MdOutlineExpandLess, MdOutlineExpandMore, MdEdit, MdDelete } from "react-icons/md";
import { Share } from '@mui/icons-material';
import Heading from '../../../../ui/Heading';
import FormRow from '../../../../ui/FormRow';
import Table from '../../../../ui/Table';
import StyledFilterField from '../../../../ui/StyledFilterField';
import CustomModal from '../../../../ui/CustomModal';
import { useUser } from '../../../authentication/useUser';
import TextButton from '../../../../ui/TextButton';
import PreviewContent from '../../../../ui/PreviewContent';

const StyledNavLink = styled(NavLink)`
    cursor: pointer;
    &:hover {
        background: transparent;
        color: var(--color-brand-600);
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        color: var(--color-grey-400);
        h5 {
            color: var(--color-grey-400);
        }
    }
    &:link,
    &:visited {
        display: flex;
        align-items: center;
        color: var(--color-grey-600);
        transition: all 0.3s;
    }
`;

function ItemTable() {
    const { t } = useTranslation();
    const { user } = useUser();
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, itemId: null });
    const [userDetails, setUserDetails] = useState({});

    const navigate = useNavigate();
    useEffect(() => {
        async function loadItems() {
            setIsLoading(true);
            try {
                const data = await fetchItems(user.user.user_id, user.user.user_role);
                setItems(data);
                setFilteredItems(data);
                setError(null);
            } catch (err) {
                setError('Failed to fetch items');
            } finally {
                setIsLoading(false);
            }
        }
        loadItems();
    }, [user]);

    useEffect(() => {
        items.forEach(item => {
            if (!userDetails[item.user_id]) {
                getUserDetails(item.user_id);
            }
        });
    }, [items]);

    async function getUserDetails(userId) {
        if (userDetails[userId]) {
            return;
        }
        setIsLoading(true);
        try {
            const userDetailsResponse = await fetchUserById(userId);
            if (userDetailsResponse) {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [userId]: `${userDetailsResponse.user_fname} ${userDetailsResponse.user_lname}`
                }));
            }
            else {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [userId]: 'Unknown User'
                }));
            }
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            setUserDetails(prevDetails => ({
                ...prevDetails,
                [userId]: 'Unknown User'
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const handleFilterChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(searchText);
        const filtered = items.filter(item => {
            const item_title_proper = (item.item_title_proper || "").toLowerCase();
            const item_title_alternative = (item.item_title_alternative || "").toLowerCase();
            const item_title_supplied = (item.item_title_supplied || "").toLowerCase();
            const item_title_other = (item.item_title_other || "").toLowerCase();
            const item_format_general = (item.item_format_general || "").toLowerCase();
            const item_nfaid = (item.item_nfaid || "").toLowerCase();
            const category = (item.category && item.category).toLowerCase();
            return item_title_proper.includes(searchText) ||
                item_title_alternative.includes(searchText) ||
                item_title_supplied.includes(searchText) ||
                item_title_other.includes(searchText) ||
                item_format_general.includes(searchText) ||
                item_nfaid.includes(searchText);
            category.includes(searchText);
        });
        setFilteredItems(filtered);
        setPage(0);
    };


    const handleDeleteClick = (itemId) => {
        setConfirmDelete({ open: true, itemId });
    };

    const handleDelete = async () => {
        if (confirmDelete.itemId !== null) {
            setIsLoading(true);
            try {
                await deleteItem(confirmDelete.itemId);
                setItems(items.filter(item => item.item_id !== confirmDelete.itemId));
                setFilteredItems(filteredItems.filter(item => item.item_id !== confirmDelete.itemId));
                setError(null);

            } catch (err) {
                setError('Failed to delete item');
            } finally {
                setIsLoading(false);
                setConfirmDelete({ open: false, itemId: null });
            }
        }
    };

    const handleEdit = (itemId) => {
        navigate(`/edit-item/${itemId}`);
    };

    const handleExpandClick = (itemId) => {
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(itemId)) {
            newExpandedRows.delete(itemId);
        } else {
            newExpandedRows.add(itemId);
        }
        setExpandedRows(newExpandedRows);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return <SpinnerMini />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    const renderEventDetails = (eventType, eventDetails) => {
        switch (eventType) {
            case 'event_inspection':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Inspection Event:</Heading>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Type: {eventDetail.type}</p>
                        <p>Details: {eventDetail.details}</p>
                    </div>
                ));
            case 'event_acquisition':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Acquisition Event:</Heading>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Type: {eventDetail.type}</p>
                        <p>Source: {eventDetail.source}</p>
                        <p>Details: {eventDetail.details}</p>
                    </div>
                ));
            case 'event_licensing':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Licensing Event:</Heading>
                        <p>Notes: {eventDetail.notes}</p>
                    </div>
                ));
            case 'event_preservation':
                return eventDetails.map((eventDetail, index) => (
                    <div key={index}>
                        <Heading as='h8'>Preservation Event:</Heading>
                        <p>Date: {new Date(eventDetail.date).toLocaleDateString()}</p>
                        <p>Type: {eventDetail.type}</p>
                        <p>Notes: {eventDetail.notes}</p>
                    </div>
                ));
            default:
                return <Heading as='h8'>No details available</Heading>;
        }
    };



    const renderItemRow = (item) => (
        <React.Fragment key={item.item_id}>
            <Table.Row>
                <div>
                    {item.item_title_proper}
                    <br />
                    <Typography style={{ fontSize: '0.8rem' }}>
                        {item.item_nfaid}
                    </Typography>
                    <br />
                    <Typography variant="caption" style={{ display: 'block' }}>
                        {`Entered by: ${userDetails[item.user_id] ? userDetails[item.user_id] : <SpinnerMini />}`}
                    </Typography>
                </div>
                <div>{item.category}</div>
                <div>{item.item_format_general}</div>
                <div>{item.item_format_location}</div>
                <div>
                    {item.item_accessstatus}
                    {item.item_status && (
                        <span style={{
                            display: 'inline-block',
                            marginLeft: '10px',
                            height: '10px',
                            width: '10px',
                            backgroundColor: item.item_status === 'yellow' ? 'yellow' : 'green',
                            borderRadius: '50%'
                        }} />
                    )}
                </div>

                <FormRow>

                    {expandedRows.has(item.item_id) ? <StyledNavLink onClick={() => handleExpandClick(item.item_id)} ><MdOutlineExpandLess /> </StyledNavLink> : <StyledNavLink StyledNavLink onClick={() => handleExpandClick(item.item_id)}> <MdOutlineExpandMore /></StyledNavLink>}
                    <StyledNavLink as="div" onClick={() => handleEdit(item.item_id)}> <MdEdit /></StyledNavLink>
                    <StyledNavLink onClick={() => handleDeleteClick(item.item_id)}> <MdDelete /></StyledNavLink>
                    <TextButton label="Fiaf card" link={`/item-details/${item.item_id}`}></TextButton>
                </FormRow>
            </Table.Row>
            <Collapse in={expandedRows.has(item.item_id)} timeout="auto" unmountOnExit>
                <Box margin={2}>
                    <TableBody>
                        {Object.entries({
                            'item_table.alternative_title': item.item_title_alternative ? item.item_title_alternative : "No data available",
                            'item_table.supplied_title': item.item_title_supplied ? item.item_title_supplied : "No data available",
                            ...(item.category === "photos_and_images" ? { 'item_table.caption_title': item.item_title_caption ? item.item_title_caption : "No data available" } : {}),
                            'item_table.other_titles': item.item_title_other ? item.item_title_other : "No data available",
                            'item_table.specific_format': item.item_format_specific ? item.item_format_specific : "No data available",
                            ...(item.category === "audiovisuals" || item.category === "photos_and_images" ? { 'item_table.color': item.item_format_color ? item.item_format_color : "No data available" } : {}),
                            ...(item.category !== "objects" ? { 'item_table.aspect_ratio': item.item_format_aspect ? item.item_format_aspect : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.aperture': item.item_format_aperture ? item.item_format_aperture : "No data available" } : {}),
                            ...(item.category === "audiovisuals" || item.category === "photos_and_images" ? { 'item_table.stock': item.item_format_stock ? item.item_format_stock : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.frame_rate': item.item_format_framerate ? item.item_format_framerate : "No data available" } : {}),
                            ...(item.category !== "objects" ? { 'item_table.bit_depth_rate': item.item_format_depth ? item.item_format_depth : "No data available" } : {}),
                            ...(item.category !== "objects" ? { 'item_table.resolution': item.item_format_resolution ? item.item_format_resolution : "No data available" } : {}),
                            'item_table.item_type': item.item_format_itemtype ? item.item_format_itemtype : "No data available",
                            ...(item.category === "audiovisuals" || item.category === "photos_and_images" ? { 'item_table.item_base': item.item_format_itembase ? item.item_format_itembase : "No data available" } : {}),
                            ...(item.category !== "objects" ? { 'item_table.height': item.item_format_height ? item.item_format_width : "No data available" } : {}),
                            ...(item.category !== "objects" ? { 'item_table.width': item.item_format_width ? item.item_format_width : "No data available" } : {}),
                            ...(item.category === "audiovisuals" || item.category === "photos_and_images" ? { 'item_table.color_standard': item.item_format_colorstandard } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.audio': item.item_format_audio ? item.item_format_audio : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.audio_format': item.item_format_audioformat ? item.item_format_audioformat : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.audio_system': item.item_format_audiosystem ? item.item_format_audiosystem : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.audio_rate': item.item_format_audiorate ? item.item_format_audiorate : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.audio_frequency': item.item_format_audiofrequency ? item.item_format_audiofrequency : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.audio_channel': item.item_format_audiochannel ? item.item_format_audiochannel : "No data available" } : {}),
                            ...(item.category !== "objects" ? { 'item_table.length': item.item_format_length ? item.item_format_length : "No data available" } : {}),
                            ...(item.category === "objects" ? { 'item_table.measurements': item.item_measurements ? item.item_measurements : "No data available" } : {}),
                            ...(item.category === "objects" ? { 'item_table.weight': item.item_format_weight ? item.item_format_weight : "No data available" } : {}),
                            ...(item.category === "audiovisuals" ? { 'item_table.duration': item.item_format_duration ? item.item_format_duration : "No data available" } : {}),
                            'item_table.location': item.item_format_location ? item.item_format_location : "No data available",
                            'item_table.access_condition': item.item_accesscondition ? item.item_accesscondition : "No data available",
                            'item_table.access_status': item.item_accessstatus ? item.item_accessstatus : "No data available",
                        }).map(([label, value], index) => (
                            <Table.Row key={index}>

                                <Heading as='h7'>
                                    {t(label)}
                                </Heading>

                                {value && typeof value === 'object' ?
                                    Object.entries(value).map(([key, val]) =>
                                        <Heading as={'h8'}>{`${key}: ${val}`}</Heading>
                                    )
                                    : value
                                }

                            </Table.Row>
                        ))}
                    </TableBody>


                    <Table.Accordion
                        summary={t('table.agents')}
                        details={
                            <MuiTable size="small">
                                <TableHead>
                                    <Table.Cell>{t('agent.type')}</ Table.Cell>
                                    <Table.Cell>{t('agent.description')}</Table.Cell>
                                </TableHead>
                                <TableBody>
                                    {(item.item_agents || []).map((agent, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell><Heading as={'h8'}>{agent.agent_type ? agent.agent_type : "No data available"}</Heading></TableCell>
                                            <TableCell><Heading as={'h8'}>{agent.agent_description ? agent.agent_description : "No data available"}</Heading></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </MuiTable>
                        }
                    />
                    <Table.Accordion
                        summary={t('table.events')}
                        details={
                            <MuiTable size="small">
                                <TableHead>
                                    <TableCell><Heading as='h7'>{t('event.type')}</Heading></TableCell>
                                    <TableCell ><Heading as='h7'>{t('event.details')}</Heading></TableCell>
                                </TableHead>
                                <TableBody>
                                    {['event_inspection', 'event_acquisition', 'event_licensing', 'event_preservation'].map((eventType, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell><Heading as='h7'>{t(eventType)}</Heading></TableCell>
                                            <Table.Cell>
                                                {item.item_events && item.item_events[0] && item.item_events[0][eventType] && item.item_events[0][eventType].length > 0 ?
                                                    renderEventDetails(eventType, item.item_events[0][eventType])
                                                    : <Heading as='h8'>No data available</Heading>}
                                            </Table.Cell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </MuiTable>
                        }
                    />
                    <Table.Accordion
                        summary={t('table.preview')}
                        details={
                            <PreviewContent category={item.category} />
                        }
                    />
                </Box>
            </Collapse>
        </React.Fragment>
    );


    return (
        <div style={{ width: '100%', marginTop: '1rem' }}>
            <Heading as="h3" > {t('titles.item')}</Heading>
            <StyledFilterField
                label={<Heading as="h6" > {t('table.filter')}</Heading>}
                variant="outlined"
                value={filterText}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                style={{ marginBottom: '1rem' }}
            />
            <Table columns="repeat(6, 1fr)">
                <Table.Header>
                    <Heading as="h6" > {t('item_table.title')}</Heading>
                    <Heading as="h6" > {t('table.category')}</Heading>
                    <Heading as="h6" > {t('item_table.general_format')}</Heading>
                    <Heading as="h6" > {t('item_table.location')}</Heading>
                    <Heading as="h6" > {t('item_table.access_condition')}</Heading>
                    <Heading as="h6" > {t('actions.actions')}</Heading>
                </Table.Header>
                <Table.Body data={filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} render={renderItemRow} />
            </Table>
            <Table.Footer>
                <TablePagination
                    component="div"
                    count={filteredItems.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Table.Footer>
            <CustomModal
                isOpen={confirmDelete.open}
                onRequestClose={() => setConfirmDelete({ open: false, workId: null })}
                onConfirm={handleDelete}
                confirmationMessage={
                    <>
                        Are you sure you want to delete this item?
                        <br />
                        <span style={{ color: 'var(--color-red-800)', fontWeight: 'bold' }}>This action cannot be undone</span>
                    </>
                }
            />
        </div>
    );
}

export default ItemTable;

