import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import DropDown from '../../../ui/DropDown';
import { getCountryOptions } from '../../../utils/countries';
import Input from '../../../ui/Input';
import Heading from '../../../ui/Heading';
const ItemLanguage = ({ errors }) => {
    const { register, setValue, watch } = useFormContext();
    const countryOptions = getCountryOptions();

    return (
        <>
            <FormRow error={errors.item_format_location?.message}>
                <Heading as="h5">Item Location</Heading>
                <Input
                    id="item_format_location"
                    type="text"
                    placeholder="Item physical location"
                    {...register("item_format_location", { required: "Please enter item location" })}
                />
            </FormRow>
        </>
    );
};

export default ItemLanguage;
