import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

// UI Components
import Heading from '../../../ui/Heading';
import FormRow from '../../../ui/FormRow';
import Form from '../../../ui/Form';
import FormGroup from '../../../ui/FormGroup';
import Button from '../../../ui/Button';
import CustomModal from '../../../ui/CustomModal';
import Warning from '../../../ui/Warning';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';

// Form Sections
import ManifestationTitles from './ManifestationTitles';
import ManifestationGeneralFields from './ManifestationGeneralFields';
import ManifestationLanguage from './ManifestationLanguage';
import ManifestationFormat from './ManifestationFormat';
import ManifestationAgents from './ManifestationAgents';
import ManifestationEvents from './ManifestationEvents';
import PartOfManifestation from './PartOfManifestation';
import ManifestationExtent from './ManifestationExtent';

// Data
import { fetchManifestationById, updateManifestation } from '../../../services/apiManifestation';
import { useUser } from '../../authentication/useUser';
import { getCountryOptions, getLanguageOptions } from '../../../utils/countries';
import 'react-datepicker/dist/react-datepicker.css';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function EditManifestationForm() {
    const navigate = useNavigate();
    const { manifestId } = useParams();
    const countryOptions = getCountryOptions();
    const languageOptions = getLanguageOptions();
    const methods = useForm();
    const { user } = useUser();
    const userId = user.user.user_id;
    const [manifestationLanguages, setManifestationLanguages] = useState([{ language: "", dialogue: false, written: false, dialogueType: "", writtenType: "" }]);
    const [category, setCategory] = useState(null);
    const [agents, setAgents] = useState([]);
    const [events, setEvents] = useState([]);
    const [publicationEvents, setPublicationEvents] = useState([]);
    const [awardEvents, setAwardEvents] = useState([]);
    const [licensingEvents, setLicensingEvents] = useState([]);
    const [preservationEvents, setPreservationEvents] = useState([]);
    const [decisionEvents, setDecisionEvents] = useState([]);
    const [manufactureEvents, setManufactureEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isItem, setIsItem] = useState(false);
    const [eventTypes, setEventTypes] = useState({
        publication: false,
        awardNomination: false,
        licensing: false,
        preservation: false,
        decision: false,
        manufacture: false,
    });
    const [selectedWorkId, setSelectedWorkId] = useState(null);  // New state for selected work ID
    const queryClient = useQueryClient();
    const [isInitialized, setIsInitialized] = useState(false);

    const mapEventsToState = (events) => {
        const publication = [];
        const award = [];
        const licensing = [];
        const preservation = [];
        const decision = [];
        const manufacture = [];
        if (events.length > 0) {
            events[0].event_publication?.forEach(event => publication.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
            events[0].event_award?.forEach(event => award.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
            events[0].event_licensing?.forEach(event => licensing.push({
                ...event,
                id: uuidv4(),
            }));
            events[0].event_preservation?.forEach(event => preservation.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
            events[0].event_decision?.forEach(event => decision.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
            events[0].event_manufacture?.forEach(event => manufacture.push({
                ...event,
                id: uuidv4(),
                date: new Date(event.date),
            }));
        }
        return {
            publication,
            award,
            licensing,
            preservation,
            decision,
            manufacture
        };
    };

    const { data: manifestationData, isLoading: isFetching } = useQuery(['manifestation', manifestId], () => fetchManifestationById(manifestId), {
        onSuccess: (data) => {
            if (!isInitialized) {
                const {
                    manifest_format_language,
                    manifestation_agents,
                    manifestation_events,
                    is_item,
                    category,
                    work_id,
                    ...restData
                } = data;

                const { publication, award, licensing, preservation, decision, manufacture } = mapEventsToState(manifestation_events || {});

                methods.reset(restData);
                setManifestationLanguages(manifest_format_language || []);
                setAgents(manifestation_agents.map(agent => ({
                    ...agent,
                    id: uuidv4(),
                    type: agent.agent_type,
                    description: agent.agent_description,
                })) || []);
                setPublicationEvents(publication);
                setAwardEvents(award);
                setLicensingEvents(licensing);
                setPreservationEvents(preservation);
                setDecisionEvents(decision);
                setManufactureEvents(manufacture);
                setEventTypes({
                    publication: publication.length > 0,
                    awardNomination: award.length > 0,
                    licensing: licensing.length > 0,
                    preservation: preservation.length > 0,
                    decision: decision.length > 0,
                    manufacture: manufacture.length > 0,
                });
                setIsItem(is_item);
                setCategory(category);
                setSelectedWorkId(work_id);
                setIsInitialized(true);
            }
        },
        onError: (err) => toast.error('Error fetching manifestation data: ' + err.message),
    });

    const { mutate, isLoading: isUpdating } = useMutation(updateManifestation, {
        onSuccess: () => {
            toast.success('Manifestation updated successfully');
            queryClient.invalidateQueries(['manifestation', manifestId]);
            navigate(-1);
        },
        onError: (err) => toast.error('Error updating manifestation: ' + err.message),
    });

    const onSubmit = (data, event) => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    const confirmSubmit = () => {
        setIsModalOpen(false);

        const formData = {
            ...methods.getValues(),
            user_id: userId,
            work_id: selectedWorkId,
            category,
            manifest_format_language: manifestationLanguages.filter(lang =>
                lang.language.trim() !== "" ||
                (lang.dialogue && lang.dialogueType.trim() !== "") ||
                (lang.written && lang.writtenType.trim() !== "")
            ),
            agents: agents.map(agent => ({
                agent_type: agent.type,
                agent_description: agent.description,
            })),
            events: {
                publication: publicationEvents,
                awardNomination: awardEvents,
                licensing: licensingEvents,
                preservation: preservationEvents,
                decision: decisionEvents,
                manufacture: manufactureEvents,
            },
            is_item: isItem,
        };
        mutate({ manifestId, formData });
    };

    const onError = (errors) => {
    };

    const toggleEventType = (type) => {
        setEventTypes(prev => ({ ...prev, [type]: !prev[type] }));
    };

    if (isFetching) return <SpinnerOverlay />;

    return (
        <FormProvider {...methods}>
            <Heading as="h3">Edit Manifestation</Heading>
            <Form type="tertiary" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <FormGroup>
                    <ManifestationGeneralFields category={category} errors={methods.formState.errors} />
                </FormGroup>
                <FormGroup>
                    <ManifestationTitles category={category} isCreating={isUpdating} errors={methods.formState.errors} />
                </FormGroup>
                {(category !== "photos_and_images") && (
                    <FormGroup>
                        <ManifestationLanguage
                            languageOptions={languageOptions}
                            manifestationLanguages={manifestationLanguages}
                            setManifestationLanguages={setManifestationLanguages}
                            errors={methods.formState.errors}
                            category={category}
                        />
                    </FormGroup>
                )}
                <FormGroup>
                    <ManifestationFormat category={category} errors={methods.formState.errors} />
                </FormGroup>
                <FormGroup>
                    <ManifestationExtent category={category} isCreating={isUpdating} />
                </FormGroup>
                <FormGroup>
                    <ManifestationEvents
                        eventTypes={eventTypes}
                        toggleEventType={(type) => setEventTypes(prevState => ({ ...prevState, [type]: !prevState[type] }))}
                        publicationEvents={publicationEvents}
                        setPublicationEvents={setPublicationEvents}
                        awardEvents={awardEvents}
                        setAwardEvents={setAwardEvents}
                        licensingEvents={licensingEvents}
                        setLicensingEvents={setLicensingEvents}
                        preservationEvents={preservationEvents}
                        setPreservationEvents={setPreservationEvents}
                        decisionEvents={decisionEvents}
                        setDecisionEvents={setDecisionEvents}
                        manufactureEvents={manufactureEvents}
                        setManufactureEvents={setManufactureEvents}
                        countryOptions={countryOptions}
                        category={category}
                    />
                </FormGroup>
                <FormGroup>
                    {!isFetching && agents.length > 0 && (
                        <ManifestationAgents
                            agents={agents}
                            setAgents={setAgents}
                            errors={methods.formState.errors}
                            category={category}
                        />
                    )}
                </FormGroup>
                <FormGroup>
                    <PartOfManifestation
                        selectedWorkId={selectedWorkId}
                        setSelectedWorkId={setSelectedWorkId}
                        category={category}
                        isEditing={true}
                        currentWorkId={selectedWorkId}
                    />
                </FormGroup>
                <Button type="submit" disabled={isUpdating}>Update</Button>
                {Object.keys(methods.formState.errors).length > 0 && (
                    <Warning message="A mandatory field must be filled" color="red" />
                )}
            </Form>
            {isUpdating && <SpinnerOverlay />}
            <CustomModal
                isOpen={isModalOpen}
                confirmationMessage={"Are you sure you want to update the manifestation?"}
                onRequestClose={() => setIsModalOpen(false)}
                onConfirm={confirmSubmit}
            />
        </FormProvider>
    );
}

export default EditManifestationForm;
