import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from "../../../ui/FormRow";
import Input from "../../../ui/Input";
import { StyledTextarea } from "../../../ui/StyledComponents"
import AutoExpandingTextarea from '../../../ui/AutoExpandingTextArea';
import Heading from '../../../ui/Heading';
import { workContentTypes, workFormsAudioVisuals, workGenresAudioVisual, workGenresPhoto, workFormsPhoto, workGenresDoc, workFormsDoc } from "../../../data/workData/workData";
import MultiSelect from '../../../ui/MultiSelect';
import DropDown from '../../../ui/DropDown';

const WorkContent = ({
    workContentType, setWorkContentType,
    workContent, setWorkContent,
    workGenre, setWorkGenre,
    workForm, setWorkForm,
    workSubjects, setWorkSubjects, isCreating, errors, category
}) => {
    const { register, setValue, setError, clearErrors } = useFormContext();

    const handleWorkContentTypeSelect = (option) => {
        setWorkContentType(option.value);
        setValue('work_content_type', option.value);
    };


    return (
        <>
            {category === 'audiovisuals' && (
                <FormRow>
                    <Heading as="h5">Content type</Heading>
                    <DropDown
                        id="work_content_type"
                        options={workContentTypes}
                        placeholder={"Choose content type"}
                        onSelect={handleWorkContentTypeSelect}
                        value={workContentType}
                        {...register('work_content_type')}
                    />
                </FormRow>
            )}
            <FormRow>
                <Heading as="h5">Content description </Heading>
                <AutoExpandingTextarea
                    type="text"
                    id="work_content"
                    {...register("work_content")}
                    name="work_content"
                    disabled={isCreating}
                />
            </FormRow>
            <FormRow>
                <Heading as="h5">Work genres</Heading>
                <MultiSelect
                    options={category === 'audiovisuals' ? workGenresAudioVisual :
                        category === 'photos_and_images' ? workGenresPhoto :
                            workGenresDoc}
                    selectedValues={workGenre}
                    setSelectedValues={setWorkGenre}
                    register={register}
                    placeHolder={"Choose work genres"}
                    clearErrors={clearErrors}
                    setError={setError}

                />
            </FormRow>
            <FormRow>
                <Heading as="h5">Work forms</Heading>
                <MultiSelect
                    options={category === 'audiovisuals' ? workFormsAudioVisuals :
                        category === 'photos_and_images' ? workFormsPhoto :
                            workFormsDoc}
                    selectedValues={workForm}
                    setSelectedValues={setWorkForm}
                    register={register}
                    placeHolder={"Choose work forms"}
                    clearErrors={clearErrors}
                    setError={setError}
                />
            </FormRow>
            <FormRow>
                <Heading as="h5">Work subjects</Heading>
                <Input
                    type="text"
                    id="work_subjects"
                    {...register("work_subjects")}
                    disabled={isCreating}
                />
            </FormRow>
        </>
    );
};

export default WorkContent;
