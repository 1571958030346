import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Box, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchWorkById } from '../../../../services/apiWork';
import Heading from '../../../../ui/Heading';
import countries from 'i18n-iso-countries';
import { convertCountryCodesToNames } from '../../../../utils/countries';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en);

function WorkDetails() {
    const { workId } = useParams();
    const navigate = useNavigate();
    const [work, setWork] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function loadWorkDetails() {
            try {
                const data = await fetchWorkById(workId);
                setWork(data);
                setError(null);
            } catch (err) {
                setError('Failed to fetch work details');
            } finally {
                setIsLoading(false);
            }
        }
        loadWorkDetails();
    }, [workId]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    const formatField = (label, value) => (
        <TableRow key={label}>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{label}</Heading>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{value || ''}</Heading>
            </TableCell>
        </TableRow>
    );

    const formatHeader = (label, value) => (
        <TableRow key={label}>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h7">{label}</Heading>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Heading as="h8">{value || ''}</Heading>
            </TableCell>
        </TableRow>
    );

    const renderAgents = (agents) => (
        agents.map((agent, index) => (
            <TableRow key={index}>
                <TableCell sx={{ borderBottom: 'none' }}>   <Heading as="h8">{agent.agent_title}</Heading></TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Heading as="h8">{agent.agent_first_name} {agent.agent_middle_name || ''} {agent.agent_last_name}</Heading>
                </TableCell>
            </TableRow>
        ))
    );

    const renderEventDetails = (eventDetails) => (
        Object.entries(eventDetails).map(([key, value]) => {
            if (key === 'id') return null;
            if (key === 'nomination') {
                value = value ? 'Yes' : 'No';
            }
            if (key === 'region') {
                value = Array.isArray(value) ? convertCountryCodesToNames(value).join(', ') : convertCountryCodesToNames([value]).join(', ');
            }
            return (
                <TableRow key={key}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                        <Heading as="h8">{key.charAt(0).toUpperCase() + key.slice(1)}</Heading>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}><Heading as="h8">{value}</Heading></TableCell>
                </TableRow>
            );
        })
    );

    const renderEvents = (events, eventType) => (
        events.filter(event => event[eventType]).map((event, index) => (
            <React.Fragment key={index}>
                <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                        <Heading as="h7">
                            Event: {eventType.replace(/^event_/, '').charAt(0).toUpperCase() + eventType.replace(/^event_/, '').slice(1)}
                        </Heading>
                    </TableCell>
                </TableRow>
                {Array.isArray(event[eventType])
                    ? event[eventType].map((detail, idx) => (
                        <React.Fragment key={idx}>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                                    <Heading as="h7">
                                        Date: {detail.date || 'N/A'}
                                    </Heading>
                                </TableCell>
                            </TableRow>
                            {renderEventDetails(detail)}
                        </React.Fragment>
                    ))
                    : (
                        <>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                                    <Heading as="h7">
                                        Date: {event[eventType].date || 'N/A'}
                                    </Heading>
                                </TableCell>
                            </TableRow>
                            {renderEventDetails(event[eventType])}
                        </>
                    )
                }
            </React.Fragment>
        ))
    );

    return (
        <Box margin={3}>
            <IconButton onClick={() => navigate(-1)} color="var(--color-grey-700)">
                <ArrowBackIcon />
            </IconButton>
            <CardContent>
                <Heading as="h7">
                    Work
                </Heading>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Title [Title]</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Title type</Heading></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {formatField(work?.work_title_identifying, 'Identifying')}
                            {formatField(work?.work_title_alternative, 'Alternative')}
                            {formatField(work?.work_title_preferred, 'Preferred')}
                            {formatField(work?.work_title_other, 'Other')}
                        </TableBody>

                        <TableBody>
                            {formatHeader('Work Type', work?.work_type)}
                            {formatHeader('Production country [Country of reference] :', convertCountryCodesToNames(work?.work_country).join(', '))}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }} ><Heading as="h7">Date(s) [Year of reference]</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }} ><Heading as="h7">Date type</Heading></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formatField(work?.work_date_start_publication, 'Start publication')}
                            {formatField(work?.work_date_end_publication, 'End publication')}
                            {formatField(work?.work_date_start_awardornomination, 'Start award or nomination')}
                            {formatField(work?.work_date_end_awardornomination, 'End award or nomination')}
                            {formatField(work?.work_date_start_production, 'Start production')}
                            {formatField(work?.work_date_end_production, 'End production')}
                            {formatField(work?.work_date_start_rightsoriprregisteration, 'Start rights or IP registration')}
                            {formatField(work?.work_date_end_rightsoriprregisteration, 'End rights or IP registration',)}
                        </TableBody>
                        <TableBody>
                            {formatField('Category [Form]:', work?.work_forms?.join(', '))}
                            {formatField('Genre [Subject/Genre]:', work?.work_genres?.join(', '))}
                            {formatField(`${work?.work_content_type || ''} : ${work?.work_content || ''}`.trim(), '')}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">On-screen credit</Heading></TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}><Heading as="h7">Name [Credits]</Heading></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {work?.work_agents && renderAgents(work.work_agents)}
                        </TableBody>
                        <TableBody>
                            {formatHeader('[Identifier] Identification number:', work?.work_nfaid)}
                        </TableBody>
                        <TableBody>
                            {work?.work_events && renderEvents(work.work_events, 'event_production')}
                            {work?.work_events && renderEvents(work.work_events, 'event_award')}
                            {work?.work_events && renderEvents(work.work_events, 'event_registration')}
                            {work?.work_events && renderEvents(work.work_events, 'event_publication')}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>

        </Box>
    );
}

export default WorkDetails;
