import React from 'react';
import { HiOutlineBell } from "react-icons/hi";
import { useNotification } from './useNotification';
import HeaderButtonIcon from '../../ui/HeaderButtonIcon';
import { useNavigate } from "react-router-dom";
const NotificationBadge = () => {
    const { notificationCount } = useNotification();
    const navigate = useNavigate();
    return (
        <div className="btn-container" style={{ position: 'relative' }}>
            <HeaderButtonIcon>
                <HiOutlineBell onClick={() => { navigate("/notifications", { replace: true }); }} />
                {notificationCount > 0 && (
                    <span style={{
                        position: 'absolute',
                        top: '-2px',
                        right: '-2px',
                        backgroundColor: 'var(--color-brand-600)',
                        borderRadius: '50%',
                        width: '8px',
                        height: '8px',
                        display: 'block'
                    }}

                    >
                    </span>
                )}
            </HeaderButtonIcon>
        </div>


    );
};

export default NotificationBadge;
