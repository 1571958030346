import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';

// UI Components
import Heading from '../../../ui/Heading';
import FormRow from "../../../ui/FormRow";
import Form from "../../../ui/Form";
import FormGroup from '../../../ui/FormGroup';
import Button from "../../../ui/Button";
import CustomModal from '../../../ui/CustomModal';
import FormattedCategory from '../../../utils/formattedCategory';
import Warning from '../../../ui/Warning';
import SpinnerOverlay from '../../../ui/SpinnerOverlay';

// Form Sections
import WorkTitles from './WorkTitles';
import WorkDateEntries from './WorkDateEntries';
import WorkEvents from './WorkEvents';
import WorkGeneralFields from './WorkGeneralFields';
import WorkLocalization from './WorkLocalization';
import WorkContent from './WorkContent';
import WorkAgents from './WorkAgents';

// Data
import { workDateTypes } from '../../../data/workData/workData';
import countries from 'i18n-iso-countries';
import { createWork } from '../../../services/apiWork';
import { useUser } from '../../authentication/useUser';
import { getCountryOptions, getLanguageOptions } from '../../../utils/countries';


import "react-datepicker/dist/react-datepicker.css";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function CreateWorkForm() {
    const navigate = useNavigate();
    const countryOptions = getCountryOptions();
    const languageOptions = getLanguageOptions();
    const methods = useForm();
    const location = useLocation();
    const { category } = location.state || { category: 'default' };
    const [workType, setWorkType] = useState("");
    const [identifiers, setIdentifiers] = useState([{ id: "", type: "" }]);
    const [cast, setCast] = useState([{ id: uuidv4(), firstName: '', middleName: '', lastName: '', characterName: '' }]);
    const [agents, setAgents] = useState([]);
    const [events, setEvents] = useState([{ type: '', date: new Date(), region: '' }]);
    const [workContentType, setWorkContentType] = useState("");
    const [workContent, setWorkContent] = useState("");
    const [workGenre, setWorkGenre] = useState([]);
    const [workForm, setWorkForm] = useState([]);
    const [workSubjects, setWorkSubjects] = useState("");
    const [workCountry, setWorkCountry] = useState([]);
    const [workOriginalLanguage, setWorkOriginalLanguage] = useState("");
    const [workLanguages, setWorkLanguages] = useState([]);
    const [dateEntries, setDateEntries] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        startDatePrecision: "",
        endDatePrecision: "",
        dateType: "",
    }]);
    const { user } = useUser();
    const userId = user.user.user_id;

    const [eventTypes, setEventTypes] = useState({
        publication: false,
        awardNomination: false,
        production: false,
        rightsRegistration: false
    });

    const [publicationEvents, setPublicationEvents] = useState([]);
    const [awardEvents, setAwardEvents] = useState([]);
    const [productionEvents, setProductionEvents] = useState([]);
    const [registrationEvents, setRegistrationEvents] = useState([]);
    const [isManifestation, setIsManifestation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleEventType = (type) => {
        setEventTypes(prevState => ({
            ...prevState,
            [type]: !prevState[type]
        }));
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = methods;

    useEffect(() => {
        if (category === "photos_and_images") {
            setWorkCountry([]);
            setWorkOriginalLanguage('');
            setWorkLanguages([]);
        }
        else if (category === "paper_based_documents") {
            setWorkCountry([]);
        }
    }, [category]);

    const queryClient = useQueryClient();
    const { mutate, isLoading: isCreating } = useMutation({
        mutationFn: createWork,
        onSuccess: (response) => {
            toast.success("New work successfully created");
            queryClient.invalidateQueries({ queryKey: ["works"] });
            reset();
            if (isManifestation) {
                navigate("/create-manifestation", { replace: true, state: { work_id: response.work_id, work_title_identifying: response.work.work_title_identifying, category: category } });
            }
            else {
                navigate(-1);
            }
            localStorage.removeItem('workDraft');
        },
        onError: (err) => { toast.error(err.message) },
    });

    const getDateFieldNames = (dateType) => {
        const typeName = dateType.replace(/_/g, '');
        return {
            startDateField: `work_date_start_${typeName}`,
            endDateField: `work_date_end_${typeName}`,
            startPrecisionField: `work_date_precision_start_${typeName}`,
            endPrecisionField: `work_date_precision_end_${typeName}`
        };
    };

    const handleSaveDraft = () => {
        const formData = {
            ...methods.getValues(),
            workType,
            identifiers,
            cast,
            agents,
            events,
            workContentType,
            workContent,
            workGenre,
            workForm,
            workSubjects,
            workCountry,
            workOriginalLanguage,
            workLanguages,
            dateEntries: dateEntries.map(entry => ({
                ...entry,
                startDate: entry.startDate,
                endDate: entry.endDate
            })),
            eventTypes,
            isManifestation
        };

        const serializedData = JSON.stringify(formData);
        localStorage.setItem('workDraft', serializedData);
        toast.success("Draft saved successfully!");
    };

    const loadDraft = () => {
        const storedData = localStorage.getItem('workDraft');
        if (storedData) {
            const draftData = JSON.parse(storedData);

            methods.reset(draftData);
            setWorkType(draftData.workType);
            setIdentifiers(draftData.identifiers);
            setCast(draftData.cast);
            setAgents(draftData.agents);
            setEvents(draftData.events);
            setWorkContentType(draftData.workContentType);
            setWorkContent(draftData.workContent);
            setWorkGenre(draftData.workGenre);
            setWorkForm(draftData.workForm);
            setWorkSubjects(draftData.workSubjects);
            setWorkCountry(draftData.workCountry);
            setWorkOriginalLanguage(draftData.workOriginalLanguage);
            setWorkLanguages(draftData.workLanguages);
            setDateEntries(draftData.dateEntries.map(entry => ({ ...entry, startDate: new Date(entry.startDate), endDate: new Date(entry.endDate) })));
            setEventTypes(draftData.eventTypes);
            setIsManifestation(draftData.isManifestation);
        }
    };

    useEffect(() => {
        loadDraft();
    }, []);

    const handleConfirmSubmission = () => {
        setIsModalOpen(false);

        const data = methods.getValues();
        const workData = {
            ...data,
            category,
            user_id: userId,
            work_country: workCountry,
            work_original_language: workOriginalLanguage,
            work_languages: workLanguages,
            work_content_type: workContentType,
            work_genres: workGenre,
            work_forms: workForm,
            work_identifiers: data.work_identifiers,
            agents: agents.map(agent => ({
                agent_title: agent.title,
                agent_first_name: agent.firstName,
                agent_middle_name: agent.middleName,
                agent_last_name: agent.lastName,
                agent_character_name: agent.characterName
            })),
            events: {
                publication: publicationEvents,
                awardNomination: awardEvents,
                production: productionEvents,
                rightsRegistration: registrationEvents
            }
        };
        for (let type of workDateTypes.map(type => type.value)) {
            const { startDateField, endDateField, startPrecisionField, endPrecisionField } = getDateFieldNames(type);
            workData[startDateField] = '';
            workData[endDateField] = '';
            workData[startPrecisionField] = '';
            workData[endPrecisionField] = '';
        }

        for (let entry of dateEntries) {
            const { startDateField, endDateField, startPrecisionField, endPrecisionField } = getDateFieldNames(entry.dateType);
            workData[startDateField] = entry.startDate ? entry.startDate : '';
            workData[endDateField] = entry.endDate ? entry.endDate : '';
            workData[startPrecisionField] = entry.startDatePrecision;
            workData[endPrecisionField] = entry.endDatePrecision;
        }
        try {
            JSON.stringify(workData);
        } catch (error) {
            return;
        }

        mutate(workData);
        methods.reset();
    };


    const onSubmit = (data, event) => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    const onError = (errors) => { };


    return (
        <FormProvider {...methods}>
            <Heading as="h3">Add new work, starting new <FormattedCategory category={category} /></Heading>
            <Form type="tertiary" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <FormGroup>
                    <WorkGeneralFields
                        workType={workType}
                        setWorkType={setWorkType}
                        errors={errors}
                        identifiers={identifiers}
                        setIdentifiers={setIdentifiers}
                    />
                </FormGroup>
                <FormGroup>
                    <WorkTitles category={category} isCreating={isCreating} errors={errors} />
                </FormGroup>
                <FormGroup>
                    <WorkDateEntries dateEntries={dateEntries} setDateEntries={setDateEntries} errors={methods.formState.errors} />
                </FormGroup>
                {(category !== "photos_and_images") && (
                    <FormGroup>
                        <WorkLocalization
                            category={category}
                            workCountry={workCountry}
                            setWorkCountry={setWorkCountry}
                            workOriginalLanguage={workOriginalLanguage}
                            setWorkOriginalLanguage={setWorkOriginalLanguage}
                            workLanguages={workLanguages}
                            setWorkLanguages={setWorkLanguages}
                            countryOptions={countryOptions}
                            languageOptions={languageOptions}
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <WorkContent
                        workContentType={workContentType}
                        setWorkContentType={setWorkContentType}
                        workContent={workContent}
                        setWorkContent={setWorkContent}
                        workGenre={workGenre}
                        setWorkGenre={setWorkGenre}
                        workForm={workForm}
                        setWorkForm={setWorkForm}
                        workSubjects={workSubjects}
                        setWorkSubjects={setWorkSubjects}
                        category={category}
                    />
                </FormGroup>

                <FormGroup>
                    <WorkAgents
                        category={category}
                        agents={agents}
                        setAgents={setAgents}
                        errors={errors}
                    />
                </FormGroup>
                <FormGroup>
                    <WorkEvents
                        eventTypes={eventTypes}
                        toggleEventType={toggleEventType}
                        publicationEvents={publicationEvents}
                        setPublicationEvents={setPublicationEvents}
                        awardEvents={awardEvents}
                        setAwardEvents={setAwardEvents}
                        productionEvents={productionEvents}
                        setProductionEvents={setProductionEvents}
                        registrationEvents={registrationEvents}
                        setRegistrationEvents={setRegistrationEvents}
                        countryOptions={countryOptions}
                    />
                </FormGroup>

                <FormGroup>
                    <FormRow label="Does this work have a manifestation?">
                        <input type="checkbox" checked={isManifestation} onChange={() => setIsManifestation(!isManifestation)} />
                    </FormRow>
                </FormGroup>
                <Button variation="secondary" type="reset" onClick={() => { localStorage.removeItem('workDraft'); }}>
                    Reset
                </Button>
                <Button variation="secondary" type="button" onClick={handleSaveDraft}>
                    Save
                </Button>

                <Button variation="primary" type="submit" disabled={isCreating}>Record</Button>
                {Object.keys(errors).length > 0 && (
                    <Warning message="A mandatory field must be filled" color="red" />
                )}
            </Form>
            {isCreating && <SpinnerOverlay />}
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmSubmission}
                confirmationMessage={"Are you sure you want to submit the work?"}
            />
        </FormProvider>
    );
}

export default CreateWorkForm;
